import React from 'react';
import './ticker.css';

const Ticker = () => {
  const handleTickerClick = () => {
    // window.open(`${process.env.PUBLIC_URL}/SD_SD_guidelines.pdf`, '_blank');
  };

  return (
    <div className="ticker-container">
      <div className="static-text" onClick={handleTickerClick}>
        The website is currently working in progress, data is being collected
        and uploaded. You are also requested to contribute data of your villages
      </div>
    </div>
  );
};

export default Ticker;

