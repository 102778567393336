import { useCallback, useEffect, useState } from "react";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import styled from "styled-components";
import firebase from "../../../firebase";
import axios from "axios";
import { Button, Carousel, Modal, Offcanvas } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import SuggestEdit from "../SuggestEdit";
import OverviewCard from "./OverviewCard";
import YoutubeEmbed from "./YoutubeEmbed";
import Tooltip from "@mui/material/Tooltip";
import { CiCircleInfo } from "react-icons/ci";
import { useInView } from "react-intersection-observer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import closeLogo from "../../Assets/img/icons/close.svg";
import LanguageIcon from "../../Assets/img/icons/LanguageIcon.svg";
import ElectricityIcon from "../../Assets/img/icons/electricity.svg";
import CommunitiesIcon from "../../Assets/img/icons/Communities.svg";
import SchoolIcon from "../../Assets/img/icons/Schools.svg";
import HealthIcon from "../../Assets/img/icons/Health.svg";
import CollegeIcon from "../../Assets/img/icons/College.svg";
import ToiletsIcon from "../../Assets/img/icons/Toilets.svg";
import CookingIcon from "../../Assets/img/icons/Cooking.svg";
import TelephoneIcon from "../../Assets/img/icons/telephone.svg";
import RailwayIcon from "../../Assets/img/icons/railway.svg";
import BusIcon from "../../Assets/img/icons/bus.svg";
import BankIcon from "../../Assets/img/icons/bank.svg";
import PostoffcIcon from "../../Assets/img/icons/postoffc.svg";
import MenuIcon from "../../Assets/img/icons/menu.svg";
import MyLayout from "../../Components/MyLayout/index";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import InternetIcon from "../../Assets/img/icons/internet.svg";
import WaterIcon from "../../Assets/img/icons/water.svg";
import EditIcon from "../../Assets/img/icons/edit.svg";
import Default from "../../Assets/img/General/default.png";
import PlusGreen from "../../Assets/img/icons/plus-green.svg";

import "./index.css";
import "./index.scss";
import "react-placeholder/lib/reactPlaceholder.css";
import "react-image-lightbox/style.css";
import NewCard from "./NewCard";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { handleImageError } from "../../utils";
import Heart from "../../Assets/img/icons/heart.svg";
import HeartColored from "../../Assets/img/icons/heart-colored.svg";
import HeartGreen from "../../Assets/img/icons/heart-green.svg";
import Village from "../Village/detail";
import VillageCard from "../Village/VillageCard";

const VillageImage = ({ image_id, getData }) => {
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    if (image_id && image_id != "000") {
      getData(image_id).then((dd) => setImgUrl(dd));
    } else {
      setImgUrl(Default);
    }
  }, []);

  return (
    <>
      <VillageImageDiv
        style={{
          backgroundColor: "#fff",
          borderRadius: "15px",
          backgroundSize: "cover",
          filter: "blur(80px)",
          border: "1px solid #3CAF5C",
          overflow: "hidden",
          backgroundImage: `url(${imgUrl})`,
        }}
      ></VillageImageDiv>
      <VillageImageDiv
        className="th-border-top th-border-bottom"
        style={{
          borderRadius: "15px",
          position: "absolute",
          top: "0%",
          right: "0%",
          bottom: "0%",
          backgroundSize: "contain",
          backgroundImage: `url(${imgUrl})`,
        }}
      ></VillageImageDiv>
    </>
  );
};

const SliderImage = ({ image_id, getData }) => {
  const [imgUrlSlider, setImgUrlSlider] = useState("");

  useEffect(() => {
    if (image_id) {
      getData(image_id).then((dd) => setImgUrlSlider(dd));
    } else {
      setImgUrlSlider(Default);
    }
  }, []);

  return (
    <>
      <VillageImageDiv
        className="th-border-top th-border-bottom overflow-hidden"
        style={{
          backgroundColor: "#fff",
          backgroundSize: "cover",
          filter: "blur(5px)",
          border: "1px solid #3CAF5C",
          overflow: "hidden",
          backgroundImage: `url(${imgUrlSlider})`,
        }}
      ></VillageImageDiv>
      <VillageImageDiv
        className="th-border-top th-border-bottom"
        style={{
          position: "absolute",
          top: "0%",
          right: "0%",
          backgroundImage: `url(${imgUrlSlider})`,
        }}
      ></VillageImageDiv>
    </>
  );
};

const generateNewCardComponent = (
  title,
  subtitle,
  jsonField,
  icon,
  topicsData,
  keyy,
  villageData
) => {
  return (
    <NewCard
      topicsData={topicsData?.geographical?.[jsonField]}
      keyy={keyy}
      villageData={villageData}
      icon={icon}
      jsonField={jsonField}
      subtitle={subtitle}
      title={title}
    />
  );
};
const renderOverviewCard = (
  topicsData,
  villageData,
  keyy,
  title,
  jsonField,
  subtitle,
  icon
) => {
  if (
    topicsData?.developmental?.[jsonField] === "" ||
    topicsData?.developmental?.[jsonField] === null ||
    topicsData?.developmental?.[jsonField] === undefined ||
    topicsData?.developmental?.[jsonField] === "NA"
  ) {
    return null;
  }
  return (
    <div className="bedu my-2 primary-part">
      <OverviewCard
        village_key={keyy}
        village_name={villageData.data?.village_name}
        blockName={villageData.data?.block_name}
        districtName={villageData.data?.district_name}
        title={title}
        jsonField={jsonField}
        subtitle={subtitle}
        state_name={villageData.data?.state_name}
        answer={topicsData?.developmental?.[jsonField]}
        icon={icon}
      />
    </div>
  );
};
const VillageDetails = (props) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const urlContains =
      location.pathname.includes("IVH9vm4mbCgPTB2Jny97") ||
      location.pathname.includes("m8L3kCiyctyTVeJsJifS");

    if (urlContains) {
      window.location.href = "https://mgmd.gov.in/explore";
    }
  }, [location.pathname]);

  const getData = async (id, type) => {
    const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${id}`;
    let data = "";
    await axios.get(url).then(function (response) {
      data = response?.data?.data;
    });
    return data;
  };

  const getData2 = async (id, type) => {
    const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/800x800/filters:format(webp)/filters:quality(90)/images/${id}`;
    let data = "";
    await axios.get(url).then(function (response) {
      data = response?.data?.data;
    });
    return data;
  };

  const renderEditOption = (section, field, defaultData) => {
    return (
      <>
        <div className={"pt-4 pb-3 border-radius-content-village-deets mt-2"}>
          <div className="th-16 px-3" style={{ fontWeight: "600" }}>
            <div className="d-flex align-items-center">
              <div>
                {section == "famous_personalities"
                  ? "Name of local hero :"
                  : section == "traditional_art"
                  ? "Name of art :"
                  : section == "prominent_artists"
                  ? "Name"
                  : "Title :"}
              </div>
              <span style={{ display: "flex", alignItems: "center" }}>
                <div className="d-flex">
                  <NoDataMessage />
                </div>
                {handleAllEditcontent(
                  section,
                  section == "famous_personalities"
                    ? "name_of_local_hero"
                    : section == "traditional_art"
                    ? "name_of_art"
                    : section == "prominent_artists"
                    ? "name"
                    : "title",
                  defaultData
                )}
              </span>
            </div>
          </div>
          {/* <div className="text-disclaimer-with-edit px-3 pt-2">
            The information displayed on this portal has been collected through
            local respondants via survey. If any of the information found is
            incorrect then correct information can be updated using contribute
            button.
          </div> */}
        </div>
      </>
    );
  };

  const StateVillageDistrictDisplay = () => {
    const [villageStyle, setVillageStyle] = useState({ display: "block" });
    return (
      <>
        <div className="shadow-lg p-3 mb-2 bg-white rounded d-flex justify-content-start ">
          <div className="village_name">
            <div
              onMouseLeave={(e) => {
                setVillageStyle({ display: "block" });
              }}
            >
              <div>
                <div className="d-flex flex-row">
                  <div
                    onMouseEnter={(e) => {
                      setVillageStyle({ display: "block" });
                    }}
                  >
                    <h3 className="th-20 th-fw-600 mb-0 d-flex text-capitalize aboutVillage">
                      {topicsData?.village_name
                        ? topicsData?.village_name?.toLowerCase()
                        : "Loading..."}
                    </h3>
                  </div>
                </div>
                <div className="th-14 th-grey-2 text-capitalize">
                  {topicsData?.block_name
                    ? topicsData?.block_name?.toLowerCase() + ", "
                    : null}
                  {topicsData?.district_name
                    ? topicsData?.district_name?.toLowerCase()
                    : null}
                  {topicsData?.state_name
                    ? ", " + topicsData?.state_name?.toLowerCase()
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const Message = () => {
    return (
      <>
        <div
          className={
            selectedTopic == "traditional_food" ||
            selectedTopic == "traditional_dress" ||
            selectedTopic == "ornaments"
              ? "text-disclaimer-with-edit pt-2"
              : "text-disclaimer-with-edit px-3 pt-2"
          }
        >
          The information on this portal has been collected through surveys and
          is subject to ongoing updates. If any information is found to be
          missing or incorrect, it can be updated using the "Contribute" button.
        </div>
      </>
    );
  };

  const highlight = (text) => {
    const elements = document.getElementsByClassName("inputText");

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const innerHTML = element.innerHTML;
      const expression = new RegExp(`${text.searchValue}`, "gi");
      const matches = innerHTML.matchAll(expression);

      let highlightedHTML = innerHTML;
      let offset = 0;
      for (const match of matches) {
        const start = match.index + offset;
        const end = start + match[0].length;
        highlightedHTML = `${highlightedHTML.substring(
          0,
          start
        )}<span class='highlight'>${match[0]}</span>${highlightedHTML.substring(
          end
        )}`;
        offset += `<span class='highlight'></span>`.length;
      }
      element.innerHTML = highlightedHTML;
    }
  };
  const getPoster = async (id, type) => {
    const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${id}`;
    let data = "";
    await axios.get(url).then(function (response) {
      data = response?.data?.data;
    });
    return data;
  };

  const { pathname, searchValue } = useLocation();

  const [villageStyle, setVillageStyle] = useState({ display: "block" });
  const [aboutEdit, setAboutEdit] = useState({ display: "block" });
  const [mainProducesEdit, setAboutProducesEdit] = useState({
    display: "block",
  });

  const [modalShow, setModalShow] = useState(false);
  const [openContributeModal, setOpenContributeModal] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [imageItem, setImageItem] = useState();
  const [imageItemIndex, setImageItemIndex] = useState();
  const [selectedTopic, setSelectedTopic] = useState("culture_profile");
  const [villageData, setVillageData] = useState({});
  const [shownTopic, setShownTopic] = useState([]);
  const [isError, setIsError] = useState(false);
  const [allEditInput, setAllEditInput] = useState();
  const [aboutsuccess, setAboutsuccess] = useState(false);
  const [itemList, setItemList] = useState();
  const [modelbody, setmodelbody] = useState(false);
  const [imageId, setImageId] = useState();
  const [errorInput, setErrorInput] = useState(false);
  const [currentInView, setCurrentInView] = useState(null);

  //data To be saved for indibadges
  const [villageID, setVillageID] = useState();
  const [indiBadgeImageID, setIndiBadgeImageId] = useState();
  const [claimLoader, setClaimLoader] = useState(false); //claim loader for indibadge
  const [selectedGalleryTopic, setSelectedGalleryTopic] =
    useState("all_photos");
  const [imgSelected, setImgSelected] = useState(false);
  const [cultureFontWeight, setCultureFontWeight] = useState("500");
  const [cultureFontColor, setCultureFontColor] = useState("#3CAF5C");

  const [geoFontWeight, setGeoFontWeight] = useState("500");
  const [geoFontColor, setGeoFontColor] = useState("#3CAF5C");

  const [devFontWeight, setDevFontWeight] = useState("500");
  const [devFontColor, setDevFontColor] = useState("#3CAF5C");

  const [foodFontWeight, setFoodFontWeight] = useState("500");
  const [foodFontColor, setFoodFontColor] = useState("#3CAF5C");

  const [dressFontWeight, setDressFontWeight] = useState("500");
  const [dressFontColor, setDressFontColor] = useState("#3CAF5C");

  const [ornamentsFontWeight, setOrnamentsFontWeight] = useState("500");
  const [ornamentsFontColor, setOrnamentsFontColor] = useState("#3CAF5C");

  const [artFontWeight, setArtFontWeight] = useState("500");
  const [artFontColor, setArtFontColor] = useState("#3CAF5C");

  const [festivalFontWeight, setFestivalFontWeight] = useState("500");
  const [festivalFontColor, setFestivalFontColor] = useState("#3CAF5C");

  const [beliefFontWeight, setBeliefFontWeight] = useState("500");
  const [beliefFontColor, setBeliefsFontColor] = useState("#3CAF5C");

  const [heritageFontWeight, setHeritageFontWeight] = useState("500");
  const [heritageFontColor, setHeritageFontColor] = useState("#3CAF5C");

  const [famousFontWeight, setFamousFontWeight] = useState("500");
  const [famousFontColor, setFamousFontColor] = useState("#3CAF5C");

  const [artistFontWeight, setArtistFontWeight] = useState("500");
  const [artistFontColor, setArtistFontColor] = useState("#3CAF5C");

  const [nearFontWeight, setNearFontWeight] = useState("500");
  const [nearFontColor, setNearFontColor] = useState("#3CAF5C");

  const [imageFontWeight, setImageFontWeight] = useState("500");
  const [imageFontColor, setImageFontColor] = useState("#3CAF5C");
  const [isEdit, setIsEdit] = useState(false);

  const handleInViewChange = useCallback(
    (inView, entry, setFontColor, setFontWeight) => {
      const sectionName = entry.target.getAttribute("data-section");
      if (inView && currentInView !== sectionName) {
        setSelectedTopic(sectionName);
        // setFontColor("#fff");
        setFontWeight("700");
        setCurrentInView(sectionName);
      } else if (!inView && currentInView === sectionName) {
        setCurrentInView(null);
        // setFontColor("#fff");
        setFontWeight("500");
      }
    },
    [currentInView]
  );

  const { ref: geoSectionRef } = useInView({
    threshold: 0.8,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(inView, entry, setGeoFontColor, setGeoFontWeight),
  });

  const { ref: nearVillageRef } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(inView, entry, setNearFontColor, setNearFontWeight),
  });

  const { ref: cultureProfileRef } = useInView({
    threshold: 0.8,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(
        inView,
        entry,
        setCultureFontColor,
        setCultureFontWeight
      ),
  });

  const { ref: developmentalRef } = useInView({
    threshold: 0.8,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(inView, entry, setDevFontColor, setDevFontWeight),
  });

  const { ref: foodRef } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(inView, entry, setFoodFontColor, setFoodFontWeight),
  });

  const { ref: dressRef } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(inView, entry, setDressFontColor, setDressFontWeight),
  });

  const { ref: ornamentRef } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(
        inView,
        entry,
        setOrnamentsFontColor,
        setOrnamentsFontWeight
      ),
  });

  const { ref: artRef } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(inView, entry, setArtFontColor, setArtFontWeight),
  });

  const { ref: festivalsRef } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(
        inView,
        entry,
        setFestivalFontColor,
        setFestivalFontWeight
      ),
  });

  const { ref: beliefRef } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(
        inView,
        entry,
        setBeliefsFontColor,
        setBeliefFontWeight
      ),
  });

  const { ref: heritageRef } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(
        inView,
        entry,
        setHeritageFontColor,
        setHeritageFontWeight
      ),
  });

  const { ref: famousRef } = useInView({
    threshold: 0.4,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(
        inView,
        entry,
        setFamousFontColor,
        setFamousFontWeight
      ),
  });

  const { ref: prominentRef } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(
        inView,
        entry,
        setArtistFontColor,
        setArtistFontWeight
      ),
  });

  const { ref: imageRef } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(inView, entry, setImageFontColor, setImageFontWeight),
  });

  const { ref: nearRef } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    onChange: (inView, entry) =>
      handleInViewChange(inView, entry, setNearFontColor, setNearFontWeight),
  });

  useEffect(() => {
    if (selectedTopic === "image_gallery") {
      setImgSelected(true);
    }
  }, [selectedTopic]);

  //Navigate to the topic based on the link
  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const paramValue = urlParams.get("topic");
  //   if (paramValue) {
  //     setSelectedTopic(paramValue);
  //   }
  // }, []);

  // Effect to scroll to the section based on URL parameter
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get("topic");
    if (paramValue) {
      setSelectedTopic(paramValue);

      // Scroll to the section after a short delay to ensure component mounts
      setTimeout(() => {
        const sectionElement = document.querySelector(
          `[data-section="${paramValue}"]`
        );
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 300); // Adjust delay as necessary
    }
  }, []);

  //Set the url according the selected topic
  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set("topic", selectedTopic);
    history.push(url.pathname + url.search);
    // document
    //   ?.querySelector(".th-90-height-scroll")
    //   ?.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedTopic, history]);

  const getClickedSection = (sectionName) => {
    const hasWindow = typeof window !== "undefined";
    const sectionElement = document.querySelector(
      `[data-section="${sectionName}"]`
    );
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      const width = hasWindow ? window.innerWidth : null;

      let windowScroll;
      if (width < 768) {
        windowScroll = document.getElementById("mobile");
        const elementPosition = sectionElement.getBoundingClientRect().top;
        const offset = 80; // Offset in pixels
        const top = elementPosition - offset;

        windowScroll.scrollBy({
          top: elementPosition - offset,
          behavior: "smooth",
        });
      } else {
        windowScroll = document.getElementById("desktop");
        const elementPosition = sectionElement.getBoundingClientRect().top;
        const offset = 200; // Offset in pixels
        const top = elementPosition - offset;

        windowScroll.scrollBy({
          top: elementPosition - offset,
          behavior: "smooth",
        });
      }
    }
  };

  const [galleryList, setGalleryList] = useState([
    "all_photos",
    "festivals",
    "heritage_places",
    "traditional_food",
    "traditional_art",
    "traditional_dress",
    "ornaments",
    "famous_personalities",
  ]);
  const [topicList, setTopicList] = useState([
    "culture_profile",
    "geographical",
    "developmental",
    "festivals",
    "traditional_food",
    "heritage_places",
    "belief",
    "ornaments",
    "prominent_artists",
    "traditional_dress",
    "famous_personalities",
    "traditional_art",
  ]);
  const textVillage = [
    "-",
    "NO",
    "no",
    null,
    "",
    " ",
    "No",
    "_",
    "Na",
    "NA",
    "none",
    "...",
    "....",
    "--",
    "---",
    "-",
    "-                                                           ",
    "No data available.",
    "No Information available",
    "No such information came during survey",
    "No data available",
    "Information not Available",
    "Information not available.",
    "No such data found during the survey.",
    "Not Available",
    "No such information has come out during the survey. ",
    "No such information is found during the survey.",
    "No Information Available",
    "Nil",
    "Not available",
    "Data Not Available",
    "Data not available",
    "Data Not Available ",
    "Information not available. ",
    "Information is not available",
    "Information Not Available",
    "-\r\n",
    "- .",
    ".",
    "No information available.",
    "None",
    " -",
    "- ",
    "-\r\n\r\n\r\n",
    "Information not available",
    "Information Not Available ",
    "NIL",
    ",",
  ];

  const [show, setShow] = useState(false);
  const [capId, setCapId] = useState();
  const [captionShow, setCaptionShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleBadgesM = () => {
    setOpenModel(false);
    setIsError(false);
  };
  const [topicsData, setTopicsData] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(undefined);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState();
  const [villageThumbnailId, setVillageThumbnailId] = useState();
  const [onHoverEff, setOnHoverEff] = useState(true);
  const [openModel, setOpenModel] = useState(false);
  const [randomImg, setRandomImgBadge] = useState();
  const [randomImgName, setRandomImgBadgeName] = useState();
  var url = location.pathname;

  const [offset, setOffset] = useState(0);
  const [images360, setImages360] = useState();
  const [display, setDisplay] = useState(false);

  const [resetImageGellary, setResetImageGellary] = useState();
  const [resetImageHeritage, setResetImageHeritage] = useState();
  const [resetImageFestivals, setResetImageFestivals] = useState();
  const [resetImageDress, setResetImageDress] = useState();
  const [resetImageOrnamants, setResetImageOrnaments] = useState();
  const [resetImagesFood, setResetImagesFood] = useState();
  const [resetImageFamous, setResetImagesFamous] = useState();
  const [resetImageArt, setResetImagesArt] = useState();
  const [newList, setNewList] = useState();
  const [primary, setPrimary] = useState("");
  const [primaryArray, setPrimaryArray] = useState([]);
  const [guid, setGuid] = useState("");
  const [likeCount, setLikeCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [nearBuyVillage, setNearBuyVillage] = useState([]);
  const [cultureIndex, setCultureIndex] = useState(0);
  const [foodIndex, setFoodIndex] = useState(0);
  const [dressIndex, setDressIndex] = useState(0);
  const [ornamentsIndex, setOrnamentsIndex] = useState(0);
  const [artIndex, setArtIndex] = useState(0);
  const [festivalIndex, setFestivalIndex] = useState(0);
  const [beliefIndex, setBeliefIndex] = useState(0);
  const [heritageIndex, setHeritageIndex] = useState(0);
  const [famousIndex, setFamousIndex] = useState(0);
  const [artistIndex, setArtistIndex] = useState(0);
  const [summary, setSummary] = useState("");

  const handleCultureIndex = (selectedIndex, e) => {
    setCultureIndex(selectedIndex);
  };

  const handleFoodIndex = (selectedIndex, e) => {
    setFoodIndex(selectedIndex);
  };

  const handleDressIndex = (selectedIndex, e) => {
    setDressIndex(selectedIndex);
  };

  const handleOrnamentsIndex = (selectedIndex, e) => {
    setOrnamentsIndex(selectedIndex);
  };

  const handleArtIndex = (selectedIndex, e) => {
    setArtIndex(selectedIndex);
  };

  const handleFestivalIndex = (selectedIndex, e) => {
    setFestivalIndex(selectedIndex);
  };

  const handleBeliefIndex = (selectedIndex, e) => {
    setBeliefIndex(selectedIndex);
  };

  const handleHeritageIndex = (selectedIndex, e) => {
    setHeritageIndex(selectedIndex);
  };

  const handleFamousIndex = (selectedIndex, e) => {
    setFamousIndex(selectedIndex);
  };

  const handleArtistIndex = (selectedIndex, e) => {
    setArtistIndex(selectedIndex);
  };

  useEffect(() => {
    let val = location?.state?.searchValue;
  }, [searchValue]);

  const config = {
    autoRotate: -5,
    autoLoad: true,
    autoRotateInactivityDelay: 2000,
  };
  const temporaryTopiclist = [
    "culture_profile",
    "geographical",
    "developmental",
    "festivals",
    "traditional_food",
    "heritage_places",
    "belief",
    "ornaments",
    "prominent_artists",
    "traditional_dress",
    "famous_personalities",
    "traditional_art",
    "image_gallery",
  ];
  const allpictures = [];
  var id = props.match.params?.id;
  var keyy = props.match.params.ind;
  console.log(keyy, ":keyy  ");

  const handleModalShow = (item, index) => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.displayName
    ) {
      setisUpdate(false);
      setModalShow(true);
      setImageItem(item ? item : null);
      setImageItemIndex(index);
    } else {
      history.push("/login");
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
    setIsError(false);
  };

  useEffect(() => {
    setShow(false);
    setModalShow(false);
    if (
      selectedTopic == "culture_profile" ||
      selectedTopic == "geographical" ||
      selectedTopic == "developmental" ||
      selectedTopic == "belief" ||
      selectedTopic == "prominent_artists" ||
      selectedTopic === "famous_personalities" ||
      selectedTopic == "image_gallery"
    ) {
      setShow(false);
      setModalShow(false);
    }
  }, [selectedTopic]);

  useEffect(() => {
    if (localStorage.getItem("village-visited")) {
      let new_village = JSON.parse(localStorage.getItem("village-visited"));
      if (new_village) {
        new_village.push(keyy);
        localStorage.setItem("village-visited", JSON.stringify(new_village));
      }
    } else {
      let villages = [];
      villages.push(keyy);
      localStorage.setItem("village-visited", JSON.stringify(villages));
    }

    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [keyy]);

  const getLikeCount = async (id) => {
    let db = firebase.firestore();
    let docRef = db.collection("large_village_list");
    const querySnapShot = await docRef.where("guid", "==", id).get();

    if (!querySnapShot.empty) {
      let a = 0; // Initialize a with 0
      if (querySnapShot.docs[0].data().likes_count !== undefined) {
        a = querySnapShot.docs[0].data().likes_count;
      }
      setLikeCount(a);
    } else {
      console.log("No matching documents.");
      setLikeCount(0);
    }
  };

  const { ind } = useParams();

  const getSummary = async (ind) => {
    const response = await axios.get(
      `https://asia-south1-mgmd-356213.cloudfunctions.net/getSummary?docId=${ind}`
    );
    setSummary(response.data.summary);
  };

  // useEffect(() => {
  //   if (ind) {
  //     getSummary(ind).then();
  //   }
  // }, [ind]);

  const getNearBuyVillage = async (blockCode) => {
    const db = firebase.firestore();
    const docRef = db.collection("large_village_list");
    const querySnapshot = await docRef
      .where("block_lgd_code", "==", blockCode)
      .where("village_document_id", "!=", ind)
      .limit(6)
      .get();

    if (!querySnapshot.empty) {
      const data = querySnapshot.docs.map((doc) => doc.data());
      setNearBuyVillage(data);
    } else {
      console.log("No villages found for the given block code.");
    }
  };

  useEffect(() => {
    async function fetchData() {
      let db = firebase.firestore();
      let docRef = db.collection("large_village_detail");
      docRef
        .doc(keyy)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setVillageID(doc?.id); // storing for indibadge
            setVillageData(doc.data());
            getNearBuyVillage(doc.data()?.block_lgd_code);
            setGuid(doc.data()?.guid);
            setTopicsData(doc.data().data);
            isVillageLiked(doc.data()?.guid);
            getLikeCount(doc.data()?.guid);
            setCurrentVideo(doc.data().video);
            let list = topicList.filter((topic) => {
              return doc.data().data[topic] != null;
            });

            setShownTopic(list);
            if (localStorage.getItem("user")) {
              firebase
                .firestore()
                .collection("user_data")
                .doc(JSON.parse(localStorage.getItem("user")).uid)
                .get()
                .then((res) => {
                  if (res.data() === undefined) {
                    firebase
                      .firestore()
                      .collection("user_data")
                      .doc(JSON.parse(localStorage.getItem("user")).uid)
                      .set({
                        states: [doc.data().data.state_name],
                        email: JSON.parse(localStorage.getItem("user")).email,
                        name: JSON.parse(localStorage.getItem("user"))
                          .displayName,
                        villages: [doc.data().data.village_name],
                        thumbnails: [
                          doc.data().data.video_thumbnail != undefined
                            ? doc.data().data.video_thumbnail
                            : "default",
                        ],
                        keys: [doc?.id],
                        uid: JSON.parse(localStorage.getItem("user")).uid,
                        count: 1,
                      })
                      .then((response) => {
                        const increment =
                          firebase.firestore.FieldValue.increment(1);
                        firebase
                          .firestore()
                          .collection("users")
                          .doc(JSON.parse(localStorage.getItem("user")).uid)
                          .update({ count: increment }, { merge: true })
                          .then((response) => {});
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    let data = res.data();
                    if (
                      data.states.indexOf(doc.data().data.state_name) === -1
                    ) {
                      data.states.push(doc.data().data.state_name);
                    }
                    if (
                      data.villages.indexOf(doc.data().data.village_name) === -1
                    ) {
                      data.villages.push(doc.data().data.village_name);
                      data.keys.push(doc?.id);
                      data.count += 1;
                      const increment =
                        firebase.firestore.FieldValue.increment(1);
                      firebase
                        .firestore()
                        .collection("users")
                        .doc(JSON.parse(localStorage.getItem("user")).uid)
                        .update({ count: increment }, { merge: true })
                        .then((response) => {});
                      data.thumbnails.push(
                        doc.data().data.video_thumbnail != undefined
                          ? doc.data().data.video_thumbnail
                          : "default"
                      );
                    }

                    firebase
                      .firestore()
                      .collection("user_data")
                      .doc(JSON.parse(localStorage.getItem("user")).uid)
                      .set(data, { merge: true })
                      .then((response) => {});
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
        });
      setShownTopic(topicList);
    }

    fetchData();
  }, [topicList, ind]);

  useEffect(() => {
    let db = firebase.firestore();
    let villageRef = db.collection("large_village_list");
    // let villageRef = db.collection("large_village_detail");

    villageRef
      .where("village_document_id", "==", keyy)
      .get()
      .then((doc) => {
        doc?.forEach((docc) => {
          if (docc?.data()?.["thumbnail_image_id"]) {
            setVillageThumbnailId(docc?.data()["thumbnail_image_id"]);
          } else {
            if (topicsData) {
              if (
                topicsData?.culture_profile !== undefined &&
                topicsData?.culture_profile?.media?.[0]?.media_name
              ) {
                setVillageThumbnailId(
                  topicsData?.culture_profile?.media?.[0]?.media_name
                );
              } else {
                setVillageThumbnailId("000");
              }
            }
          }
        });
      });
  }, [keyy, topicsData]);

  const handleCaption = () => {
    setCaptionShow(true);
  };

  const handleAllEditFunc = (text_label, text_name, item, index) => {
    if (
      allEditInput?.caption == "" ||
      allEditInput.caption == undefined ||
      primaryArray == []
    ) {
      setErrorInput(true);
    } else {
      setAboutsuccess(true);
      if (
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user"))?.displayName
      ) {
        let data;
        let type = "about_edit";
        let main_field = text_label;
        let sub_field = text_name;
        if (
          [
            "festivals",
            "traditional_food",
            "heritage_places",
            "belief",
            "ornaments",
            "prominent_artists",
            "traditional_dress",
            "famous_personalities",
            "traditional_art",
          ].includes(text_label)
        ) {
          main_field = text_label;
          sub_field = text_name;
          type = "about_edit";
        } else if (
          ["culture_profile", "geographical", "developmental"].includes(
            text_label
          )
        ) {
          main_field = text_label;
          sub_field = text_name;
          type = "about_edit";
          if (
            sub_field == "primary_source_of_livelihood" ||
            sub_field == "primary_source_of_drinking_water"
          ) {
            allEditInput.caption = JSON.stringify(primaryArray);
          }
        }
        if (
          text_name == "local_language" ||
          text_name == "electricty" ||
          text_name == "internet"
        ) {
          main_field = text_label;
          type = "about_edit";
        }
        if (
          text_name == "primary_source_of_livelihood" ||
          text_name == "primary_source_of_drinking_water" ||
          text_name == "main_produces_edit"
        ) {
          type = "about_edit";
          sub_field = text_name;
        }
        if (text_name == "village_name_edit") {
          if (allEditInput.caption) {
            data = {
              type: text_name,
              village_name: villageData.data.village_name,
              block_name: villageData.data.block_name,
              district_name: villageData.data.district_name,
              value: allEditInput?.caption,
              timestamp: Date.now(),
              village_key: keyy,
              state_name: villageData.data.state_name,
              created_by: localStorage.getItem("user")
                ? JSON.parse(localStorage.getItem("user")).displayName
                : null,
              status: "no action",
            };
          }
        } else if (text_name == "caption_edit") {
          let type = "caption_edit";
          if (text_label == "culture_profile") {
            if (allEditInput.caption) {
              data = {
                json_field: text_label,
                image_index: index ? index : 0,
                timestamp: Date.now(),
                caption: allEditInput?.caption,
                block_name: villageData.data.block_name,
                district_name: villageData.data.district_name,
                village_key: keyy,
                village_name: villageData.data.village_name,
                type: type,
                state_name: villageData.data.state_name,
                created_by: localStorage.getItem("user")
                  ? JSON.parse(localStorage.getItem("user")).displayName
                  : null,
                status: "no action",
              };
            }
          } else {
            let i_idx = 0;
            let m_idx = 0;
            topicsData?.[selectedTopic]?.map((itm, m_ind) => {
              if (itm?.media?.map((i) => i && i?.id)) {
                if (
                  itm?.media?.map((i) => i && i?.id)?.indexOf(item?.id) >= 0
                ) {
                  i_idx = itm?.media?.map((i) => i && i?.id)?.indexOf(item?.id);
                  m_idx = m_ind;
                }
              }
            });
            if (allEditInput.caption) {
              data = {
                json_field: text_label,
                image_index: i_idx ? i_idx : 0,
                caption: allEditInput?.caption,
                timestamp: Date.now(),
                village_key: keyy,
                village_name: villageData.data.village_name,
                block_name: villageData.data.block_name,
                district_name: villageData.data.district_name,
                type: type,
                main_index: m_idx ? m_idx : 0,
                state_name: villageData.data.state_name,
                created_by: localStorage.getItem("user")
                  ? JSON.parse(localStorage.getItem("user")).displayName
                  : null,
                status: "no action",
              };
            }
          }
        } else {
          if (
            text_label == "culture_profile" ||
            text_label == "developmental" ||
            text_label == "geographical"
          ) {
            if (allEditInput.caption) {
              data = {
                village_name: villageData.data.village_name,
                timestamp: Date.now(),
                main_field: main_field,
                value: allEditInput?.caption,
                block_name: villageData.data.block_name,
                district_name: villageData.data.district_name,
                village_key: keyy,
                type: type,
                state_name: villageData.data.state_name,
                created_by: localStorage.getItem("user")
                  ? JSON.parse(localStorage.getItem("user")).displayName
                  : null,
                status: "no action",
                sub_field: sub_field,
              };
            }
          } else {
            if (allEditInput.caption) {
              data = {
                village_name: villageData.data.village_name,
                timestamp: Date.now(),
                main_field: main_field,
                value: allEditInput?.caption,
                block_name: villageData.data.block_name,
                district_name: villageData.data.district_name,
                village_key: keyy,
                main_index: index ? index : 0,
                type: type,
                state_name: villageData.data.state_name,
                created_by: localStorage.getItem("user")
                  ? JSON.parse(localStorage.getItem("user")).displayName
                  : null,
                status: "no action",
                sub_field: sub_field,
              };
            }
          }
        }
        firebase
          .firestore()
          .collection("large_village_suggest_edit")
          .add(data)
          .then((response) => {
            setAboutsuccess(true);
            setTimeout(() => {
              setAboutsuccess(false);
              document.body.click();
            }, 1000);
          });
      } else {
        history.push("/login");
      }
    }
    setTimeout(() => {
      setErrorInput(false);
      setAboutsuccess(false);
    }, 2000);
  };

  const allEditInputFunc = (e) => {
    let val = e.target.value;
    setAllEditInput((p) => ({ ...p, caption: val }));
  };
  const PrimaryText = (e) => {
    let val = e.target.value;
    setAllEditInput((p) => ({ ...p, caption: val }));
    setPrimary(e.target.value);
  };

  const PrimaryTextClick = () => {
    if (primary && primary?.length > 0) {
      let data = [...primaryArray];
      data.push(primary);
      setPrimaryArray(data);
    }
    setPrimary("");
  };

  const removePrimary = (itm) => {
    let data = [...primaryArray];
    data.push(primary);
    data = data?.filter((item) => item != itm);
    const results = data.filter((element) => {
      return element !== "";
    });
    setAllEditInput((p) => ({ ...p, caption: results }));
    setPrimaryArray(results);
  };

  const handleAllEditcontent = (text_label, text_name, item = null, index) => {
    let cols = 26;
    if (window.innerWidth >= 767) {
      cols = 41;
    } else {
      cols = 26;
    }

    return (
      <>
        {isEdit && (
          <div className="px-2 custom_edit">
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="auto"
              overlay={
                <Popover
                  id="popover-basic"
                  style={{
                    height: "auto",
                  }}
                >
                  <Popover.Header>
                    {aboutsuccess && (
                      <div className="alert alert-success" role="alert">
                        Submitted For Review
                      </div>
                    )}
                    {errorInput && (
                      <div className="alert alert-danger" role="alert">
                        Please Enter Details
                      </div>
                    )}
                  </Popover.Header>
                  <Popover.Body>
                    <div>
                      {text_name == "primary_source_of_livelihood" ||
                      text_name == "primary_source_of_drinking_water" ? (
                        <div>
                          {primaryArray &&
                            primaryArray?.length > 0 &&
                            primaryArray?.map((itm) => {
                              return (
                                <div>
                                  <div className="p-2">
                                    <span>{itm}</span>
                                    <img
                                      role="button"
                                      onClick={() => removePrimary(itm)}
                                      src={closeLogo}
                                      style={{
                                        height: "13px",
                                        paddingLeft: "10px",
                                      }}
                                    ></img>
                                  </div>
                                </div>
                              );
                            })}
                          <div className="py-2 d-flex">
                            <input
                              type="text"
                              style={{ width: "80%" }}
                              onChange={(e) => PrimaryText(e)}
                              value={primary}
                            />
                            <button
                              onClick={() => PrimaryTextClick()}
                              style={{ paddingLeft: "10px" }}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <textarea
                            name="caption"
                            className="handleEditInput"
                            style={{ width: "100%" }}
                            rows="7"
                            onChange={(e) => allEditInputFunc(e)}
                            value={allEditInput?.caption}
                          ></textarea>
                        </>
                      )}
                    </div>
                    <div className="d-flex flex-row justify-content-end">
                      <div
                        className="btn th-bg-primary suggest_edit"
                        onClick={() =>
                          handleAllEditFunc(text_label, text_name, item, index)
                        }
                      >
                        Submit For Review
                      </div>
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <span
                  className="d-flex  align-items-center"
                  style={{ paddingTop: "3px" }}
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      setAllEditInput({
                        caption: RemoveCapturedBy(item?.caption),
                      });
                    } else {
                      history.push("/login");
                    }
                  }}
                >
                  <img
                    onClick={() => {
                      setPrimaryArray(item);
                    }}
                    src={EditIcon}
                    style={{
                      width: "16px",
                      height: "16px",
                      cursor: "pointer",
                    }}
                    alt="..."
                  />{" "}
                  {text_name == "about_edit_village" ? (
                    <span
                      style={{
                        color: "#3CAF5C",
                        fontWeight: "semi-bold",
                        fontSize: "18px",
                        cursor: "pointer",
                        paddingLeft: "2px",
                      }}
                    >
                      Edit Info
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </OverlayTrigger>
          </div>
        )}
      </>
    );
  };

  Object.entries(topicsData)?.map((d) => {
    if (d[1] != null) {
      if (typeof d[1] == "object" && d[1].length != undefined) {
        d[1]?.map((a) => {
          if (a.media != null) {
            if (a.media.length > 0) {
              a.media?.map((item, index) => {
                item != null && allpictures.push(item);
              });
            }
          }
        });
      }
    }
  });
  const gallery_photos = [];

  // proper order
  const order = [
    "culture_profile",
    "heritage_places",
    "festivals",
    "traditional_art",
    "traditional_food",
    "traditional_dress",
    "ornaments",
    "belief",
    "famous_personalities",
    "prominent_artists",
    "geographical",
    "developmental",
    "image_gallery",
    "near_village",
  ];
  const removeContentWords = [undefined, null];
  Object.entries(topicsData)?.map((d) => {
    galleryList?.map((i) => {
      if (d[0] == i) {
        if (d[1] != null) {
          if (typeof d[1] == "object" && d[1].length != undefined) {
            gallery_photos.push({ [i]: d[1] });
          }
        }
      }
    });
  });

  gallery_photos.sort((a, b) => {
    const aIndex = order.indexOf(Object.keys(a)[0]);
    const bIndex = order.indexOf(Object.keys(b)[0]);
    return aIndex - bIndex;
  });
  const gallery_photos_temp = ["all_photos"];

  Object.entries(gallery_photos)?.map((d) => {
    Object.entries(d[1])?.map((i) => {
      if (i[1] != null) {
        if (typeof i[1] == "object" && i[1].length != undefined) {
          i[1]?.map((a) => {
            if (a.media != null) {
              if (a.media?.length > 0) {
                a?.media?.map((item) => {
                  item != null && gallery_photos_temp.push(i[0]);
                });
              }
            }
          });
        }
      }
    });
  });

  const mediaItem = (i) => {
    if (i.media != null && i.media?.length != 0 && i.media?.length > 1) {
      return (
        i.media?.length != 0 &&
        i.media != null &&
        i.media.filter((e) => parseInt(e.status) !== -1).length > 0
      );
    } else if (
      i.media?.map((item) => {
        return item != null;
      }) &&
      i.media?.length === 1 &&
      i.media[0] != null
    ) {
      return (
        i.media?.length != 0 &&
        i.media != null &&
        i.media.filter((e) => parseInt(e.status) !== -1).length > 0
      );
    } else {
      return i.media?.length === 0 && i.media == null;
    }
  };

  const UpdatePhotoIndex = (id) => {
    setSelectedPhotoId(id);
    allpictures?.map((item, ind) => {
      item?.id === id && setPhotoIndex(ind);
    });
    setIsOpen(true);
  };
  useEffect(() => {
    const fetchImageData = async (mediaArray) => {
      const resetImageArray = await Promise.all(
        mediaArray?.map(async (item) => {
          if (item.media_type !== "video") {
            const thumb = await getData2(item?.media_name);
            item.image_path = thumb;
            item.thumbnail = thumb;
            return item;
          }
          return null;
        })
      );
      return resetImageArray.filter((item) => item !== null);
    };

    if (selectedTopic === "image_gallery" && typeof topicsData === "object") {
      let allpictures_temp = [];
      Object.entries(topicsData)?.map((d) => {
        if (d[1] != null) {
          if (typeof d[1] == "object" && d[1]?.length != undefined) {
            d[1]?.map((a) => {
              if (a?.media != null) {
                if (a?.media.length > 0) {
                  a?.media?.map((item, index) => {
                    item != null &&
                      item.media_type != "video" &&
                      allpictures_temp.push(item);
                  });
                }
              }
            });
          }
        }
      });

      let cultureMedia = topicsData?.culture_profile?.media;
      allpictures_temp = [...cultureMedia, ...allpictures_temp];

      fetchImageData(allpictures_temp).then((all_reset_image) => {
        setResetImageGellary(all_reset_image);
      });

      if (topicsData?.heritage_places) {
        const heritage_temp = [];
        topicsData?.heritage_places?.forEach((item) => {
          if (item?.media != null) {
            if (item?.media?.length > 0) {
              item?.media?.forEach((a) => {
                if (a?.media_type !== "video") {
                  heritage_temp?.push(a);
                }
              });
            }
          }
        });

        fetchImageData(heritage_temp).then((reset_heritage_image) => {
          setResetImageHeritage(reset_heritage_image);
        });
      }

      if (topicsData?.festivals) {
        const festival_temp = [];
        topicsData?.festivals?.forEach((item) => {
          if (item.media != null) {
            if (item.media.length > 0) {
              item?.media?.forEach((a) => {
                if (a.media_type !== "video") {
                  festival_temp.push(a);
                }
              });
            }
          }
        });

        fetchImageData(festival_temp).then((reset_festival_image) => {
          setResetImageFestivals(reset_festival_image);
        });
      }

      if (topicsData?.traditional_food) {
        const traditional_food_temp = [];
        topicsData?.traditional_food?.forEach((item) => {
          if (item.media != null) {
            if (item.media.length > 0) {
              item?.media?.forEach((a) => {
                if (a.media_type !== "video") {
                  traditional_food_temp.push(a);
                }
              });
            }
          }
        });

        fetchImageData(traditional_food_temp).then((reset_food_image) => {
          setResetImagesFood(reset_food_image);
        });
      }

      if (topicsData?.traditional_dress) {
        const traditional_dress_temp = [];
        topicsData?.traditional_dress?.forEach((item) => {
          if (item.media != null) {
            if (item.media.length > 0) {
              item?.media?.forEach((a) => {
                if (a.media_type !== "video") {
                  traditional_dress_temp.push(a);
                }
              });
            }
          }
        });

        fetchImageData(traditional_dress_temp).then((reset_tdr_image) => {
          setResetImageDress(reset_tdr_image);
        });
      }

      if (topicsData?.ornaments) {
        const ornaments_temp = [];
        topicsData?.ornaments?.forEach((item) => {
          if (item.media != null) {
            if (item.media.length > 0) {
              item?.media?.forEach((a) => {
                if (a.media_type !== "video") {
                  ornaments_temp.push(a);
                }
              });
            }
          }
        });

        fetchImageData(ornaments_temp).then((reset_or_image) => {
          setResetImageOrnaments(reset_or_image);
        });
      }

      if (topicsData?.famous_personalities) {
        const traditional_art_temp = [];
        topicsData?.traditional_art?.forEach((item) => {
          if (item.media != null) {
            if (item.media.length > 0) {
              item?.media?.forEach((a) => {
                if (a.media_type !== "video") {
                  traditional_art_temp.push(a);
                }
              });
            }
          }
        });

        fetchImageData(traditional_art_temp).then((reset_art_image) => {
          setResetImagesArt(reset_art_image);
        });
      }

      if (topicsData?.famous_personalities) {
        const famous_personalities_temp = [];
        topicsData?.famous_personalities?.forEach((item) => {
          if (item?.media != null) {
            if (item?.media?.length > 0) {
              item?.media?.forEach((a) => {
                if (a?.media_type !== "video") {
                  famous_personalities_temp.push(a);
                }
              });
            }
          }
        });

        fetchImageData(famous_personalities_temp).then((reset_tmp_image) => {
          setResetImagesFamous(reset_tmp_image);
        });
      }
      // Similar operations for other sections like traditional_dress, ornaments, traditional_food, famous_personalities, traditional_art...
    }
  }, [topicsData, selectedTopic]);

  // if string includes removeWords find index of the word and remove it till end
  const RemoveCapturedBy = (cap) => {
    if (/captured by/i.test(cap)) {
      // return cap.replace(/captured by.*$/i, "").trim();
      // return cap.replace(/(?:,\s*|)\b(captured by)\b/gi, "").trim();
      return cap.split(/captured by/i)[0].trim();
    }
    return cap.replace(/(?:,\s*|)\b(CSC agent|CSC)\b/gi, "").trim();
  };

  // if items length is less than 2 i.e. either its is "Na" || "N/A" || "NO" || "-" it will not return anything
  const FilterNaItems = (item) => {
    if (item != null && item.length > 1) {
      return item;
    }
  };

  const collectBadges = (topic) => {
    let userId = JSON.parse(localStorage.getItem("user"))?.uid;

    if (userId) {
      setClaimLoader(true);
      firebase
        .firestore()
        .collection("user_data")
        .doc(JSON.parse(localStorage.getItem("user")).uid)
        .get()
        .then((u_data) => {
          let recentBadges = u_data.data()?.recentBadges;

          //checking if the doc exist
          if (u_data.exists) {
            if (recentBadges) {
              //check for updating
              let checkForUpdate = false;

              //check if any badges have been claimed
              recentBadges?.map((item) => {
                if (item.media_name == indiBadgeImageID) checkForUpdate = true;
              });

              if (checkForUpdate)
                // the val remains the same if check if true
                recentBadges = recentBadges;
              //push item in array
              //update local data
              else
                recentBadges.push({
                  imageID: indiBadgeImageID,
                  villageID: villageID,
                  category: selectedTopic,
                });
            } else {
              recentBadges = [
                {
                  imageID: indiBadgeImageID,
                  villageID: villageID,
                  category: selectedTopic,
                },
              ];
            }

            firebase
              .firestore()
              .collection("user_data")
              .doc(JSON.parse(localStorage.getItem("user")).uid)
              .update({ recentBadges: recentBadges })
              .then((data) => {
                setOpenModel(false);
                setClaimLoader(false);
              })
              .catch((err) => {
                console.log("err", err);
              });
          } else {
            // if the doc doesnt exist

            //redeclaring cuz im evil - buaahhahah
            recentBadges = [
              {
                imageID: indiBadgeImageID,
                villageID: villageID,
                category: selectedTopic,
              },
            ];

            //setting firestore docs
            firebase
              .firestore()
              .collection("user_data")
              .doc(JSON.parse(localStorage.getItem("user")).uid)
              .set({ recentBadges: recentBadges })
              .then((data) => {
                setOpenModel(false);
                setClaimLoader(false);
              })
              .catch((err) => {
                console.log("err", err);
              });
          }
        });
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    setOpenModel(false);
    if (selectedTopic == "ornaments" || selectedTopic == "traditional_art") {
      setTimeout(() => {
        //fetching all indibadges
        firebase
          .firestore()
          .collection("village_badges")
          .doc(villageID)
          .get()
          .then(async (doc) => {
            const tempOrnaments = [];
            const tempArt = [];

            if (doc.exists) {
              // only run if the doc has category
              const currentDoc = doc.data();

              //get all the values from ornaments
              for (
                let counter = 0;
                counter < currentDoc.category.ornaments.length;
                counter++
              ) {
                tempOrnaments.push({
                  image: currentDoc.category.ornaments[counter].media_name,
                  name: currentDoc.category.ornaments[counter].name,
                });
              }
              //get all the values from traditional_art
              for (
                let counter = 0;
                counter < currentDoc.category.traditional_art.length;
                counter++
              ) {
                tempArt.push({
                  image:
                    currentDoc.category.traditional_art[counter].media_name,
                  name: currentDoc.category.traditional_art[counter].name,
                });
              }

              //get a random value
              const randomOrnamentIndiBadge =
                tempOrnaments[Math.floor(Math.random() * tempOrnaments.length)];
              const randomArtIndiBadge =
                tempArt[Math.floor(Math.random() * tempArt.length)];

              //assign to specifc category
              if (selectedTopic == "ornaments") {
                //fetch the signed url
                const randomOrnamentImage = await getData(
                  randomOrnamentIndiBadge?.image
                );
                setRandomImgBadge(randomOrnamentImage); //random img src to show
                setRandomImgBadgeName(randomOrnamentIndiBadge?.name); //name that needs to be shown
                setIndiBadgeImageId(randomOrnamentIndiBadge?.image); // id that needs to be updated in recentBadges
              }

              if (selectedTopic == "traditional_art") {
                //fetch the signed url
                const randomArtImage = await getData(randomArtIndiBadge?.image);

                setRandomImgBadge(randomArtImage); //random img src to show
                setRandomImgBadgeName(randomArtIndiBadge?.name); //name that needs to be shown
                setIndiBadgeImageId(randomArtIndiBadge?.image); // id that needs to be updated in recentBadges
              }

              //check if the user already has the indibadge
              //getting user data
              firebase
                .firestore()
                .collection("user_data")
                .doc(JSON.parse(localStorage.getItem("user")).uid)
                .get()
                .then((u_data) => {
                  if (u_data.exists) {
                    let recentBadges = u_data.data()?.recentBadges;

                    if (recentBadges) {
                      let checkForUpdate = false;

                      //check if any badges have been claimed
                      recentBadges?.map((item) => {
                        //check which category is clicked
                        if (selectedTopic == "traditional_art") {
                          if (item.media_name == randomArtIndiBadge?.image)
                            checkForUpdate = true;
                        } else if (selectedTopic == "ornaments") {
                          if (item.media_name == randomOrnamentIndiBadge?.image)
                            checkForUpdate = true;
                        }
                      });

                      //open the modal if the value is not present
                      if (!checkForUpdate) setOpenModel(true);
                    } else {
                      // recentbadges might not be present in all user profiles
                      setOpenModel(true);
                    }
                  }
                });
            }
          });
      }, 300);
    }
    if (selectedTopic == "developmental") {
      setPrimaryArray(topicsData?.developmental?.primary_source_of_livelihood);
    }
  }, [selectedTopic]);

  const awesomePlaceholder = (
    <div className="mt-5">
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "40px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "40px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "40px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "40px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "40px", margin: "0" }} />
      </div>
    </div>
  );

  const awesomePlaceholderDetail = (
    <div className="mt-2">
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "400px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "60px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "50px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "40px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "30px", margin: "0" }} />
      </div>
    </div>
  );

  const awesomePlaceholderDetailMobile = (
    <div className="mt-2">
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "40px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "30px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "25px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "20px", margin: "0" }} />
      </div>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <RectShape color="#dadada" style={{ height: "10px", margin: "0" }} />
      </div>
    </div>
  );

  useEffect(() => {
    if (images360 && images360.length > 0) {
      setNewList(temporaryTopiclist);
    } else {
      let data = temporaryTopiclist.filter((item) => item != "360_gallery");
      setNewList(data);
    }
  }, [images360]);

  useEffect(() => {
    if (!!searchValue) {
      setTimeout(() => {
        highlight(searchValue);
      }, 1500);
    }
  }, [location.state?.searchValue, selectedTopic, searchValue]);

  const handleLike = async () => {
    if (!firebase.auth().currentUser) {
      history.push("/login");
      return;
    }

    let newLikeCount = likeCount;
    let newIsLiked = isLiked;

    if (isLiked) {
      newLikeCount -= 1;
      newIsLiked = false;
    } else {
      newLikeCount += 1;
      newIsLiked = true;
    }

    setLikeCount(newLikeCount);
    setIsLiked(newIsLiked);

    try {
      const user = firebase.auth().currentUser;
      const token = await user.getIdToken();

      const res = await axios.post(
        "https://asia-south1-mgmd-356213.cloudfunctions.net/likeVillage",
        { village_id: guid },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!res.data.success) {
        // If the server response indicates failure, revert the like state
        setLikeCount(likeCount);
        setIsLiked(isLiked);
      }
    } catch (error) {
      console.log(error);
      // In case of error, revert the like state
      setLikeCount(likeCount);
      setIsLiked(isLiked);
    }
  };

  const isVillageLiked = async (guid) => {
    let token;
    const user = firebase.auth().currentUser;
    if (user) {
      token = await user.getIdToken();
      const res = await axios.post(
        "https://asia-south1-mgmd-356213.cloudfunctions.net/isVillageLiked",
        {
          village_id: guid,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.success) {
        setIsLiked(res.data.liked);
      }
    }
  };

  const convertLinks = (text) => {
    const urlPattern = /https?:\/\/[^\s]+/g;

    return text.split(" ").map((word, index) => {
      if (urlPattern.test(word)) {
        return (
          <a
            key={index}
            href={word}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "underline", // Underline to indicate it's a link
              fontWeight: "bold", // Optional: bold font for emphasis
            }}
          >
            {word}
          </a>
        );
      }
      return <span key={index}> {word} </span>;
    });
  };

  return (
    <>
      <MyDesktop>
        <MyLayout footer={false}>
          <div style={{ position: "fixed" }}>
            <Village fetchVillage={false} />
          </div>
          {/* Topic List */}

          <SuggestEdit
            show={modalShow}
            handleClose={handleModalClose}
            handleModalShow={handleModalShow}
            handleCaption={handleCaption}
            keyy={keyy}
            jsonField={selectedTopic}
            update={isUpdate}
            imageItemIndex={imageItemIndex}
            item={imageItem}
            selectedTopic={selectedTopic}
            topicsData={topicsData}
          />

          {/* Side Bar */}
          <div
            className="col-md-3 col-sm-2 my-5 mr-2"
            // style={{
            //   backgroundColor: "#fff",
            //   boxShadow: "rgb(152 136 177 / 25%) 14px 2px 30px 4px",
            //   borderRadius: "15px",
            //   height: "76vh",
            //   width: "20%",
            //   overflowY: "auto",
            //   overflowX: "hidden",
            // }}
          >
            <div
              style={{
                position: "fixed",
                backgroundColor: "#fff",
                boxShadow: "rgb(152 136 177 / 25%) 14px 2px 30px 4px",
                borderRadius: "15px",
                height: "76vh",
                width: "20%",
                overflowY: "auto",
                overflowX: "hidden",
                padding: "10px",
              }}
            >
              <ReactPlaceholder
                ready={topicsData}
                customPlaceholder={awesomePlaceholder}
              >
                <span className="pt-2">
                  {newList &&
                    order?.map((item, index) => {
                      return (
                        item != "360_gallery" && (
                          <>
                            <div
                              className={`th-pointer action-btn my-2 text-capitalize col ${
                                selectedTopic === item
                                  ? "th-fw-600 col selectiveColor"
                                  : null
                              }`}
                              style={{ overflowWrap: "break-word" }}
                              key={index}
                              onClick={() => {
                                setShow(false);
                                setOpenModel(false);
                                setSelectedTopic(item);
                                getClickedSection(item);
                              }}
                            >
                              {item === "geographical"
                                ? "Geographic and Demographic profile"
                                : item === "developmental"
                                ? "Developmental Profile"
                                : item === "festivals"
                                ? "Fairs and Festivals"
                                : item === "traditional_art"
                                ? "Traditional Art and Craft"
                                : item === "ornaments"
                                ? "Traditional Ornaments"
                                : item === "belief"
                                ? "Beliefs and Customs"
                                : item === "near_village"
                                ? "Explore more villages nearby"
                                : item.toLowerCase().split("_").join(" ")}
                            </div>
                          </>
                        )
                      );
                    })}
                </span>
              </ReactPlaceholder>
            </div>
          </div>

          {/* Village Details */}
          <>
            <div className="col-md-9 my-3">
              <div
                style={{
                  backgroundColor: "rgb(247 247 247)",
                  height: "28px",
                  width: "70%",
                  position: "absolute",
                  top: "72px",
                  zIndex: "222",
                }}
              ></div>

              <ReactPlaceholder
                ready={
                  topicsData?.village_name &&
                  topicsData?.village_name?.toLowerCase()
                }
                customPlaceholder={awesomePlaceholderDetailMobile}
              >
                {/* -----------------------------------------CULTURAL PROFILE----------------------------------------------- */}
                {/* {selectedTopic === "culture_profile" && */}
                {/* villageThumbnailId && ( */}
                <div
                  className="pr-1"
                  style={{
                    paddingRight: "1.5%",
                    // marginTop: "5%",
                  }}
                >
                  <div
                    style={{
                      position: "fixed",
                      top: "100px",
                      width:
                        window.innerWidth == "3840"
                          ? "72.3%"
                          : window.innerWidth == "1920"
                          ? "70.5%"
                          : "69.5%",
                      zIndex: "222",
                    }}
                  >
                    <div className="th-bg-white th-border-bottom py-3 px-3 mb-2 ">
                      <div className="d-flex row align-items-center mobile_edit_functionality">
                        <div className="village_name">
                          <div
                            onMouseLeave={(e) => {
                              setVillageStyle({ display: "block" });
                            }}
                          >
                            <div>
                              <div className="d-flex flex-row">
                                <div
                                  onMouseEnter={(e) => {
                                    setVillageStyle({ display: "block" });
                                  }}
                                >
                                  <h3 className="th-20 th-fw-600 mb-0 d-flex text-capitalize aboutVillage">
                                    {topicsData?.village_name
                                      ? topicsData?.village_name?.toLowerCase()
                                      : "Loading..."}

                                    {handleAllEditcontent(
                                      "culture_profile",
                                      "village_name_edit",
                                      {
                                        caption:
                                          topicsData?.village_name?.toLowerCase(),
                                      },
                                      null
                                    )}
                                  </h3>
                                </div>
                              </div>
                              <div className="th-14 th-grey-2 text-capitalize">
                                {topicsData?.block_name
                                  ? topicsData?.block_name?.toLowerCase() + ", "
                                  : null}
                                {topicsData?.district_name
                                  ? topicsData?.district_name?.toLowerCase()
                                  : null}
                                {topicsData?.state_name
                                  ? ", " + topicsData?.state_name?.toLowerCase()
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="village_lan">
                          <div className="d-flex sub_lan justify-content-end align-items-center">
                            <div
                              className="th-11 pr-1"
                              style={{ marginRight: "20px" }}
                            >
                              <div
                                className="d-flex align-items-start"
                                style={{ gap: "2px" }}
                              >
                                <div
                                  onClick={handleLike}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    height="24px"
                                    src={!isLiked ? Heart : HeartColored}
                                    alt="..."
                                  />
                                </div>
                                <div>{likeCount}</div>
                              </div>
                            </div>

                            <div
                              className="th-11 pr-1"
                              style={{ marginRight: "20px" }}
                            >
                              {/* <div
                            className="d-flex align-items-start"
                            style={{ gap: "2px" }}
                          >
                            <div>
                              <img height="24px" src={Eye} alt="..." />
                            </div>
                            <div>5000</div>
                          </div> */}
                            </div>

                            <div
                              className="th-11 pr-1"
                              style={{ marginRight: "20px" }}
                            >
                              <div
                                className="btn"
                                style={{
                                  borderColor: "#3CAF5C",
                                  color: "#3CAF5C",
                                }}
                                onClick={() => {
                                  if (isEdit) {
                                    setIsEdit(false);
                                  } else {
                                    setOpenContributeModal(true);
                                  }
                                }}
                              >
                                {isEdit ? "Cancel" : "Contribute"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="th-bg-white th-border-bottom th-border-top  pt-3 px-3 shadow-sm"
                    style={{
                      borderRadius: "20px",
                      marginTop: "2%",
                      padding: "5% 5% 3% 5%",
                    }}
                  >
                    <div>
                      <div
                        className="th-bg-white th-border-bottom mb-3 th-border-top"
                        style={{
                          borderRadius: "10px",
                          position: "relative",
                          overflow: "hidden",
                          marginTop: "5%",
                        }}
                      >
                        {currentVideo ? (
                          <YoutubeEmbed
                            embedId={currentVideo}
                            thumbnail={topicsData?.video_thumbnail}
                          />
                        ) : (
                          <div
                            style={{
                              borderRadius: "30px",
                              position: "relative",
                            }}
                            className="demo"
                            onMouseEnter={() => setOnHoverEff(true)}
                            onMouseLeave={() => setOnHoverEff(true)}
                          >
                            <VillageImage
                              getData={getData}
                              image_id={
                                villageThumbnailId
                                  ? villageThumbnailId
                                  : topicsData?.culture_profile?.media?.[0]
                                      ?.media_name
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {summary && (
                    <div className="th-br-15 my-5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px 5px 5px 15px",
                          width: "100%",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              color: cultureFontColor,
                              fontSize: "24px",
                              fontWeight: cultureFontWeight,
                            }}
                          >
                            Summary
                          </span>
                          <div style={{ padding: "20px" }}>
                            {convertLinks(summary)}
                          </div>
                        </div>
                      </div>
                      {/* // ) : null} */}
                    </div>
                  )}

                  {/* -----------------------------------------------About Village Description---------------------------------------------------- */}
                  <div
                    className="th-br-15 my-5"
                    ref={cultureProfileRef}
                    data-section="culture_profile"
                  >
                    {/* {selectedTopic === "culture_profile" ? ( */}
                    <Accordion
                      defaultExpanded={
                        topicsData?.culture_profile &&
                        Object.keys(topicsData?.culture_profile)?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            width: "50%",
                            backgroundColor: "#fff",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: cultureFontColor,
                                fontSize: "24px",
                                fontWeight: cultureFontWeight,
                              }}
                            >
                              Culture Profile
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>

                      <AccordionDetails>
                        <div className="pt-4 border-radius-content-village-deets">
                          <>
                            <div
                              className="d-flex flex-row bd-highlight mb-1"
                              onMouseLeave={(e) => {
                                setAboutEdit({ display: "block" });
                              }}
                              onMouseEnter={(e) => {
                                setAboutEdit({ display: "block" });
                              }}
                            >
                              <div>
                                <h3
                                  className="th-16 th-fw-600 mb-0 text-capitalize px-3"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  What is it famous for?
                                  <span>
                                    <div className="th-14 py-2 px-3">
                                      <div className="d-flex">
                                        {!topicsData?.culture_profile
                                          ?.your_village_famous_for && (
                                          <NoDataMessage />
                                        )}
                                        <span>
                                          <div className="inputText">
                                            {handleAllEditcontent(
                                              "culture_profile",
                                              "your_village_famous_for",
                                              {
                                                caption: "",
                                              }
                                            )}
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </span>
                                </h3>
                              </div>
                            </div>

                            {topicsData?.culture_profile
                              ?.your_village_famous_for &&
                              !textVillage.includes(
                                topicsData?.culture_profile
                                  ?.your_village_famous_for
                              ) && (
                                <>
                                  <div className="th-14 py-2 px-3">
                                    <div className="d-flex">
                                      <div className="inputText">
                                        {convertLinks(
                                          FilterNaItems(
                                            topicsData?.culture_profile
                                              ?.your_village_famous_for
                                          )
                                        )}
                                      </div>
                                      <span>
                                        <div className="">
                                          {handleAllEditcontent(
                                            "culture_profile",
                                            "your_village_famous_for",
                                            {
                                              caption: FilterNaItems(
                                                topicsData?.culture_profile
                                                  ?.your_village_famous_for
                                              ),
                                            }
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                          </>
                        </div>

                        <div className="border-radius-content-village-deets  my-3 ">
                          <div
                            className="d-flex flex-row bd-highlight mb-1 mt-2"
                            onMouseLeave={(e) => {
                              setAboutEdit({ display: "block" });
                            }}
                            onMouseEnter={(e) => {
                              setAboutEdit({ display: "block" });
                            }}
                          >
                            <div>
                              <h3
                                className="th-16 th-fw-600 mb-0 text-capitalize px-3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                How is it unique?
                                <span>
                                  <div className="th-14 py-2 px-3">
                                    <div className="d-flex">
                                      {!topicsData?.culture_profile
                                        ?.village_unique_from_other_village && (
                                        <NoDataMessage />
                                      )}
                                      <span>
                                        {handleAllEditcontent(
                                          "culture_profile",
                                          "village_unique_from_other_village",
                                          {
                                            caption: "",
                                          }
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </span>
                              </h3>
                            </div>
                          </div>

                          {topicsData?.culture_profile
                            ?.village_unique_from_other_village &&
                            !textVillage.includes(
                              topicsData?.culture_profile
                                ?.village_unique_from_other_village
                            ) && (
                              <>
                                <div className="th-14 py-2 px-3">
                                  <div className="d-flex">
                                    {" "}
                                    <div className="inputText">
                                      {convertLinks(
                                        FilterNaItems(
                                          topicsData?.culture_profile
                                            ?.village_unique_from_other_village
                                        )
                                      )}{" "}
                                    </div>
                                    <span>
                                      {handleAllEditcontent(
                                        "culture_profile",
                                        "village_unique_from_other_village",
                                        {
                                          caption: FilterNaItems(
                                            topicsData?.culture_profile
                                              ?.village_unique_from_other_village
                                          ),
                                        }
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                        </div>

                        <div className="border-radius-content-village-deets  my-3 ">
                          <div
                            className="d-flex flex-row bd-highlight mb-1 mt-2"
                            onMouseLeave={(e) => {
                              setAboutEdit({ display: "block" });
                            }}
                            onMouseEnter={(e) => {
                              setAboutEdit({ display: "block" });
                            }}
                          >
                            <div>
                              <h3
                                className="th-16 th-fw-600 mb-0 text-capitalize px-3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Any Historic Incident?
                                <span>
                                  <div className="th-14 py-2 px-3">
                                    <div className="d-flex">
                                      {!topicsData?.culture_profile
                                        ?.historic_incident && (
                                        <NoDataMessage />
                                      )}
                                      <span>
                                        {handleAllEditcontent(
                                          "culture_profile",
                                          "historic_incident",
                                          {
                                            caption: "",
                                          }
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </span>
                              </h3>
                            </div>
                          </div>

                          {topicsData?.culture_profile?.historic_incident &&
                            !textVillage.includes(
                              topicsData?.culture_profile?.historic_incident
                            ) && (
                              <>
                                <div className="th-14 py-2 px-3">
                                  <div className="d-flex">
                                    <div className="inputText">
                                      {convertLinks(
                                        FilterNaItems(
                                          topicsData?.culture_profile
                                            ?.historic_incident
                                        )
                                      )}
                                    </div>
                                    <span>
                                      {handleAllEditcontent(
                                        "culture_profile",
                                        "historic_incident",
                                        {
                                          caption: FilterNaItems(
                                            topicsData?.culture_profile
                                              ?.historic_incident
                                          ),
                                        }
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                        {/* <--------------------------------culture profile--------------CAROUSEL------------------------------------------------> */}
                        {topicsData?.culture_profile?.media &&
                        topicsData?.culture_profile?.media?.length > 0 &&
                        topicsData?.culture_profile?.media?.filter(
                          (e) => e.media_name !== undefined
                        ).length > 0 ? (
                          <div style={{ paddingBottom: "10px" }}>
                            <Carousel
                              interval={null}
                              activeIndex={cultureIndex}
                              onSelect={handleCultureIndex}
                              indicators={false}
                              controls={
                                topicsData?.culture_profile?.media?.length > 1
                              }
                              className={`th-bg-white th-border-bottom px-3 th-border-top ${
                                topicsData?.culture_profile?.media.length == 1
                                  ? "carsouel-main"
                                  : ""
                              }`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              {topicsData?.culture_profile?.media?.map(
                                (item, index) => {
                                  return (
                                    item != null &&
                                    item?.media_type != "video" &&
                                    parseInt(item?.status) !== -1 && (
                                      <Carousel.Item
                                        style={{
                                          position: "relative",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        <SliderImage
                                          getData={getData}
                                          image_id={item?.media_name}
                                        />
                                        <Carousel.Caption
                                          className="display-static pb-0"
                                          onMouseEnter={() =>
                                            setOnHoverEff(true)
                                          }
                                          onMouseLeave={() =>
                                            setOnHoverEff(true)
                                          }
                                        >
                                          <div className="d-block">
                                            <div className="d-flex ">
                                              <p
                                                className="th-12 mb-4"
                                                style={{
                                                  color: "#1e1d4c",
                                                }}
                                              >
                                                {item?.caption ? (
                                                  RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                ) : (
                                                  <NoDataMessage />
                                                )}
                                              </p>

                                              <div>
                                                {handleAllEditcontent(
                                                  "culture_profile",
                                                  "caption_edit",
                                                  item,
                                                  null,
                                                  index
                                                )}
                                              </div>
                                            </div>
                                          </div>

                                          {onHoverEff ? (
                                            <div>
                                              <img
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  handleModalShow(item, index)
                                                }
                                                src={PlusGreen}
                                                alt={item?.title}
                                              />
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </Carousel.Caption>
                                        {item?.caption && (
                                          <p className="text-disclaimer-with-edit px-3 pt-2">
                                            The images on the portal have been
                                            collected and uploaded only prior to
                                            the consent of the concerned person.
                                          </p>
                                        )}
                                      </Carousel.Item>
                                    )
                                  );
                                }
                              )}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {cultureIndex + 1} /
                              {topicsData?.culture_profile?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-16 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "600",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    {/* // ) : null} */}
                  </div>
                </div>
                {/* // )} */}

                {/* ----------------------------------------------heritage_places---------------------------------------------------- */}

                {/* {selectedTopic === "heritage_places" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={heritageRef}
                    data-section="heritage_places"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.heritage_places &&
                        Object.keys(topicsData?.heritage_places[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "50%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: heritageFontColor,
                                fontWeight: heritageFontWeight,
                                fontSize: "24px",
                              }}
                            >
                              Heritage Places
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.heritage_places && (
                          <>
                            {renderEditOption(
                              "heritage_places",
                              "description",
                              {
                                index: 0,
                              }
                            )}
                          </>
                        )}
                        {topicsData?.heritage_places &&
                        topicsData?.heritage_places?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={heritageIndex}
                              onSelect={handleHeritageIndex}
                              indicators={false}
                              controls={
                                topicsData?.heritage_places[0]?.media?.length >
                                1
                              }
                            >
                              {topicsData?.heritage_places
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4"
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "heritage_places",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {heritageIndex + 1} /
                              {topicsData?.heritage_places[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-16 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "600",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.heritage_places?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i?.title) ? null : (
                                <div
                                  className={
                                    "pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "description",
                                                  { caption: i?.description },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "heritage_places",
                                              "description",
                                              {
                                                caption: FilterNaItems(
                                                  i.description
                                                ),
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      {/* second field */}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            any story associated?{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.story_associate_with_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "story_associate_with_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.story_associate_with_heritage &&
                                        !textVillage.includes(
                                          i.story_associate_with_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.story_associate_with_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "story_associate_with_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.story_associate_with_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Type of Heritage{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.type_of_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "type_of_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.type_of_heritage &&
                                        !textVillage.includes(
                                          i.type_of_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "type_of_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.type_of_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Rituals/ Worship related to the
                                            Heritage{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.ritual_worship_related_to_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "ritual_worship_related_to_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.ritual_worship_related_to_heritage &&
                                        !textVillage.includes(
                                          i.ritual_worship_related_to_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.ritual_worship_related_to_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "ritual_worship_related_to_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.ritual_worship_related_to_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Deities related to the Heritage
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  <NoDataMessage />
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "deities_related_to_heritage_dsr",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.deities_related_to_heritage_dsr &&
                                        !textVillage.includes(
                                          i.deities_related_to_heritage_dsr
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.deities_related_to_heritage_dsr
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "deities_related_to_heritage_dsr",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.deities_related_to_heritage_dsr
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                        {(!topicsData?.heritage_places ||
                          topicsData?.heritage_places?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords.includes(i?.title) ? null : ( */}
                                <div
                                  className={
                                    "pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "description",
                                                  { caption: i?.description },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.description)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "heritage_places",
                                              "description",
                                              {
                                                caption: FilterNaItems(
                                                  i.description
                                                ),
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      {/* second field */}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            any story associated?{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.story_associate_with_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "story_associate_with_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.story_associate_with_heritage &&
                                        !textVillage.includes(
                                          i.story_associate_with_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.story_associate_with_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "story_associate_with_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.story_associate_with_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Type of Heritage{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.type_of_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "type_of_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.type_of_heritage &&
                                        !textVillage.includes(
                                          i.type_of_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "type_of_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.type_of_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Rituals/ Worship related to the
                                            Heritage{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.ritual_worship_related_to_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "ritual_worship_related_to_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.ritual_worship_related_to_heritage &&
                                        !textVillage.includes(
                                          i.ritual_worship_related_to_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.ritual_worship_related_to_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "ritual_worship_related_to_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.ritual_worship_related_to_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Deities related to the Heritage
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  <NoDataMessage />
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "deities_related_to_heritage_dsr",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.deities_related_to_heritage_dsr &&
                                        !textVillage.includes(
                                          i.deities_related_to_heritage_dsr
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.deities_related_to_heritage_dsr
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "deities_related_to_heritage_dsr",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.deities_related_to_heritage_dsr
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------FESTIVAL---------------------------------------------------- */}

                {/* {selectedTopic === "festivals" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={festivalsRef}
                    data-section="festivals"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.festivals &&
                        topicsData?.festivals?.length > 0 &&
                        Object.keys(topicsData?.festivals[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "50%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: festivalFontColor,
                                fontWeight: festivalFontWeight,
                                fontSize: "24px",
                              }}
                            >
                              Fairs & Festivals
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.festivals && (
                          <>
                            {renderEditOption("festivals", "description", {
                              index: 0,
                            })}
                          </>
                        )}
                        {topicsData?.festivals &&
                        topicsData?.festivals?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={festivalIndex}
                              onSelect={handleFestivalIndex}
                              indicators={false}
                              controls={
                                topicsData?.festivals[0]?.media?.length > 1
                              }
                            >
                              {topicsData?.festivals
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />

                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4"
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "festivals",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {festivalIndex + 1} /
                              {topicsData?.festivals[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-18 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "700",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.festivals?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i?.title) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "description",
                                                  {
                                                    caption: "",
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex ">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>

                                          <span>
                                            {handleAllEditcontent(
                                              "festivals",
                                              "description",
                                              {
                                                caption: FilterNaItems(
                                                  i.description
                                                ),
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  {/* second field */}

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Who Organizes Festival?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.who_organise_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "who_organise_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.who_organise_festival &&
                                        !textVillage.includes(
                                          i.who_organise_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.who_organise_festival
                                                )}
                                              </div>

                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "who_organise_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.who_organise_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      {/* third field */}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Who are the attendees?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.who_take_part_in_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "who_take_part_in_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.who_take_part_in_festival &&
                                        !textVillage.includes(
                                          i.who_take_part_in_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.who_take_part_in_festival
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "who_take_part_in_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.who_take_part_in_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      {/* fourth feild */}
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Type of Festival?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.type_of_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "type_of_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.type_of_festival &&
                                        !textVillage.includes(
                                          i.type_of_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_festival
                                                )}
                                              </div>

                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "type_of_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.type_of_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      {/* {dd} */}
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Any specific cuisine prepared?{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.any_specific_cusine_prepared && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "any_specific_cusine_prepared",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.any_specific_cusine_prepared &&
                                        !textVillage.includes(
                                          i.any_specific_cusine_prepared
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.any_specific_cusine_prepared
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "any_specific_cusine_prepared",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.any_specific_cusine_prepared
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                        {!topicsData?.festivals &&
                          topicsData?.festivals?.length === 0 &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords.includes(
                                  i?.title
                                ) ? null : ( */}
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "description",
                                                  {
                                                    caption: "",
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex ">
                                          <div className="inputText">
                                            {FilterNaItems(i.description)}
                                          </div>

                                          <span>
                                            {handleAllEditcontent(
                                              "festivals",
                                              "description",
                                              {
                                                caption: FilterNaItems(
                                                  i.description
                                                ),
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  {/* second field */}

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Who Organizes Festival?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.who_organise_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "who_organise_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.who_organise_festival &&
                                        !textVillage.includes(
                                          i.who_organise_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.who_organise_festival
                                                )}
                                              </div>

                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "who_organise_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.who_organise_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      {/* third field */}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Who are the attendees?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.who_take_part_in_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "who_take_part_in_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.who_take_part_in_festival &&
                                        !textVillage.includes(
                                          i.who_take_part_in_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.who_take_part_in_festival
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "who_take_part_in_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.who_take_part_in_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      {/* fourth feild */}
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Type of Festival?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.type_of_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "type_of_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.type_of_festival &&
                                        !textVillage.includes(
                                          i.type_of_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_festival
                                                )}
                                              </div>

                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "type_of_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.type_of_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      {/* {dd} */}
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Any specific cuisine prepared?{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.any_specific_cusine_prepared && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "any_specific_cusine_prepared",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.any_specific_cusine_prepared &&
                                        !textVillage.includes(
                                          i.any_specific_cusine_prepared
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.any_specific_cusine_prepared
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "any_specific_cusine_prepared",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.any_specific_cusine_prepared
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------ART & CRAFT---------------------------------------------------- */}
                {/* {selectedTopic === "traditional_art" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={artRef}
                    data-section="traditional_art"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.traditional_art &&
                        Object.keys(topicsData?.traditional_art[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "50%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: artFontColor,
                                fontWeight: artFontWeight,
                                fontSize: "24px",
                              }}
                            >
                              Traditional Art & Craft
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.traditional_art && (
                          <>
                            {renderEditOption(
                              "traditional_art",
                              "description",
                              {
                                index: 0,
                              }
                            )}
                          </>
                        )}

                        {topicsData?.traditional_art &&
                        topicsData?.traditional_art?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={artIndex}
                              onSelect={handleArtIndex}
                              indicators={false}
                              controls={
                                topicsData?.traditional_art[0]?.media?.length >
                                1
                              }
                            >
                              {topicsData?.traditional_art
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4 "
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "traditional_art",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <img
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleModalShow(item, index)
                                              }
                                              src={PlusGreen}
                                              alt={item?.title}
                                            />
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {artIndex + 1} /
                              {topicsData?.traditional_art[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-18 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "700",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.traditional_art?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(
                                i?.name_of_art
                              ) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.name_of_art}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                        <div>Description</div>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                  !textVillage.includes(i.description) ? (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {convertLinks(
                                            FilterNaItems(i.description)
                                          )}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_art",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        {!i.description && (
                                          <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                            <IconButton>
                                              <CiCircleInfo fill="#40B05E" />
                                            </IconButton>
                                          </Tooltip>
                                        )}

                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_art",
                                            "description",
                                            { caption: "" },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  {/* -------------------------------------------------------------------------------------------------------------------- */}
                                  {/* second field */}

                                  <div className="container">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>How is it designed?</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.design_and_motifs &&
                                        !textVillage.includes(
                                          i.design_and_motifs
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.design_and_motifs
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "design_and_motifs",
                                                  {
                                                    caption:
                                                      i?.design_and_motifs,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.design_and_motifs && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "design_and_motifs",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Art From Practiced in Village ?{" "}
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.art_from_pratice_in_village &&
                                        !textVillage.includes(
                                          i.art_from_pratice_in_village
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.art_from_pratice_in_village
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "art_from_pratice_in_village",
                                                  {
                                                    caption:
                                                      i?.art_from_pratice_in_village,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.art_from_pratice_in_village && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "art_from_pratice_in_village",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Colors Used</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.color_used &&
                                        !textVillage.includes(i.color_used) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.color_used)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "color_used",
                                                  { caption: i?.color_used },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.color_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "color_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Implement Tools</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.implement_tools &&
                                        !textVillage.includes(
                                          i.implement_tools
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.implement_tools
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "implement_tools",
                                                  {
                                                    caption: i?.implement_tools,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.implement_tools && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "implement_tools",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Stories, songs and popular
                                                sayings for Art and Craft
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.stories_songs &&
                                        !textVillage.includes(
                                          i.stories_songs
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.stories_songs)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "stories_songs",
                                                  { caption: i?.stories_songs },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.stories_songs && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "stories_songs",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Present status</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.present_status_of_art_form &&
                                        !textVillage.includes(
                                          i.present_status_of_art_form
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.present_status_of_art_form
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "present_status_of_art_form",
                                                  {
                                                    caption:
                                                      i?.present_status_of_art_form,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.present_status_of_art_form && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "present_status_of_art_form",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>More Information</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.more_information &&
                                        !textVillage.includes(
                                          i.more_information
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.more_information
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "more_information",
                                                  {
                                                    caption:
                                                      i?.more_information,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.more_information && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "more_information",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Individual & Group Activity
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.individual_or_group_activity &&
                                        !textVillage.includes(
                                          i.individual_or_group_activity
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.individual_or_group_activity
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "individual_or_group_activity",
                                                  {
                                                    caption:
                                                      i?.individual_or_group_activity,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.individual_or_group_activity && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "individual_or_group_activity",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Specific Communities Associated
                                                With
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.specific_communities_associated_with &&
                                        !textVillage.includes(
                                          i.specific_communities_associated_with
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.specific_communities_associated_with
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "specific_communities_associated_with",
                                                  {
                                                    caption:
                                                      i?.specific_communities_associated_with,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.specific_communities_associated_with && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "specific_communities_associated_with",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-md-6">
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Type of Art and Craft</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.type_of_art_craft &&
                                        !textVillage.includes(
                                          i.type_of_art_craft
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_art_craft
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "type_of_art_craft",
                                                  {
                                                    caption:
                                                      i?.type_of_art_craft,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.type_of_art_craft && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "type_of_art_craft",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Story on origin of Art & Craft
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.story_on_origin_of_art &&
                                        !textVillage.includes(
                                          i.story_on_origin_of_art
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.story_on_origin_of_art
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "story_on_origin_of_art",
                                                  {
                                                    caption:
                                                      i?.story_on_origin_of_art,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.story_on_origin_of_art && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "story_on_origin_of_art",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Relationship of the Art and
                                                Craft (if any){" "}
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.relationship_art_craft &&
                                        !textVillage.includes(
                                          i.relationship_art_craft
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.relationship_art_craft
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "relationship_art_craft",
                                                  {
                                                    caption:
                                                      i?.relationship_art_craft,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.relationship_art_craft && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "relationship_art_craft",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Material Used</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.material_used &&
                                        !textVillage.includes(
                                          i.material_used
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.material_used)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "material_used",
                                                  { caption: i?.material_used },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.material_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "material_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Occasion for Display and Sale
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.occasion_display_and_sale &&
                                        !textVillage.includes(
                                          i.occasion_display_and_sale
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.occasion_display_and_sale
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "occasion_display_and_sale",
                                                  {
                                                    caption:
                                                      i?.occasion_display_and_sale,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.occasion_display_and_sale && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "occasion_display_and_sale",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Practicing Communities</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.practicing_communities &&
                                        !textVillage.includes(
                                          i.practicing_communities
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.practicing_communities
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "practicing_communities",
                                                  {
                                                    caption:
                                                      i?.practicing_communities,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.practicing_communities && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "practicing_communities",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Suggestion for Revival and
                                                Economic Viability of Art Form
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.suggestion_for_revival_and_economic &&
                                        !textVillage.includes(
                                          i.suggestion_for_revival_and_economic
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.suggestion_for_revival_and_economic
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "suggestion_for_revival_and_economic",
                                                  {
                                                    caption:
                                                      i?.suggestion_for_revival_and_economic,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.suggestion_for_revival_and_economic && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "suggestion_for_revival_and_economic",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Classification of art form
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.classification_of_art_form &&
                                        !textVillage.includes(
                                          i.classification_of_art_form
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.classification_of_art_form
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "classification_of_art_form",
                                                  {
                                                    caption:
                                                      i?.classification_of_art_form,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.classification_of_art_form && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "classification_of_art_form",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Surface & treatment</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.technique_used &&
                                        !textVillage.includes(
                                          i.technique_used
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.technique_used
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "technique_used",
                                                  {
                                                    caption: i?.technique_used,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.technique_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "technique_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}

                        {(!topicsData?.traditional_art ||
                          topicsData?.traditional_art?.length === 0) &&
                          ["1"].map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords.includes(
                                  i?.name_of_art
                                ) ? null : ( */}
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.name_of_art}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                        <div>Description</div>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                  !textVillage.includes(i.description) ? (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {FilterNaItems(i.description)}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_art",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        {!i.description && (
                                          <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                            <IconButton>
                                              <CiCircleInfo fill="#40B05E" />
                                            </IconButton>
                                          </Tooltip>
                                        )}

                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_art",
                                            "description",
                                            { caption: "" },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  {/* -------------------------------------------------------------------------------------------------------------------- */}
                                  {/* second field */}

                                  <div className="container">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>How is it designed?</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.design_and_motifs &&
                                        !textVillage.includes(
                                          i.design_and_motifs
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.design_and_motifs
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "design_and_motifs",
                                                  {
                                                    caption:
                                                      i?.design_and_motifs,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.design_and_motifs && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "design_and_motifs",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Art From Practiced in Village ?{" "}
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.art_from_pratice_in_village &&
                                        !textVillage.includes(
                                          i.art_from_pratice_in_village
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.art_from_pratice_in_village
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "art_from_pratice_in_village",
                                                  {
                                                    caption:
                                                      i?.art_from_pratice_in_village,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.art_from_pratice_in_village && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "art_from_pratice_in_village",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Colors Used</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.color_used &&
                                        !textVillage.includes(i.color_used) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.color_used)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "color_used",
                                                  { caption: i?.color_used },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.color_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "color_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Implement Tools</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.implement_tools &&
                                        !textVillage.includes(
                                          i.implement_tools
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.implement_tools
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "implement_tools",
                                                  {
                                                    caption: i?.implement_tools,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.implement_tools && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "implement_tools",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Stories, songs and popular
                                                sayings for Art and Craft
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.stories_songs &&
                                        !textVillage.includes(
                                          i.stories_songs
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.stories_songs)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "stories_songs",
                                                  {
                                                    caption: i?.stories_songs,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.stories_songs && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "stories_songs",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Present status</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.present_status_of_art_form &&
                                        !textVillage.includes(
                                          i.present_status_of_art_form
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.present_status_of_art_form
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "present_status_of_art_form",
                                                  {
                                                    caption:
                                                      i?.present_status_of_art_form,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.present_status_of_art_form && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "present_status_of_art_form",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>More Information</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.more_information &&
                                        !textVillage.includes(
                                          i.more_information
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.more_information
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "more_information",
                                                  {
                                                    caption:
                                                      i?.more_information,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.more_information && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "more_information",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Individual & Group Activity
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.individual_or_group_activity &&
                                        !textVillage.includes(
                                          i.individual_or_group_activity
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.individual_or_group_activity
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "individual_or_group_activity",
                                                  {
                                                    caption:
                                                      i?.individual_or_group_activity,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.individual_or_group_activity && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "individual_or_group_activity",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Specific Communities Associated
                                                With
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.specific_communities_associated_with &&
                                        !textVillage.includes(
                                          i.specific_communities_associated_with
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.specific_communities_associated_with
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "specific_communities_associated_with",
                                                  {
                                                    caption:
                                                      i?.specific_communities_associated_with,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.specific_communities_associated_with && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "specific_communities_associated_with",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-md-6">
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Type of Art and Craft</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.type_of_art_craft &&
                                        !textVillage.includes(
                                          i.type_of_art_craft
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_art_craft
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "type_of_art_craft",
                                                  {
                                                    caption:
                                                      i?.type_of_art_craft,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.type_of_art_craft && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "type_of_art_craft",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Story on origin of Art & Craft
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.story_on_origin_of_art &&
                                        !textVillage.includes(
                                          i.story_on_origin_of_art
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.story_on_origin_of_art
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "story_on_origin_of_art",
                                                  {
                                                    caption:
                                                      i?.story_on_origin_of_art,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.story_on_origin_of_art && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "story_on_origin_of_art",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Relationship of the Art and
                                                Craft (if any){" "}
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.relationship_art_craft &&
                                        !textVillage.includes(
                                          i.relationship_art_craft
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.relationship_art_craft
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "relationship_art_craft",
                                                  {
                                                    caption:
                                                      i?.relationship_art_craft,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.relationship_art_craft && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "relationship_art_craft",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Material Used</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.material_used &&
                                        !textVillage.includes(
                                          i.material_used
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.material_used)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "material_used",
                                                  {
                                                    caption: i?.material_used,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.material_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "material_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Occasion for Display and Sale
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.occasion_display_and_sale &&
                                        !textVillage.includes(
                                          i.occasion_display_and_sale
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.occasion_display_and_sale
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "occasion_display_and_sale",
                                                  {
                                                    caption:
                                                      i?.occasion_display_and_sale,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.occasion_display_and_sale && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "occasion_display_and_sale",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Practicing Communities</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.practicing_communities &&
                                        !textVillage.includes(
                                          i.practicing_communities
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.practicing_communities
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "practicing_communities",
                                                  {
                                                    caption:
                                                      i?.practicing_communities,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.practicing_communities && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "practicing_communities",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Suggestion for Revival and
                                                Economic Viability of Art Form
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.suggestion_for_revival_and_economic &&
                                        !textVillage.includes(
                                          i.suggestion_for_revival_and_economic
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.suggestion_for_revival_and_economic
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "suggestion_for_revival_and_economic",
                                                  {
                                                    caption:
                                                      i?.suggestion_for_revival_and_economic,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.suggestion_for_revival_and_economic && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "suggestion_for_revival_and_economic",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Classification of art form
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.classification_of_art_form &&
                                        !textVillage.includes(
                                          i.classification_of_art_form
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.classification_of_art_form
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "classification_of_art_form",
                                                  {
                                                    caption:
                                                      i?.classification_of_art_form,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.classification_of_art_form && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "classification_of_art_form",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Surface & treatment</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.technique_used &&
                                        !textVillage.includes(
                                          i.technique_used
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.technique_used
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "technique_used",
                                                  {
                                                    caption: i?.technique_used,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.technique_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "technique_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------TRADITIONAL FOOD---------------------------------------------------- */}

                {/* {selectedTopic === "traditional_food" && ( */}
                {/* {selectedTopic === "traditional_food" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      marginTop: "5%",
                    }}
                    ref={foodRef}
                    data-section="traditional_food"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.traditional_food &&
                        Object.keys(topicsData?.traditional_food[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "50%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: foodFontColor,
                                fontWeight: foodFontWeight,
                                fontSize: "24px",
                              }}
                            >
                              Traditional Food
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.traditional_food && (
                          <>
                            {renderEditOption(
                              "traditional_food",
                              "description",
                              {
                                index: 0,
                              }
                            )}
                          </>
                        )}
                        {topicsData?.traditional_food &&
                        topicsData?.traditional_food?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <>
                            <Carousel
                              interval={null}
                              activeIndex={foodIndex}
                              onSelect={handleFoodIndex}
                              className="th-border-bottom pt-3 px-3 th-border-top"
                              style={{ borderRadius: "20px" }}
                              controls={
                                topicsData?.traditional_food[0]?.media?.length >
                                1
                              }
                              indicators={false}
                            >
                              {topicsData?.traditional_food
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, indexO) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4"
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {/* {topicsData.block_name ? (topicsData?.block_name?.toLowerCase()) + ", " : null} */}
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "traditional_food",
                                                    "caption_edit",
                                                    item,
                                                    null,
                                                    indexO
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {foodIndex + 1} /
                              {topicsData?.traditional_food[0]?.media?.length}
                            </div>
                          </>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-16 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "600",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {(!topicsData?.traditional_food ||
                          topicsData?.traditional_food?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {removeContentWords.includes(
                                  i?.title
                                ) ? null : (
                                  <div
                                    className={
                                      "pt-4 pb-3 border-radius-content-village-deets " +
                                      (index != 0 ? " mt-2" : "")
                                    }
                                  >
                                    <div className="bd-highlight">
                                      <div>
                                        <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                          <div>{i?.title}</div>
                                          <hr />
                                        </h3>
                                      </div>
                                    </div>

                                    {/* description */}
                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                          <div>Description</div>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.description &&
                                    !textVillage.includes(i.description) ? (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "traditional_food",
                                              "description",
                                              { caption: i?.description },
                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          {!i.description && <NoDataMessage />}
                                          <span>
                                            {handleAllEditcontent(
                                              "traditional_food",
                                              "description",
                                              { caption: "" },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            );
                          })}

                        {topicsData?.traditional_food?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i?.title) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                        <div>Description</div>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                  !textVillage.includes(i.description) ? (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {convertLinks(
                                            FilterNaItems(i.description)
                                          )}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_food",
                                            "description",
                                            { caption: i?.description },
                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        {!i.description && <NoDataMessage />}
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_food",
                                            "description",
                                            { caption: "" },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}
                {/* )} */}

                {/* ----------------------------------------------TRADITIONAL DRESS---------------------------------------------------- */}

                {/* {selectedTopic === "traditional_dress" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={dressRef}
                    data-section="traditional_dress"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.traditional_dress &&
                        Object.keys(topicsData?.traditional_dress[0])?.length >
                          1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "50%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: dressFontColor,
                                fontWeight: dressFontWeight,
                                fontSize: "24px",
                              }}
                            >
                              Traditional Dress
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.traditional_dress && (
                          <>
                            {renderEditOption(
                              "traditional_dress",
                              "description",
                              {
                                index: 0,
                              }
                            )}
                          </>
                        )}

                        {topicsData?.traditional_dress &&
                        topicsData?.traditional_dress?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              activeIndex={dressIndex}
                              onSelect={handleDressIndex}
                              className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              indicators={false}
                              controls={
                                topicsData?.traditional_dress[0]?.media
                                  ?.length > 1
                              }
                            >
                              {topicsData?.traditional_dress
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4"
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "traditional_dress",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {dressIndex + 1} /
                              {topicsData?.traditional_dress[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom my-3 pt-3 px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-12 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontSize: "18px",
                                          fontWeight: "500",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.traditional_dress?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i.title) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                        <div>Description</div>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                  !textVillage.includes(i.description) ? (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {convertLinks(
                                            FilterNaItems(i.description)
                                          )}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_dress",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        {!i.description && <NoDataMessage />}
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_dress",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          );
                        })}

                        {(!topicsData?.traditional_dress ||
                          topicsData?.traditional_dress?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {removeContentWords.includes(i.title) ? null : (
                                  <div
                                    className={
                                      "pt-4 pb-3 border-radius-content-village-deets " +
                                      (index != 0 ? " mt-2" : "")
                                    }
                                  >
                                    <div className="bd-highlight">
                                      <div>
                                        <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                          <div>{i?.title}</div>
                                          <hr />
                                        </h3>
                                      </div>
                                    </div>

                                    {/* description */}
                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                          <div>Description</div>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.description &&
                                    !textVillage.includes(i.description) ? (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "traditional_dress",
                                              "description",
                                              { caption: i?.description },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          {!i.description && <NoDataMessage />}
                                          <span>
                                            {handleAllEditcontent(
                                              "traditional_dress",
                                              "description",
                                              { caption: i?.description },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------ORNAMENTS---------------------------------------------------- */}

                {/* {selectedTopic === "ornaments" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={ornamentRef}
                    data-section="ornaments"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.ornaments &&
                        Object.keys(topicsData?.ornaments[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "50%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: ornamentsFontColor,
                                fontWeight: ornamentsFontWeight,
                                fontSize: "24px",
                              }}
                            >
                              Traditional Ornaments
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.ornaments && (
                          <>
                            {renderEditOption("ornaments", "description", {
                              index: 0,
                            })}
                          </>
                        )}
                        {topicsData.ornaments &&
                        topicsData?.ornaments?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom pt-3 px-3 th-border-top demo-animation"
                              style={{ borderRadius: "20px" }}
                              activeIndex={ornamentsIndex}
                              onSelect={handleOrnamentsIndex}
                              indicators={false}
                              controls={
                                topicsData?.ornaments[0]?.media?.length > 1
                              }
                            >
                              {topicsData?.ornaments
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4 "
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "ornaments",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {ornamentsIndex + 1} /
                              {topicsData?.ornaments[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-16 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "600",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.ornaments?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i?.title) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "ornaments",
                                                  "description",
                                                  { caption: i?.description },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "ornaments",
                                              "description",
                                              { caption: i?.description },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                            </>
                          );
                        })}
                        {(!topicsData?.ornaments ||
                          topicsData?.ornaments?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords.includes(i?.title) ? null : ( */}
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                        <div>Description</div>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                  !textVillage.includes(i.description) ? (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {convertLinks(
                                            FilterNaItems(i.description)
                                          )}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "ornaments",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        {!i.description && <NoDataMessage />}
                                        <span>
                                          {handleAllEditcontent(
                                            "ornaments",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------BELIEFS---------------------------------------------------- */}
                {/* {selectedTopic === "belief" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={beliefRef}
                    data-section="belief"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.belief &&
                        Object.keys(topicsData?.belief[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "50%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: beliefFontColor,
                                fontWeight: beliefFontWeight,
                                fontSize: "24px",
                              }}
                            >
                              Beliefs & Customs
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.belief && (
                          <>
                            {renderEditOption("belief", "description", {
                              index: 0,
                            })}
                          </>
                        )}
                        {topicsData.belief &&
                        topicsData?.belief?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={beliefIndex}
                              onSelect={handleBeliefIndex}
                              indicators={false}
                              controls={
                                topicsData?.belief[0]?.media?.length > 1
                              }
                            >
                              {topicsData?.belief
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    if (parseInt(item?.status) !== -1) {
                                      return (
                                        item != null &&
                                        item?.media_type != "video" &&
                                        parseInt(item?.status) !== -1 && (
                                          <Carousel.Item
                                            style={{
                                              position: "relative",
                                            }}
                                          >
                                            <SliderImage
                                              getData={getData}
                                              image_id={item?.media_name}
                                            />
                                            <Carousel.Caption
                                              className="display-static pb-0"
                                              onMouseEnter={() =>
                                                setOnHoverEff(true)
                                              }
                                              onMouseLeave={() =>
                                                setOnHoverEff(true)
                                              }
                                            >
                                              <div className="d-block pt-2">
                                                <div className="d-flex">
                                                  <p
                                                    className="th-12 mb-4"
                                                    style={{ color: "#1e1d4c" }}
                                                  >
                                                    {item?.caption ? (
                                                      RemoveCapturedBy(
                                                        item?.caption
                                                      )
                                                    ) : (
                                                      <NoDataMessage />
                                                    )}
                                                  </p>
                                                  <div>
                                                    {handleAllEditcontent(
                                                      "belief",
                                                      "caption_edit",
                                                      item
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {onHoverEff ? (
                                                <div>
                                                  <img
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleModalShow(
                                                        item,
                                                        index
                                                      )
                                                    }
                                                    src={PlusGreen}
                                                    alt={item?.title}
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </Carousel.Caption>
                                            {item?.caption && (
                                              <p className="text-disclaimer-with-edit px-3">
                                                The images on the portal have
                                                been collected and uploaded only
                                                prior to the consent of the
                                                concerned person.
                                              </p>
                                            )}
                                          </Carousel.Item>
                                        )
                                      );
                                    }
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {beliefIndex + 1} /
                              {topicsData?.belief[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom my-3 pt-3 px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-12 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontSize: "18px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {topicsData?.belief?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i?.title) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "description",
                                                  { caption: i?.description },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "description",
                                              { caption: i?.description },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Belief Related To
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.belief_related_to && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "belief_related_to",
                                                  {
                                                    caption:
                                                      i?.belief_related_to,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.belief_related_to &&
                                    !textVillage.includes(
                                      i.belief_related_to
                                    ) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.belief_related_to)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "belief_related_to",
                                              { caption: i?.belief_related_to },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Type of Belief
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.type_of_belief && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "type_of_belief",
                                                  {
                                                    caption: i?.type_of_belief,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.type_of_belief &&
                                    !textVillage.includes(i.type_of_belief) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.type_of_belief)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "type_of_belief",
                                              { caption: i?.type_of_belief },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                            </>
                          );
                        })}
                        {(!topicsData?.belief ||
                          topicsData?.belief?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords.includes(i?.title) ? null : ( */}
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "description",
                                                  { caption: i?.description },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "description",
                                              { caption: i?.description },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Belief Related To
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.belief_related_to && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "belief_related_to",
                                                  {
                                                    caption:
                                                      i?.belief_related_to,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.belief_related_to &&
                                    !textVillage.includes(
                                      i.belief_related_to
                                    ) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.belief_related_to)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "belief_related_to",
                                              { caption: i?.belief_related_to },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Type of Belief
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.type_of_belief && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "type_of_belief",
                                                  {
                                                    caption: i?.type_of_belief,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.type_of_belief &&
                                    !textVillage.includes(i.type_of_belief) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.type_of_belief)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "type_of_belief",
                                              { caption: i?.type_of_belief },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------FAMOUS PERSONALITY---------------------------------------------------- */}

                {/* {selectedTopic === "famous_personalities" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={famousRef}
                    data-section="famous_personalities"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.famous_personalities &&
                        Object.keys(topicsData?.famous_personalities[0])
                          ?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "50%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: famousFontColor,
                                fontWeight: famousFontWeight,
                                fontSize: "24px",
                              }}
                            >
                              Famous Personalities
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.famous_personalities && (
                          <>
                            {renderEditOption(
                              "famous_personalities",
                              "description",
                              { index: 0 }
                            )}
                          </>
                        )}

                        {topicsData?.famous_personalities &&
                        topicsData?.famous_personalities?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={famousIndex}
                              onSelect={handleFamousIndex}
                              indicators={false}
                              controls={
                                topicsData?.famous_personalities[0]?.media
                                  ?.length > 1
                              }
                            >
                              {topicsData?.famous_personalities
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4 "
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "famous_personalities",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>

                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {famousIndex + 1} /
                              {
                                topicsData?.famous_personalities[0]?.media
                                  ?.length
                              }
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom my-3 pt-3 px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-16 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "600",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {(!topicsData?.famous_personalities ||
                          topicsData?.famous_personalities?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <div
                                className={
                                  "pb-3 border-radius-content-village-deets " +
                                  (index != 0 ? " mt-2" : "")
                                }
                                key={index}
                              >
                                <div className="bd-highlight">
                                  <div>
                                    <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                      <div>
                                        {/* {i?.name_of_local_hero || <NoDataMessage />} */}
                                      </div>
                                      <hr />
                                    </h3>
                                  </div>
                                </div>

                                {/* description */}
                                <div
                                  className="d-flex flex-row bd-highlight mt-2"
                                  onMouseLeave={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                  onMouseEnter={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                >
                                  <div>
                                    <h3
                                      className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Personal Details{" "}
                                      {!i.personal_details && (
                                        <span>
                                          <NoDataMessage />
                                        </span>
                                      )}
                                      {handleAllEditcontent(
                                        "famous_personalities",
                                        "personal_details",
                                        { caption: i?.personal_details },
                                        index
                                      )}
                                    </h3>
                                  </div>
                                </div>

                                <div className="th-14 pb-1 px-3">
                                  <div className="d-flex">
                                    <div className="inputText">
                                      {i.personal_details &&
                                        !textVillage.includes(
                                          i.personal_details
                                        ) &&
                                        convertLinks(
                                          FilterNaItems(i.personal_details)
                                        )}
                                    </div>
                                    <span></span>
                                  </div>
                                </div>

                                <div
                                  className="d-flex flex-row bd-highlight mt-2"
                                  onMouseLeave={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                  onMouseEnter={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                >
                                  <div>
                                    <h3
                                      className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Person / Family related to (present)
                                      {!i.family_related_to && (
                                        <span>
                                          <NoDataMessage />
                                        </span>
                                      )}
                                      {handleAllEditcontent(
                                        "famous_personalities",
                                        "family_related_to",
                                        { caption: i?.family_related_to },
                                        index
                                      )}
                                    </h3>
                                  </div>
                                </div>

                                <div className="th-14 pb-1 px-3">
                                  <div className="d-flex">
                                    <div className="inputText">
                                      {i.family_related_to &&
                                        !textVillage.includes(
                                          i.family_related_to
                                        ) &&
                                        FilterNaItems(i.family_related_to)}
                                    </div>
                                    <span></span>
                                  </div>
                                </div>

                                <div
                                  className="d-flex flex-row bd-highlight mt-2"
                                  onMouseLeave={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                  onMouseEnter={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                >
                                  <div>
                                    <h3
                                      className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Any Legendary Stories?
                                      {!i.legend_stories && (
                                        <span>
                                          <NoDataMessage />
                                        </span>
                                      )}
                                      {handleAllEditcontent(
                                        "famous_personalities",
                                        "family_related_to",
                                        { caption: i?.family_related_to },
                                        index
                                      )}
                                    </h3>
                                  </div>
                                </div>

                                <div className="th-14 pb-1 px-3">
                                  <div className="d-flex">
                                    <div className="inputText">
                                      {i.legend_stories &&
                                        !textVillage.includes(
                                          i.legend_stories
                                        ) &&
                                        convertLinks(
                                          FilterNaItems(i.legend_stories)
                                        )}
                                    </div>
                                    <span></span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {topicsData?.famous_personalities?.map((i, index) => {
                          return (
                            <div
                              className={
                                "pb-3 border-radius-content-village-deets " +
                                (index != 0 ? " mt-2" : "")
                              }
                              key={index}
                            >
                              <div className="bd-highlight">
                                <div>
                                  <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                    <div>
                                      {/* {i?.name_of_local_hero || <NoDataMessage />} */}
                                    </div>
                                    <hr />
                                  </h3>
                                </div>
                              </div>

                              {/* description */}
                              <div
                                className="d-flex flex-row bd-highlight mt-2"
                                onMouseLeave={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                                onMouseEnter={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                              >
                                <div>
                                  <h3
                                    className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    Personal Details{" "}
                                    {!i.personal_details && (
                                      <span>
                                        <NoDataMessage />
                                      </span>
                                    )}
                                    {handleAllEditcontent(
                                      "famous_personalities",
                                      "personal_details",
                                      { caption: i?.personal_details },
                                      index
                                    )}
                                  </h3>
                                </div>
                              </div>

                              <div className="th-14 pb-1 px-3">
                                <div className="d-flex">
                                  <div className="inputText">
                                    {i.personal_details &&
                                      !textVillage.includes(
                                        i.personal_details
                                      ) &&
                                      convertLinks(
                                        FilterNaItems(i.personal_details)
                                      )}
                                  </div>
                                  <span></span>
                                </div>
                              </div>

                              <div
                                className="d-flex flex-row bd-highlight mt-2"
                                onMouseLeave={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                                onMouseEnter={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                              >
                                <div>
                                  <h3
                                    className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    Person / Family related to (present)
                                    {!i.family_related_to && (
                                      <span>
                                        <NoDataMessage />
                                      </span>
                                    )}
                                    {handleAllEditcontent(
                                      "famous_personalities",
                                      "family_related_to",
                                      { caption: i?.family_related_to },
                                      index
                                    )}
                                  </h3>
                                </div>
                              </div>

                              <div className="th-14 pb-1 px-3">
                                <div className="d-flex">
                                  <div className="inputText">
                                    {i.family_related_to &&
                                      !textVillage.includes(
                                        i.family_related_to
                                      ) &&
                                      FilterNaItems(i.family_related_to)}
                                  </div>
                                  <span></span>
                                </div>
                              </div>

                              <div
                                className="d-flex flex-row bd-highlight mt-2"
                                onMouseLeave={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                                onMouseEnter={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                              >
                                <div>
                                  <h3
                                    className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    Any Legendary Stories?
                                    {!i.legend_stories && (
                                      <span>
                                        <NoDataMessage />
                                      </span>
                                    )}
                                    {handleAllEditcontent(
                                      "famous_personalities",
                                      "family_related_to",
                                      { caption: i?.family_related_to },
                                      index
                                    )}
                                  </h3>
                                </div>
                              </div>

                              <div className="th-14 pb-1 px-3">
                                <div className="d-flex">
                                  <div className="inputText">
                                    {i.legend_stories &&
                                      !textVillage.includes(i.legend_stories) &&
                                      FilterNaItems(i.legend_stories)}
                                  </div>
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------prominent_artists---------------------------------------------------- */}

                {/* {selectedTopic === "prominent_artists" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={prominentRef}
                    data-section="prominent_artists"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.prominent_artists &&
                        Object.keys(topicsData?.prominent_artists[0])?.length >
                          1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "50%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: artistFontColor,
                                fontWeight: artistFontWeight,
                                fontSize: "24px",
                              }}
                            >
                              Prominent Artists
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.prominent_artists && (
                          <>
                            {renderEditOption(
                              "prominent_artists",
                              "description",
                              {
                                index: 0,
                              }
                            )}
                          </>
                        )}

                        {topicsData?.prominent_artists &&
                        topicsData?.prominent_artists?.length > 0 &&
                        topicsData?.prominent_artists?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={artistIndex}
                              onSelect={handleArtistIndex}
                              indicators={false}
                              controls={
                                topicsData?.prominent_artists[0]?.media
                                  ?.length > 1
                              }
                            >
                              {topicsData?.prominent_artists
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4"
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {artistIndex + 1} /
                              {topicsData?.prominent_artists[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-14"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "500",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.prominent_artists?.length > 0 &&
                          topicsData?.prominent_artists?.map((i, index) => {
                            return (
                              <>
                                {removeContentWords?.includes(
                                  i?.name
                                ) ? null : (
                                  <div
                                    className={
                                      "pb-3 border-radius-content-village-deets " +
                                      (index != 0 ? " mt-2" : "")
                                    }
                                  >
                                    <div className="bd-highlight">
                                      <div>
                                        <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                          <div>
                                            {" "}
                                            {FilterNaItems(i?.name)}
                                            {FilterNaItems(i?.gender)}
                                          </div>
                                          <hr />
                                        </h3>
                                      </div>
                                    </div>

                                    {/* frist field */}
                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Expertise
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.expertise_of_skills && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "expertise_of_skills",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.expertise_of_skills &&
                                      !textVillage.includes(
                                        i.expertise_of_skills
                                      ) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(
                                                i.expertise_of_skills
                                              )}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "expertise_of_skills",
                                                {
                                                  caption:
                                                    i?.expertise_of_skills,
                                                },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Gender
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.gender && <NoDataMessage />}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "gender",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.gender &&
                                      !textVillage.includes(i.gender) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(i.gender)}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "gender",
                                                { caption: i?.gender },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Age
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.age && <NoDataMessage />}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "age",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.age && !textVillage.includes(i.age) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.age)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "age",
                                              { caption: i?.age },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Address for Communication
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.address && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "address",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.address &&
                                      !textVillage.includes(i.address) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(i.address)}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "address",
                                                { caption: i?.address },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Pincode
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.pincode && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "pincode",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.pincode &&
                                      !textVillage.includes(i.pincode) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(i.pincode)}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "pincode",
                                                { caption: i?.pincode },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Category of expertise
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.expertise_of_category && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "expertise_of_category",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.expertise_of_category &&
                                      !textVillage.includes(
                                        i.expertise_of_category
                                      ) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(
                                                i.expertise_of_category
                                              )}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "expertise_of_category",
                                                {
                                                  caption:
                                                    i?.expertise_of_category,
                                                },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          ID card Type
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.id_card_type && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "id_card_type",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.id_card_type &&
                                      !textVillage.includes(i.id_card_type) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(
                                                i?.id_card_type == 1
                                                  ? "Aadhaar Card"
                                                  : i?.id_card_type == 2
                                                  ? "Driving License"
                                                  : i?.id_card_type == 3
                                                  ? "PAN Card"
                                                  : i?.id_card_type == 4
                                                  ? " Passport"
                                                  : i?.id_card_type == 5
                                                  ? " Pension Passbook"
                                                  : i?.id_card_type == 6
                                                  ? "   NPR Smart Card"
                                                  : i?.id_card_type == 7
                                                  ? "Voter ID"
                                                  : i?.id_card_type == 8
                                                  ? " Unique Disability ID (Unique ID for Persons with Disabilities)"
                                                  : i?.id_card_type == 9
                                                  ? " Ration Card with Photo"
                                                  : ""
                                              )}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "expertise_of_skills",
                                                {
                                                  caption:
                                                    i?.id_card_type == 1
                                                      ? "Aadhaar Card"
                                                      : i?.id_card_type == 2
                                                      ? "Driving License"
                                                      : i?.id_card_type == 3
                                                      ? "PAN Card"
                                                      : i?.id_card_type == 4
                                                      ? " Passport"
                                                      : i?.id_card_type == 5
                                                      ? " Pension Passbook"
                                                      : i?.id_card_type == 6
                                                      ? "   NPR Smart Card"
                                                      : i?.id_card_type == 7
                                                      ? "Voter ID"
                                                      : i?.id_card_type == 8
                                                      ? " Unique Disability ID (Unique ID for Persons with Disabilities)"
                                                      : i?.id_card_type == 9
                                                      ? " Ration Card with Photo"
                                                      : "",
                                                },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          ID Card Number
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.id_card_number && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "id_card_number",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.id_card_number &&
                                      !textVillage.includes(
                                        i.id_card_number
                                      ) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(i.id_card_number)}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "id_card_number",
                                                { caption: i?.id_card_number },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                )}
                              </>
                            );
                          })}

                        {(!topicsData?.prominent_artists ||
                          topicsData?.prominent_artists?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords?.includes(i?.name) ? null : ( */}
                                <div
                                  className={
                                    "pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>
                                          {" "}
                                          {FilterNaItems(i?.name)}
                                          {FilterNaItems(i?.gender)}
                                        </div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* frist field */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Expertise
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.expertise_of_skills && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "expertise_of_skills",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.expertise_of_skills &&
                                    !textVillage.includes(
                                      i.expertise_of_skills
                                    ) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(
                                              i.expertise_of_skills
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "expertise_of_skills",
                                              {
                                                caption: i?.expertise_of_skills,
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Gender
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.gender && <NoDataMessage />}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "gender",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.gender &&
                                    !textVillage.includes(i.gender) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.gender)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "gender",
                                              { caption: i?.gender },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Age
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.age && <NoDataMessage />}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "age",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.age && !textVillage.includes(i.age) && (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {FilterNaItems(i.age)}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "prominent_artists",
                                            "age",
                                            { caption: i?.age },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Address for Communication
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.address && <NoDataMessage />}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "address",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.address &&
                                    !textVillage.includes(i.address) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.address)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "address",
                                              { caption: i?.address },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Pincode
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.pincode && <NoDataMessage />}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "pincode",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.pincode &&
                                    !textVillage.includes(i.pincode) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.pincode)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "pincode",
                                              { caption: i?.pincode },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Category of expertise
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.expertise_of_category && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "expertise_of_category",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.expertise_of_category &&
                                    !textVillage.includes(
                                      i.expertise_of_category
                                    ) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(
                                              i.expertise_of_category
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "expertise_of_category",
                                              {
                                                caption:
                                                  i?.expertise_of_category,
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        ID card Type
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.id_card_type && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "id_card_type",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.id_card_type &&
                                    !textVillage.includes(i.id_card_type) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(
                                              i?.id_card_type == 1
                                                ? "Aadhaar Card"
                                                : i?.id_card_type == 2
                                                ? "Driving License"
                                                : i?.id_card_type == 3
                                                ? "PAN Card"
                                                : i?.id_card_type == 4
                                                ? " Passport"
                                                : i?.id_card_type == 5
                                                ? " Pension Passbook"
                                                : i?.id_card_type == 6
                                                ? "   NPR Smart Card"
                                                : i?.id_card_type == 7
                                                ? "Voter ID"
                                                : i?.id_card_type == 8
                                                ? " Unique Disability ID (Unique ID for Persons with Disabilities)"
                                                : i?.id_card_type == 9
                                                ? " Ration Card with Photo"
                                                : ""
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "expertise_of_skills",
                                              {
                                                caption:
                                                  i?.id_card_type == 1
                                                    ? "Aadhaar Card"
                                                    : i?.id_card_type == 2
                                                    ? "Driving License"
                                                    : i?.id_card_type == 3
                                                    ? "PAN Card"
                                                    : i?.id_card_type == 4
                                                    ? " Passport"
                                                    : i?.id_card_type == 5
                                                    ? " Pension Passbook"
                                                    : i?.id_card_type == 6
                                                    ? "   NPR Smart Card"
                                                    : i?.id_card_type == 7
                                                    ? "Voter ID"
                                                    : i?.id_card_type == 8
                                                    ? " Unique Disability ID (Unique ID for Persons with Disabilities)"
                                                    : i?.id_card_type == 9
                                                    ? " Ration Card with Photo"
                                                    : "",
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        ID Card Number
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.id_card_number && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "id_card_number",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.id_card_number &&
                                    !textVillage.includes(i.id_card_number) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.id_card_number)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "id_card_number",
                                              { caption: i?.id_card_number },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------GEOGRAPHICAL LOCATION------------------------------------------ */}

                {/* {selectedTopic === "geographical" && ( */}
                <div
                  className="pr-1"
                  ref={geoSectionRef}
                  data-section="geographical"
                >
                  <Accordion
                    defaultExpanded={
                      topicsData?.geographical &&
                      Object.keys(topicsData?.geographical)?.length > 1
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      style={{
                        position: "sticky",
                        top: "128px",
                        zIndex: "110",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          borderBottomLeftRadius: "15px",
                          borderBottomRightRadius: "15px",
                          padding: "5px 5px 5px 15px",
                          width: "50%",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              color: geoFontColor,
                              fontWeight: geoFontWeight,
                              fontSize: "24px",
                            }}
                          >
                            Geographic And Demographic Profile
                          </span>
                        </div>
                      </div>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div
                        className="th-bg-white th-border-bottom px-3 pb-2 th-border-top mr-2 img-fluid"
                        style={{
                          paddingRight: "15px",
                        }}
                      >
                        <iframe
                          className="mt-3 th-br-8"
                          title="location"
                          width="100%"
                          height="500"
                          style={{ border: 0 }}
                          allowFullscreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          src={`https://maps.google.com/maps?q=${topicsData?.geographical?.latitude_id},${topicsData?.geographical?.longitude_id}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                        />
                      </div>
                      <div className="row">
                        {generateNewCardComponent(
                          "Ancient Name",
                          "geographical",
                          "ancient_name",
                          LanguageIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Local Language",
                          "geographical",
                          "local_language",
                          LanguageIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Latitude Id",
                          "geographical",
                          "latitude_id",
                          LanguageIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Longitude Id",
                          "geographical",
                          "longitude_id",
                          LanguageIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Male",
                          "geographical",
                          "male_population",
                          CommunitiesIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Female",
                          "geographical",
                          "female_population",
                          CommunitiesIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Other Population",
                          "geographical",
                          "other_population",
                          CommunitiesIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}

                        {["", undefined, "NA"].includes(
                          topicsData?.geographical?.total_population
                        ) ? (
                          <div className="col-lg-6 col-md-12 col-md-12">
                            <div className="bedu my-2  primary-part">
                              <OverviewCard
                                village_key={keyy}
                                village_name={villageData?.data?.village_name}
                                blockName={villageData.data?.block_name}
                                districtName={villageData.data?.district_name}
                                jsonField="total_population"
                                subtitle="geographical"
                                title="Total Population"
                                total_people={"No Data Available"}
                                state_name={villageData?.data?.state_name}
                                answer={"No Data Available"}
                                icon={CommunitiesIcon}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="col-lg-6 col-md-12 col-md-12">
                            <div className="bedu my-2  primary-part">
                              <OverviewCard
                                village_key={keyy}
                                village_name={villageData?.data?.village_name}
                                blockName={villageData.data?.block_name}
                                districtName={villageData.data?.district_name}
                                jsonField="total_population"
                                subtitle="geographical"
                                title="Total Population"
                                total_people={
                                  topicsData?.geographical?.total_population
                                }
                                state_name={villageData?.data?.state_name}
                                answer={
                                  parseInt(
                                    topicsData?.geographical?.male_population
                                  ) +
                                  parseInt(
                                    topicsData?.geographical?.female_population
                                  ) +
                                  parseInt(
                                    topicsData?.geographical?.other_population
                                  )
                                }
                                icon={CommunitiesIcon}
                              />
                            </div>
                          </div>
                        )}
                        {generateNewCardComponent(
                          "Geographical Location Id",
                          "geographical",
                          "geographic_location_id",
                          RailwayIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Other Name Of Village",
                          "geographical",
                          "other_name_of_village",
                          RailwayIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Nearest Bus Terminal",
                          "geographical",
                          "nearest_bus_terminal",
                          BusIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Nearest Railway Station",
                          "geographical",
                          "nearest_railway_station",
                          RailwayIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Status",
                          "geographical",
                          "status",
                          RailwayIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {/* )} */}

                {/* ----------------------------------------------DEVELOPMENTAL---------------------------------------------------- */}

                {/* {selectedTopic === "developmental" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      marginTop: "5%",
                    }}
                    ref={developmentalRef}
                    data-section="developmental"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.developmental &&
                        Object.keys(topicsData?.developmental)?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            width: "50%",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: devFontColor,
                                fontWeight: devFontWeight,
                                fontSize: "24px",
                              }}
                            >
                              Developmental Profile
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData?.developmental?.media ? null : (
                          <Carousel
                            interval={null}
                            className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                            style={{ borderRadius: "20px" }}
                          >
                            {topicsData?.developmental?.media?.map(
                              (item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" && (
                                    <Carousel.Item
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <VillageImageDiv
                                        className="th-border-top th-border-bottom overflow-hidden"
                                        style={{
                                          backgroundColor: "#fff",
                                          backgroundSize: "cover",
                                          filter: "blur(10px)",
                                          border: "1px solid #3CAF5C",
                                          overflow: "hidden",
                                          backgroundImage: `url(${item?.cdn_path})`,
                                        }}
                                      ></VillageImageDiv>
                                      <VillageImageDiv
                                        className="th-border-top th-border-bottom"
                                        style={{
                                          position: "absolute",

                                          top: "0%",
                                          right: "0%",
                                          backgroundImage: `url(${item?.cdn_path})`,
                                        }}
                                      ></VillageImageDiv>
                                      <Carousel.Caption>
                                        <p
                                          className="th-12  mb-4 text-capitalize"
                                          style={{ color: "#1e1d4c" }}
                                        >
                                          {item?.caption ? (
                                            RemoveCapturedBy(item?.caption)
                                          ) : (
                                            <NoDataMessage />
                                          )}
                                        </p>
                                      </Carousel.Caption>
                                      {item?.caption && (
                                        <p className="text-disclaimer-with-edit px-3">
                                          The images on the portal have been
                                          collected and uploaded only prior to
                                          the consent of the concerned person.
                                        </p>
                                      )}
                                    </Carousel.Item>
                                  )
                                );
                              }
                            )}
                          </Carousel>
                        )}
                        <div
                          className="rounded "
                          style={{
                            overflowX: "hidden",
                          }}
                        >
                          <>
                            {/* {selectedTopic === "developmental" ? ( */}
                            <>
                              <div className="pt-3 pb-3 mb-1">
                                <div className="row">
                                  <div className="col-lg-6 col-md-12 col-md-12">
                                    {topicsData?.developmental
                                      ?.primary_source_of_livelihood != "" && (
                                      <>
                                        <h3 className="d-flex align-items-center th-14 th-fw-600 mb-1 text-capitalize px-3">
                                          <div>
                                            Primary Source Of Livelihood
                                          </div>
                                          <div>
                                            <>
                                              {handleAllEditcontent(
                                                "developmental",
                                                "primary_source_of_livelihood",
                                                topicsData?.developmental
                                                  ?.primary_source_of_livelihood
                                              )}
                                            </>
                                          </div>
                                        </h3>
                                        <div className="th-12 py-2 px-3">
                                          <>
                                            {topicsData?.developmental?.primary_source_of_livelihood?.map(
                                              (item, index) => {
                                                return (
                                                  <>
                                                    {item} <br />
                                                  </>
                                                );
                                              }
                                            )}
                                          </>
                                        </div>
                                      </>
                                    )}

                                    {topicsData?.developmental
                                      ?.primary_source_of_drinking_water !=
                                      "" && (
                                      <>
                                        <h3 className="d-flex align-items-center th-14 th-fw-600 mb-1 text-capitalize px-3">
                                          <div>
                                            Primary Source Of Drinking Water
                                          </div>
                                          <div>
                                            {handleAllEditcontent(
                                              "developmental",
                                              "primary_source_of_drinking_water",
                                              topicsData?.developmental
                                                ?.primary_source_of_drinking_water
                                            )}
                                          </div>
                                        </h3>
                                        <div className="th-12 py-2 px-3">
                                          <>
                                            {
                                              topicsData?.developmental
                                                ?.primary_source_of_drinking_water
                                            }
                                          </>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-lg-6 col-md-12 col-md-12">
                                    {FilterNaItems(
                                      topicsData?.developmental?.main_produces
                                    ) && (
                                      <>
                                        <div
                                          className="d-flex flex-row bd-highlight "
                                          onMouseEnter={(e) => {
                                            setAboutProducesEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseLeave={(e) => {
                                            setAboutProducesEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-600 mb-0 text-capitalize px-3">
                                              Main Produces
                                            </h3>
                                          </div>
                                          <div>
                                            {handleAllEditcontent(
                                              "developmental",
                                              "main_produces",
                                              {
                                                caption: FilterNaItems(
                                                  topicsData?.developmental
                                                    ?.main_produces
                                                ),
                                              }
                                            )}
                                          </div>
                                        </div>
                                        <div className="th-12 py-2 px-3">
                                          {FilterNaItems(
                                            topicsData?.developmental
                                              ?.main_produces
                                          )}
                                        </div>
                                      </>
                                    )}

                                    {FilterNaItems(
                                      topicsData?.developmental
                                        ?.village_paved_street
                                    ) && (
                                      <>
                                        <div
                                          className="d-flex flex-row bd-highlight "
                                          onMouseEnter={(e) => {
                                            setAboutProducesEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseLeave={(e) => {
                                            setAboutProducesEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-600 mb-0 text-capitalize px-3">
                                              Village Paved Street
                                            </h3>
                                          </div>
                                          <div>
                                            {handleAllEditcontent(
                                              "developmental",
                                              "village_paved_street",
                                              {
                                                caption: FilterNaItems(
                                                  topicsData?.developmental
                                                    ?.village_paved_street
                                                ),
                                              }
                                            )}
                                          </div>
                                        </div>
                                        <div className="th-12 py-2 px-3">
                                          {FilterNaItems(
                                            topicsData.developmental
                                              .village_paved_street
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className=" row">
                                <div className="col-lg-6 col-md-12 col-md-12">
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Toilets",
                                    "toilet",
                                    "developmental",
                                    ToiletsIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Primary Health Centre",
                                    "primary_health_centre",
                                    "developmental",
                                    HealthIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Cooking Gas",
                                    "gas",
                                    "developmental",
                                    CookingIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Drinking Water",
                                    "tab_water",
                                    "developmental",
                                    WaterIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Post Office",
                                    "post_office",
                                    "developmental",
                                    PostoffcIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Nearest Railway Station",
                                    "nearest_railway_station",
                                    "developmental",
                                    RailwayIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Drainage System",
                                    "drainage_system",
                                    "developmental",
                                    WaterIcon
                                  )}
                                </div>
                                <div className="col-lg-6 col-md-12 col-md-12">
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "College",
                                    "college",
                                    "developmental",
                                    CollegeIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Oldest School",
                                    "school",
                                    "developmental",
                                    SchoolIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Bank",
                                    "bank",
                                    "developmental",
                                    BankIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Telephone",
                                    "telephone",
                                    "developmental",
                                    TelephoneIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Internet",
                                    "internet",
                                    "developmental",
                                    InternetIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Electricity",
                                    "electricty",
                                    "developmental",
                                    ElectricityIcon
                                  )}
                                </div>
                              </div>
                            </>
                            {/* ) : null} */}
                          </>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------Image Gallery------------------------------------------ */}

                <div
                  ref={imageRef}
                  className="pr-1 shadow-sm bg-white rounded"
                  data-section="image_gallery"
                  style={{
                    position: "relative",
                    marginTop: "5%",
                  }}
                >
                  <div
                    style={{
                      position: "sticky",
                      top: "126px",
                      zIndex: "110",
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#fff",
                        width: "50%",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                        padding: "5px 5px 5px 15px",
                      }}
                    >
                      <span
                        style={{
                          color: imageFontColor,
                          fontWeight: imageFontWeight,
                          fontSize: "24px",
                        }}
                      >
                        Image Gallery
                      </span>
                    </div>
                  </div>
                  <div
                    // className="col-md-9 my-3"
                    style={{
                      borderRadius: "15px",
                      position: "relative",
                    }}
                  >
                    <>
                      <div
                        style={{
                          position: "sticky",
                          top: "170px",
                          backgroundColor: "#fff",
                          zIndex: "111",
                        }}
                      >
                        {Array?.from(new Set(gallery_photos_temp))?.map(
                          (topic, index) => {
                            return (
                              <>
                                <span>
                                  <button
                                    type="button"
                                    className={`date_btn m-2 text-capitalize  ${
                                      selectedGalleryTopic === topic
                                        ? "th-fw-600 col selectiveColor"
                                        : " not_selected_date "
                                    }`}
                                    key={index}
                                    onClick={() => {
                                      setSelectedGalleryTopic(topic);
                                      setIsOpen(false);
                                      setPhotoIndex(0);
                                    }}
                                  >
                                    {topic?.toLowerCase().split("_").join(" ")}
                                  </button>
                                </span>
                              </>
                            );
                          }
                        )}
                      </div>

                      <div className="container-fluid pt-3">
                        <div
                          className="row scollimagegallery"
                          style={{ overflowY: "auto", height: "60vh" }}
                        >
                          {selectedGalleryTopic === "all_photos" ? (
                            <>
                              {resetImageGellary?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-lg-3 col-md-4 col-sm-6 col-6"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          setIsOpen(true);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.thumbnail
                                              ? item?.thumbnail
                                              : Default
                                          }
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageGellary[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImageGellary[
                                        (photoIndex + 1) %
                                          resetImageGellary.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageGellary[
                                        (photoIndex +
                                          resetImageGellary.length -
                                          1) %
                                          resetImageGellary.length
                                      ]?.image_path
                                    }
                                    imageCaption={RemoveCapturedBy(
                                      resetImageGellary[photoIndex]?.caption
                                    )}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageGellary.length -
                                          1) %
                                          resetImageGellary.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageGellary.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "heritage_places" ? (
                            <>
                              {resetImageHeritage?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageHeritage[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImageHeritage[
                                        (photoIndex + 1) %
                                          resetImageHeritage.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageHeritage[
                                        (photoIndex +
                                          resetImageHeritage.length -
                                          1) %
                                          resetImageHeritage.length
                                      ]?.image_path
                                    }
                                    imageCaption={
                                      resetImageHeritage[photoIndex]?.caption
                                    }
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageHeritage.length -
                                          1) %
                                          resetImageHeritage.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageHeritage.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "festivals" ? (
                            <>
                              {resetImageFestivals?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageFestivals[photoIndex]
                                        ?.image_path
                                    }
                                    nextSrc={
                                      resetImageFestivals[
                                        (photoIndex + 1) %
                                          resetImageFestivals.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageFestivals[
                                        (photoIndex +
                                          resetImageFestivals.length -
                                          1) %
                                          resetImageFestivals.length
                                      ]?.image_path
                                    }
                                    imageCaption={
                                      resetImageFestivals[photoIndex]?.caption
                                    }
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageFestivals.length -
                                          1) %
                                          resetImageFestivals.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageFestivals.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "traditional_dress" ? (
                            <>
                              {resetImageDress?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageDress[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImageDress[
                                        (photoIndex + 1) %
                                          resetImageDress.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageDress[
                                        (photoIndex +
                                          resetImageDress.length -
                                          1) %
                                          resetImageDress.length
                                      ]?.image_path
                                    }
                                    imageCaption={
                                      resetImageDress[photoIndex]?.caption
                                    }
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageDress.length -
                                          1) %
                                          resetImageDress.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageDress.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "ornaments" ? (
                            <>
                              {resetImageOrnamants?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageOrnamants[photoIndex]
                                        ?.image_path
                                    }
                                    nextSrc={
                                      resetImageOrnamants[
                                        (photoIndex + 1) %
                                          resetImageOrnamants.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageOrnamants[
                                        (photoIndex +
                                          resetImageOrnamants.length -
                                          1) %
                                          resetImageOrnamants.length
                                      ]?.image_path
                                    }
                                    imageCaption={RemoveCapturedBy(
                                      resetImageOrnamants[photoIndex]?.caption
                                    )}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageOrnamants.length -
                                          1) %
                                          resetImageOrnamants.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageOrnamants.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "traditional_food" ? (
                            <>
                              {resetImagesFood?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImagesFood[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImagesFood[
                                        (photoIndex + 1) %
                                          resetImagesFood.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImagesFood[
                                        (photoIndex +
                                          resetImagesFood.length -
                                          1) %
                                          resetImagesFood.length
                                      ]?.image_path
                                    }
                                    imageCaption={RemoveCapturedBy(
                                      resetImagesFood[photoIndex]?.caption
                                    )}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImagesFood.length -
                                          1) %
                                          resetImagesFood.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImagesFood.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "famous_personalities" ? (
                            <>
                              {resetImageFamous?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageFamous[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImageFamous[
                                        (photoIndex + 1) %
                                          resetImageFamous.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageFamous[
                                        (photoIndex +
                                          resetImageFamous.length -
                                          1) %
                                          resetImageFamous.length
                                      ]?.image_path
                                    }
                                    imageCaption={RemoveCapturedBy(
                                      resetImageFamous[photoIndex]?.caption
                                    )}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageFamous.length -
                                          1) %
                                          resetImageFamous.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageFamous.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "traditional_art" ? (
                            <>
                              {resetImageArt?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageArt[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImageArt[
                                        (photoIndex + 1) % resetImageArt.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageArt[
                                        (photoIndex +
                                          resetImageArt.length -
                                          1) %
                                          resetImageArt.length
                                      ]?.image_path
                                    }
                                    imageCaption={RemoveCapturedBy(
                                      resetImageArt[photoIndex]?.caption
                                    )}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageArt.length -
                                          1) %
                                          resetImageArt.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) % resetImageArt.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </>
                  </div>
                </div>

                {/* ----------------------------------Nearbuy Village------------------------------------------ */}

                <div
                  className="pr-1 shadow-sm bg-white my-5"
                  ref={nearVillageRef}
                  style={{
                    position: "relative",
                  }}
                  data-section="near_village"
                >
                  <div
                    style={{
                      position: "sticky",
                      top: "126px",
                      zIndex: "110",
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: nearFontColor,
                        width: "50%",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                        padding: "5px 5px 5px 15px",
                      }}
                    >
                      <span
                        style={{
                          color: "#FFF",
                          fontWeight: nearFontWeight,
                          fontSize: "24px",
                        }}
                      >
                        Explore more villages nearby
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      gap: "1%",
                      padding: "2%",
                    }}
                  >
                    {nearBuyVillage.map((hit, index) => (
                      <div
                        key={index}
                        style={{ width: "30%", marginBottom: "2%" }}
                      >
                        <Link
                          to={{
                            pathname: `/village-details/${hit.village_document_id}`,
                            state: {
                              villageId: hit.village_document_id,
                            },
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <VillageCard
                            village={hit}
                            village_name={hit?.village_name}
                            state={hit?.state_name}
                            cover={hit?.thumbnail}
                            thumbnaiId={hit?.thumbnaiId}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>

                <Message />
              </ReactPlaceholder>
            </div>
          </>
          {/* )} */}
        </MyLayout>
      </MyDesktop>

      {/* ================================================================================================================================================================================================ */}

      <MyMobile>
        <MyLayout>
          <SuggestEdit
            show={modalShow}
            handleClose={handleModalClose}
            handleModalShow={handleModalShow}
            keyy={keyy}
            jsonField={selectedTopic}
            update={isUpdate}
            imageItemIndex={imageItemIndex}
            item={imageItem}
            selectedTopic={selectedTopic}
            topicsData={topicsData}
          />
          <div
            id="offCanv"
            className="col-2 mt-3 th-bg-primary "
            style={{
              borderRadius: " 0px 10px 10px 0px",
              zIndex: "1000",
              top: "51px",
              position: "fixed",
              left: "-20px",
            }}
          >
            <div className="p-1 align-items-center d-flex justify-content-center">
              <div className="th-pointer " onClick={handleShow}>
                <img
                  height="25px"
                  style={{ marginLeft: "17px" }}
                  src={MenuIcon}
                  alt="..."
                />
              </div>
            </div>
          </div>

          <Offcanvas
            show={show}
            onHide={handleClose}
            style={{ overflowY: "scroll", width: "50%" }}
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              {newList &&
                order?.map((item, index) => {
                  return (
                    <div
                      className={`th-pointer action-btn my-2 text-capitalize col ${
                        selectedTopic === item
                          ? "th-fw-600 col selectiveColor"
                          : null
                      }`}
                      style={{ overflowWrap: "break-word" }}
                      key={index}
                      onClick={() => {
                        setShow(false);
                        setOpenModel(false);
                        setSelectedTopic(item);
                        getClickedSection(item);
                      }}
                    >
                      {item === "geographical"
                        ? "Geographic and Demographic profile"
                        : item === "developmental"
                        ? "Developmental Profile"
                        : item === "festivals"
                        ? "Fairs and Festivals"
                        : item === "traditional_art"
                        ? "Traditional Art and Craft"
                        : item === "ornaments"
                        ? "Traditional Ornaments"
                        : item === "belief"
                        ? "Beliefs and Customs"
                        : item === "near_village"
                        ? "Explore more villages nearby"
                        : item.toLowerCase().split("_").join(" ")}
                    </div>
                  );
                })}
            </Offcanvas.Body>
          </Offcanvas>

          {/* Village Details */}

          <>
            <div
              style={{
                backgroundColor: "rgb(247 247 247)",
                height: "33px",
                width: "100%",
                position: "absolute",
                top: "67px",
                zIndex: "222",
              }}
            ></div>
            <div className="col-md-9">
              <div
                style={{
                  position: "fixed",
                  top: "100px",
                  width: "94%",
                  zIndex: "222",
                }}
              >
                <div className="th-bg-white th-border-bottom py-3 px-3 mb-2">
                  <div className="d-flex row align-items-center mobile_edit_functionality">
                    <div className="village_name">
                      <div
                        onMouseLeave={(e) => {
                          setVillageStyle({ display: "block" });
                        }}
                      >
                        <div>
                          <div className="d-flex flex-row">
                            <div
                              onMouseEnter={(e) => {
                                setVillageStyle({ display: "block" });
                              }}
                            >
                              <h3 className="th-20 th-fw-600 mb-0 d-flex text-capitalize aboutVillage">
                                {topicsData?.village_name
                                  ? topicsData?.village_name?.toLowerCase()
                                  : "Loading..."}

                                {handleAllEditcontent(
                                  "culture_profile",
                                  "village_name_edit",
                                  {
                                    caption:
                                      topicsData?.village_name?.toLowerCase(),
                                  },
                                  null
                                )}
                              </h3>
                            </div>
                          </div>
                          <div className="th-14 th-grey-2 text-capitalize">
                            {topicsData?.block_name
                              ? topicsData?.block_name?.toLowerCase() + ", "
                              : null}
                            {topicsData?.district_name
                              ? topicsData?.district_name?.toLowerCase()
                              : null}
                            {topicsData?.state_name
                              ? ", " + topicsData?.state_name?.toLowerCase()
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="village_lan">
                      <div className="d-flex sub_lan justify-content-between align-items-center">
                        <div
                          className="th-11 pr-1"
                          style={{
                            marginRight: "20px",
                            border: "1px solid #3CAF5C",
                            padding: "5px",
                            minWidth: "100px",
                            borderRadius: "4px",
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-evenly">
                            <div
                              onClick={handleLike}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                height="24px"
                                src={!isLiked ? HeartGreen : HeartColored}
                                alt="..."
                              />
                            </div>
                            <div className="th-16" style={{ color: "#3CAF5C" }}>
                              {likeCount}
                            </div>
                          </div>
                        </div>

                        <div
                          className="th-11 pr-1"
                          style={{ marginRight: "20px" }}
                        ></div>

                        <div
                          className="th-11 pr-1"
                          style={{ marginRight: "20px" }}
                        >
                          <div
                            className="btn"
                            style={{
                              backgroundColor: "#3CAF5C",
                              color: "#FFF",
                            }}
                            onClick={() => {
                              if (isEdit) {
                                setIsEdit(false);
                              } else {
                                setOpenContributeModal(true);
                              }
                            }}
                          >
                            {isEdit ? "Cancel" : "Contribute"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ReactPlaceholder
                ready={
                  topicsData?.village_name &&
                  topicsData?.village_name?.toLowerCase()
                }
                customPlaceholder={awesomePlaceholderDetailMobile}
              >
                {/* -----------------------------------------CULTURAL PROFILE----------------------------------------------- */}
                {/* {selectedTopic === "culture_profile" && */}
                {/* villageThumbnailId && ( */}
                <div
                  className="pr-1"
                  style={{
                    paddingRight: "1.5%",
                    marginTop: "30%",
                  }}
                >
                  <div
                    className="th-bg-white th-border-bottom th-border-top  pt-3 px-3 shadow-sm"
                    style={{
                      borderRadius: "20px",
                      marginTop: "2%",
                      padding: "5% 5% 3% 5%",
                    }}
                  >
                    <div>
                      <div
                        className="th-bg-white th-border-bottom mb-3 th-border-top"
                        style={{
                          borderRadius: "10px",
                          position: "relative",
                          overflow: "hidden",
                          marginTop: "5%",
                        }}
                      >
                        {currentVideo ? (
                          <YoutubeEmbed
                            embedId={currentVideo}
                            thumbnail={topicsData?.video_thumbnail}
                          />
                        ) : (
                          <div
                            style={{
                              borderRadius: "30px",
                              position: "relative",
                            }}
                            className="demo"
                            onMouseEnter={() => setOnHoverEff(true)}
                            onMouseLeave={() => setOnHoverEff(true)}
                          >
                            <VillageImage
                              getData={getData}
                              image_id={
                                villageThumbnailId
                                  ? villageThumbnailId
                                  : topicsData?.culture_profile?.media?.[0]
                                      ?.media_name
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {summary && (
                    <div className="th-br-15 my-5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px 5px 5px 15px",
                          width: "100%",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              color: cultureFontColor,
                              fontSize: "24px",
                              fontWeight: cultureFontWeight,
                            }}
                          >
                            Summary
                          </span>
                          <div style={{ padding: "20px" }}>
                            {convertLinks(summary)}
                          </div>
                        </div>
                      </div>
                      {/* // ) : null} */}
                    </div>
                  )}

                  {/* -----------------------------------------------About Village Description---------------------------------------------------- */}
                  <div
                    className="th-br-15 my-5"
                    ref={cultureProfileRef}
                    data-section="culture_profile"
                  >
                    {/* {selectedTopic === "culture_profile" ? ( */}
                    <Accordion
                      defaultExpanded={
                        topicsData?.culture_profile &&
                        Object.keys(topicsData?.culture_profile)?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "117px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            width: "100%",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: cultureFontColor,
                                fontSize: "18px",
                              }}
                            >
                              Culture Profile
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>

                      <AccordionDetails>
                        <div className="pt-4 border-radius-content-village-deets">
                          <>
                            <div
                              className="d-flex flex-row bd-highlight mb-1"
                              onMouseLeave={(e) => {
                                setAboutEdit({ display: "block" });
                              }}
                              onMouseEnter={(e) => {
                                setAboutEdit({ display: "block" });
                              }}
                            >
                              <div>
                                <h3
                                  className="th-16 th-fw-600 mb-0 text-capitalize px-3"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  What is it famous for?
                                  <span>
                                    <div className="th-14 py-2 px-3">
                                      <div className="d-flex">
                                        {!topicsData?.culture_profile
                                          ?.your_village_famous_for && (
                                          <NoDataMessage />
                                        )}
                                        <span>
                                          <div className="inputText">
                                            {handleAllEditcontent(
                                              "culture_profile",
                                              "your_village_famous_for",
                                              {
                                                caption: "",
                                              }
                                            )}
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </span>
                                </h3>
                              </div>
                            </div>

                            {topicsData?.culture_profile
                              ?.your_village_famous_for &&
                              !textVillage.includes(
                                topicsData?.culture_profile
                                  ?.your_village_famous_for
                              ) && (
                                <>
                                  <div className="th-14 py-2 px-3">
                                    <div className="d-flex">
                                      <div className="inputText">
                                        {convertLinks(
                                          FilterNaItems(
                                            topicsData?.culture_profile
                                              ?.your_village_famous_for
                                          )
                                        )}
                                      </div>
                                      <span>
                                        <div className="">
                                          {handleAllEditcontent(
                                            "culture_profile",
                                            "your_village_famous_for",
                                            {
                                              caption: FilterNaItems(
                                                topicsData?.culture_profile
                                                  ?.your_village_famous_for
                                              ),
                                            }
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                          </>
                        </div>

                        <div className="border-radius-content-village-deets  my-3 ">
                          <div
                            className="d-flex flex-row bd-highlight mb-1 mt-2"
                            onMouseLeave={(e) => {
                              setAboutEdit({ display: "block" });
                            }}
                            onMouseEnter={(e) => {
                              setAboutEdit({ display: "block" });
                            }}
                          >
                            <div>
                              <h3
                                className="th-16 th-fw-600 mb-0 text-capitalize px-3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                How is it unique?
                                <span>
                                  <div className="th-14 py-2 px-3">
                                    <div className="d-flex">
                                      {!topicsData?.culture_profile
                                        ?.village_unique_from_other_village && (
                                        <NoDataMessage />
                                      )}
                                      <span>
                                        {handleAllEditcontent(
                                          "culture_profile",
                                          "village_unique_from_other_village",
                                          {
                                            caption: "",
                                          }
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </span>
                              </h3>
                            </div>
                          </div>

                          {topicsData?.culture_profile
                            ?.village_unique_from_other_village &&
                            !textVillage.includes(
                              topicsData?.culture_profile
                                ?.village_unique_from_other_village
                            ) && (
                              <>
                                <div className="th-14 py-2 px-3">
                                  <div className="d-flex">
                                    {" "}
                                    <div className="inputText">
                                      {convertLinks(
                                        FilterNaItems(
                                          topicsData?.culture_profile
                                            ?.village_unique_from_other_village
                                        )
                                      )}{" "}
                                    </div>
                                    <span>
                                      {handleAllEditcontent(
                                        "culture_profile",
                                        "village_unique_from_other_village",
                                        {
                                          caption: FilterNaItems(
                                            topicsData?.culture_profile
                                              ?.village_unique_from_other_village
                                          ),
                                        }
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                        </div>

                        <div className="border-radius-content-village-deets  my-3 ">
                          <div
                            className="d-flex flex-row bd-highlight mb-1 mt-2"
                            onMouseLeave={(e) => {
                              setAboutEdit({ display: "block" });
                            }}
                            onMouseEnter={(e) => {
                              setAboutEdit({ display: "block" });
                            }}
                          >
                            <div>
                              <h3
                                className="th-16 th-fw-600 mb-0 text-capitalize px-3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Any Historic Incident?
                                <span>
                                  <div className="th-14 py-2 px-3">
                                    <div className="d-flex">
                                      {!topicsData?.culture_profile
                                        ?.historic_incident && (
                                        <NoDataMessage />
                                      )}
                                      <span>
                                        {handleAllEditcontent(
                                          "culture_profile",
                                          "historic_incident",
                                          {
                                            caption: "",
                                          }
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </span>
                              </h3>
                            </div>
                          </div>

                          {topicsData?.culture_profile?.historic_incident &&
                            !textVillage.includes(
                              topicsData?.culture_profile?.historic_incident
                            ) && (
                              <>
                                <div className="th-14 py-2 px-3">
                                  <div className="d-flex">
                                    <div className="inputText">
                                      {convertLinks(
                                        FilterNaItems(
                                          topicsData?.culture_profile
                                            ?.historic_incident
                                        )
                                      )}
                                    </div>
                                    <span>
                                      {handleAllEditcontent(
                                        "culture_profile",
                                        "historic_incident",
                                        {
                                          caption: FilterNaItems(
                                            topicsData?.culture_profile
                                              ?.historic_incident
                                          ),
                                        }
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                        {/* <--------------------------------culture profile--------------CAROUSEL------------------------------------------------> */}
                        {topicsData?.culture_profile?.media &&
                        topicsData?.culture_profile?.media?.length > 0 &&
                        topicsData?.culture_profile?.media?.filter(
                          (e) => e.media_name !== undefined
                        ).length > 0 ? (
                          <div style={{ paddingBottom: "10px" }}>
                            <Carousel
                              interval={null}
                              activeIndex={cultureIndex}
                              onSelect={handleCultureIndex}
                              indicators={false}
                              controls={
                                topicsData?.culture_profile?.media?.length > 1
                              }
                              className={`th-bg-white th-border-bottom px-3 th-border-top ${
                                topicsData?.culture_profile?.media.length == 1
                                  ? "carsouel-main"
                                  : ""
                              }`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              {topicsData?.culture_profile?.media?.map(
                                (item, index) => {
                                  return (
                                    item != null &&
                                    item?.media_type != "video" &&
                                    parseInt(item?.status) !== -1 && (
                                      <Carousel.Item
                                        style={{
                                          position: "relative",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        <SliderImage
                                          getData={getData}
                                          image_id={item?.media_name}
                                        />
                                        <Carousel.Caption
                                          className="display-static pb-0"
                                          onMouseEnter={() =>
                                            setOnHoverEff(true)
                                          }
                                          onMouseLeave={() =>
                                            setOnHoverEff(true)
                                          }
                                        >
                                          <div className="d-block">
                                            <div className="d-flex ">
                                              <p
                                                className="th-12 mb-4"
                                                style={{
                                                  color: "#1e1d4c",
                                                }}
                                              >
                                                {item?.caption ? (
                                                  RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                ) : (
                                                  <NoDataMessage />
                                                )}
                                              </p>

                                              <div>
                                                {handleAllEditcontent(
                                                  "culture_profile",
                                                  "caption_edit",
                                                  item,
                                                  null,
                                                  index
                                                )}
                                              </div>
                                            </div>
                                          </div>

                                          {onHoverEff ? (
                                            <div>
                                              <img
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  handleModalShow(item, index)
                                                }
                                                src={PlusGreen}
                                                alt={item?.title}
                                              />
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </Carousel.Caption>
                                        {item?.caption && (
                                          <p className="text-disclaimer-with-edit px-3 pt-2">
                                            The images on the portal have been
                                            collected and uploaded only prior to
                                            the consent of the concerned person.
                                          </p>
                                        )}
                                      </Carousel.Item>
                                    )
                                  );
                                }
                              )}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {cultureIndex + 1} /
                              {topicsData?.culture_profile?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-16 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "600",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    {/* // ) : null} */}
                  </div>
                </div>
                {/* // )} */}

                {/* ----------------------------------------------heritage_places---------------------------------------------------- */}

                {/* {selectedTopic === "heritage_places" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={heritageRef}
                    data-section="heritage_places"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.heritage_places &&
                        Object.keys(topicsData?.heritage_places[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "117px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: heritageFontColor,
                                fontWeight: heritageFontWeight,
                                fontSize: "18px",
                              }}
                            >
                              Heritage Places
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.heritage_places && (
                          <>
                            {renderEditOption(
                              "heritage_places",
                              "description",
                              {
                                index: 0,
                              }
                            )}
                          </>
                        )}
                        {topicsData?.heritage_places &&
                        topicsData?.heritage_places?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={heritageIndex}
                              onSelect={handleHeritageIndex}
                              indicators={false}
                              controls={
                                topicsData?.heritage_places[0]?.media?.length >
                                1
                              }
                            >
                              {topicsData?.heritage_places
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4"
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "heritage_places",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {heritageIndex + 1} /
                              {topicsData?.heritage_places[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-16 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "600",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.heritage_places?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i?.title) ? null : (
                                <div
                                  className={
                                    "pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "description",
                                                  { caption: i?.description },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "heritage_places",
                                              "description",
                                              {
                                                caption: FilterNaItems(
                                                  i.description
                                                ),
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      {/* second field */}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            any story associated?{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.story_associate_with_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "story_associate_with_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.story_associate_with_heritage &&
                                        !textVillage.includes(
                                          i.story_associate_with_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.story_associate_with_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "story_associate_with_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.story_associate_with_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Type of Heritage{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.type_of_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "type_of_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.type_of_heritage &&
                                        !textVillage.includes(
                                          i.type_of_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "type_of_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.type_of_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Rituals/ Worship related to the
                                            Heritage{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.ritual_worship_related_to_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "ritual_worship_related_to_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.ritual_worship_related_to_heritage &&
                                        !textVillage.includes(
                                          i.ritual_worship_related_to_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.ritual_worship_related_to_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "ritual_worship_related_to_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.ritual_worship_related_to_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Deities related to the Heritage
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  <NoDataMessage />
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "deities_related_to_heritage_dsr",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.deities_related_to_heritage_dsr &&
                                        !textVillage.includes(
                                          i.deities_related_to_heritage_dsr
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.deities_related_to_heritage_dsr
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "deities_related_to_heritage_dsr",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.deities_related_to_heritage_dsr
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                        {(!topicsData?.heritage_places ||
                          topicsData?.heritage_places?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords.includes(i?.title) ? null : ( */}
                                <div
                                  className={
                                    "pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "description",
                                                  { caption: i?.description },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "heritage_places",
                                              "description",
                                              {
                                                caption: FilterNaItems(
                                                  i.description
                                                ),
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      {/* second field */}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            any story associated?{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.story_associate_with_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "story_associate_with_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.story_associate_with_heritage &&
                                        !textVillage.includes(
                                          i.story_associate_with_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.story_associate_with_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "story_associate_with_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.story_associate_with_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Type of Heritage{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.type_of_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "type_of_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.type_of_heritage &&
                                        !textVillage.includes(
                                          i.type_of_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "type_of_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.type_of_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Rituals/ Worship related to the
                                            Heritage{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.ritual_worship_related_to_heritage && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "ritual_worship_related_to_heritage",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.ritual_worship_related_to_heritage &&
                                        !textVillage.includes(
                                          i.ritual_worship_related_to_heritage
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.ritual_worship_related_to_heritage
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "ritual_worship_related_to_heritage",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.ritual_worship_related_to_heritage
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Deities related to the Heritage
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  <NoDataMessage />
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "heritage_places",
                                                      "deities_related_to_heritage_dsr",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.deities_related_to_heritage_dsr &&
                                        !textVillage.includes(
                                          i.deities_related_to_heritage_dsr
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.deities_related_to_heritage_dsr
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "heritage_places",
                                                  "deities_related_to_heritage_dsr",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.deities_related_to_heritage_dsr
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------FESTIVAL---------------------------------------------------- */}

                {/* {selectedTopic === "festivals" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={festivalsRef}
                    data-section="festivals"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.festivals &&
                        topicsData?.festivals?.length > 0 &&
                        Object.keys(topicsData?.festivals[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "117px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: festivalFontColor,
                                fontWeight: festivalFontWeight,
                                fontSize: "18px",
                              }}
                            >
                              Fairs & Festivals
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.festivals && (
                          <>
                            {renderEditOption("festivals", "description", {
                              index: 0,
                            })}
                          </>
                        )}
                        {topicsData?.festivals &&
                        topicsData?.festivals?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={festivalIndex}
                              onSelect={handleFestivalIndex}
                              indicators={false}
                              controls={
                                topicsData?.festivals[0]?.media?.length > 1
                              }
                            >
                              {topicsData?.festivals
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />

                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4"
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "festivals",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {festivalIndex + 1} /
                              {topicsData?.festivals[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-18 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "700",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.festivals?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i?.title) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "description",
                                                  {
                                                    caption: "",
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex ">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>

                                          <span>
                                            {handleAllEditcontent(
                                              "festivals",
                                              "description",
                                              {
                                                caption: FilterNaItems(
                                                  i.description
                                                ),
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  {/* second field */}

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Who Organizes Festival?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.who_organise_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "who_organise_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.who_organise_festival &&
                                        !textVillage.includes(
                                          i.who_organise_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.who_organise_festival
                                                )}
                                              </div>

                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "who_organise_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.who_organise_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      {/* third field */}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Who are the attendees?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.who_take_part_in_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "who_take_part_in_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.who_take_part_in_festival &&
                                        !textVillage.includes(
                                          i.who_take_part_in_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.who_take_part_in_festival
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "who_take_part_in_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.who_take_part_in_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      {/* fourth feild */}
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Type of Festival?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.type_of_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "type_of_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.type_of_festival &&
                                        !textVillage.includes(
                                          i.type_of_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_festival
                                                )}
                                              </div>

                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "type_of_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.type_of_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      {/* {dd} */}
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Any specific cuisine prepared?{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.any_specific_cusine_prepared && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "any_specific_cusine_prepared",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.any_specific_cusine_prepared &&
                                        !textVillage.includes(
                                          i.any_specific_cusine_prepared
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.any_specific_cusine_prepared
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "any_specific_cusine_prepared",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.any_specific_cusine_prepared
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                        {!topicsData?.festivals &&
                          topicsData?.festivals?.length === 0 &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords.includes(
                                  i?.title
                                ) ? null : ( */}
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "description",
                                                  {
                                                    caption: "",
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex ">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>

                                          <span>
                                            {handleAllEditcontent(
                                              "festivals",
                                              "description",
                                              {
                                                caption: FilterNaItems(
                                                  i.description
                                                ),
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  {/* second field */}

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Who Organizes Festival?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.who_organise_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "who_organise_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.who_organise_festival &&
                                        !textVillage.includes(
                                          i.who_organise_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.who_organise_festival
                                                )}
                                              </div>

                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "who_organise_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.who_organise_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      {/* third field */}

                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Who are the attendees?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.who_take_part_in_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "who_take_part_in_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.who_take_part_in_festival &&
                                        !textVillage.includes(
                                          i.who_take_part_in_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.who_take_part_in_festival
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "who_take_part_in_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.who_take_part_in_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-md-12">
                                      {/* fourth feild */}
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Type of Festival?
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.type_of_festival && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "type_of_festival",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.type_of_festival &&
                                        !textVillage.includes(
                                          i.type_of_festival
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_festival
                                                )}
                                              </div>

                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "type_of_festival",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.type_of_festival
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      {/* {dd} */}
                                      <div
                                        className="d-flex flex-row bd-highlight mt-2"
                                        onMouseLeave={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                        onMouseEnter={(e) => {
                                          setAboutEdit({ display: "block" });
                                        }}
                                      >
                                        <div>
                                          <h3
                                            className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            Any specific cuisine prepared?{" "}
                                            <span>
                                              <div className="th-14 pb-1 px-3">
                                                <div className="d-flex">
                                                  {!i.any_specific_cusine_prepared && (
                                                    <NoDataMessage />
                                                  )}
                                                  <span>
                                                    {handleAllEditcontent(
                                                      "festivals",
                                                      "any_specific_cusine_prepared",
                                                      {
                                                        caption: "",
                                                      },

                                                      index
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </h3>
                                        </div>
                                      </div>

                                      {i.any_specific_cusine_prepared &&
                                        !textVillage.includes(
                                          i.any_specific_cusine_prepared
                                        ) && (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.any_specific_cusine_prepared
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "festivals",
                                                  "any_specific_cusine_prepared",
                                                  {
                                                    caption: FilterNaItems(
                                                      i.any_specific_cusine_prepared
                                                    ),
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------ART & CRAFT---------------------------------------------------- */}
                {/* {selectedTopic === "traditional_art" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={artRef}
                    data-section="traditional_art"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.traditional_art &&
                        Object.keys(topicsData?.traditional_art[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "117px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: artFontColor,
                                fontWeight: artFontWeight,
                                fontSize: "18px",
                              }}
                            >
                              Traditional Art & Craft
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.traditional_art && (
                          <>
                            {renderEditOption(
                              "traditional_art",
                              "description",
                              {
                                index: 0,
                              }
                            )}
                          </>
                        )}

                        {topicsData?.traditional_art &&
                        topicsData?.traditional_art?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={artIndex}
                              onSelect={handleArtIndex}
                              indicators={false}
                              controls={
                                topicsData?.traditional_art[0]?.media?.length >
                                1
                              }
                            >
                              {topicsData?.traditional_art
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4 "
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "traditional_art",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <img
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleModalShow(item, index)
                                              }
                                              src={PlusGreen}
                                              alt={item?.title}
                                            />
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {artIndex + 1} /
                              {topicsData?.traditional_art[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-18 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "700",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.traditional_art?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(
                                i?.name_of_art
                              ) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.name_of_art}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                        <div>Description</div>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                  !textVillage.includes(i.description) ? (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {convertLinks(
                                            FilterNaItems(i.description)
                                          )}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_art",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        {!i.description && (
                                          <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                            <IconButton>
                                              <CiCircleInfo fill="#40B05E" />
                                            </IconButton>
                                          </Tooltip>
                                        )}

                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_art",
                                            "description",
                                            { caption: "" },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  {/* -------------------------------------------------------------------------------------------------------------------- */}
                                  {/* second field */}

                                  <div className="container">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>How is it designed?</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.design_and_motifs &&
                                        !textVillage.includes(
                                          i.design_and_motifs
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.design_and_motifs
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "design_and_motifs",
                                                  {
                                                    caption:
                                                      i?.design_and_motifs,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.design_and_motifs && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "design_and_motifs",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Art From Practiced in Village ?{" "}
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.art_from_pratice_in_village &&
                                        !textVillage.includes(
                                          i.art_from_pratice_in_village
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.art_from_pratice_in_village
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "art_from_pratice_in_village",
                                                  {
                                                    caption:
                                                      i?.art_from_pratice_in_village,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.art_from_pratice_in_village && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "art_from_pratice_in_village",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Colors Used</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.color_used &&
                                        !textVillage.includes(i.color_used) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.color_used)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "color_used",
                                                  { caption: i?.color_used },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.color_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "color_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Implement Tools</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.implement_tools &&
                                        !textVillage.includes(
                                          i.implement_tools
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.implement_tools
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "implement_tools",
                                                  {
                                                    caption: i?.implement_tools,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.implement_tools && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "implement_tools",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Stories, songs and popular
                                                sayings for Art and Craft
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.stories_songs &&
                                        !textVillage.includes(
                                          i.stories_songs
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.stories_songs)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "stories_songs",
                                                  { caption: i?.stories_songs },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.stories_songs && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "stories_songs",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Present status</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.present_status_of_art_form &&
                                        !textVillage.includes(
                                          i.present_status_of_art_form
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.present_status_of_art_form
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "present_status_of_art_form",
                                                  {
                                                    caption:
                                                      i?.present_status_of_art_form,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.present_status_of_art_form && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "present_status_of_art_form",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>More Information</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.more_information &&
                                        !textVillage.includes(
                                          i.more_information
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.more_information
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "more_information",
                                                  {
                                                    caption:
                                                      i?.more_information,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.more_information && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "more_information",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Individual & Group Activity
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.individual_or_group_activity &&
                                        !textVillage.includes(
                                          i.individual_or_group_activity
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.individual_or_group_activity
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "individual_or_group_activity",
                                                  {
                                                    caption:
                                                      i?.individual_or_group_activity,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.individual_or_group_activity && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "individual_or_group_activity",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Specific Communities Associated
                                                With
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.specific_communities_associated_with &&
                                        !textVillage.includes(
                                          i.specific_communities_associated_with
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.specific_communities_associated_with
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "specific_communities_associated_with",
                                                  {
                                                    caption:
                                                      i?.specific_communities_associated_with,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.specific_communities_associated_with && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "specific_communities_associated_with",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-md-6">
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Type of Art and Craft</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.type_of_art_craft &&
                                        !textVillage.includes(
                                          i.type_of_art_craft
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_art_craft
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "type_of_art_craft",
                                                  {
                                                    caption:
                                                      i?.type_of_art_craft,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.type_of_art_craft && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "type_of_art_craft",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Story on origin of Art & Craft
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.story_on_origin_of_art &&
                                        !textVillage.includes(
                                          i.story_on_origin_of_art
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.story_on_origin_of_art
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "story_on_origin_of_art",
                                                  {
                                                    caption:
                                                      i?.story_on_origin_of_art,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.story_on_origin_of_art && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "story_on_origin_of_art",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Relationship of the Art and
                                                Craft (if any){" "}
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.relationship_art_craft &&
                                        !textVillage.includes(
                                          i.relationship_art_craft
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.relationship_art_craft
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "relationship_art_craft",
                                                  {
                                                    caption:
                                                      i?.relationship_art_craft,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.relationship_art_craft && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "relationship_art_craft",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Material Used</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.material_used &&
                                        !textVillage.includes(
                                          i.material_used
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.material_used)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "material_used",
                                                  { caption: i?.material_used },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.material_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "material_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Occasion for Display and Sale
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.occasion_display_and_sale &&
                                        !textVillage.includes(
                                          i.occasion_display_and_sale
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.occasion_display_and_sale
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "occasion_display_and_sale",
                                                  {
                                                    caption:
                                                      i?.occasion_display_and_sale,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.occasion_display_and_sale && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "occasion_display_and_sale",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Practicing Communities</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.practicing_communities &&
                                        !textVillage.includes(
                                          i.practicing_communities
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.practicing_communities
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "practicing_communities",
                                                  {
                                                    caption:
                                                      i?.practicing_communities,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.practicing_communities && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "practicing_communities",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Suggestion for Revival and
                                                Economic Viability of Art Form
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.suggestion_for_revival_and_economic &&
                                        !textVillage.includes(
                                          i.suggestion_for_revival_and_economic
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.suggestion_for_revival_and_economic
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "suggestion_for_revival_and_economic",
                                                  {
                                                    caption:
                                                      i?.suggestion_for_revival_and_economic,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.suggestion_for_revival_and_economic && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "suggestion_for_revival_and_economic",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Classification of art form
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.classification_of_art_form &&
                                        !textVillage.includes(
                                          i.classification_of_art_form
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.classification_of_art_form
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "classification_of_art_form",
                                                  {
                                                    caption:
                                                      i?.classification_of_art_form,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.classification_of_art_form && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "classification_of_art_form",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({ display: "block" });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Surface & treatment</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.technique_used &&
                                        !textVillage.includes(
                                          i.technique_used
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.technique_used
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "technique_used",
                                                  {
                                                    caption: i?.technique_used,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.technique_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "technique_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}

                        {(!topicsData?.traditional_art ||
                          topicsData?.traditional_art?.length === 0) &&
                          ["1"].map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords.includes(
                                  i?.name_of_art
                                ) ? null : ( */}
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.name_of_art}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                        <div>Description</div>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                  !textVillage.includes(i.description) ? (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {convertLinks(
                                            FilterNaItems(i.description)
                                          )}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_art",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        {!i.description && (
                                          <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                            <IconButton>
                                              <CiCircleInfo fill="#40B05E" />
                                            </IconButton>
                                          </Tooltip>
                                        )}

                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_art",
                                            "description",
                                            { caption: "" },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  {/* -------------------------------------------------------------------------------------------------------------------- */}
                                  {/* second field */}

                                  <div className="container">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>How is it designed?</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.design_and_motifs &&
                                        !textVillage.includes(
                                          i.design_and_motifs
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.design_and_motifs
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "design_and_motifs",
                                                  {
                                                    caption:
                                                      i?.design_and_motifs,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.design_and_motifs && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "design_and_motifs",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Art From Practiced in Village ?{" "}
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.art_from_pratice_in_village &&
                                        !textVillage.includes(
                                          i.art_from_pratice_in_village
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.art_from_pratice_in_village
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "art_from_pratice_in_village",
                                                  {
                                                    caption:
                                                      i?.art_from_pratice_in_village,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.art_from_pratice_in_village && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "art_from_pratice_in_village",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Colors Used</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.color_used &&
                                        !textVillage.includes(i.color_used) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.color_used)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "color_used",
                                                  { caption: i?.color_used },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.color_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "color_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Implement Tools</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.implement_tools &&
                                        !textVillage.includes(
                                          i.implement_tools
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.implement_tools
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "implement_tools",
                                                  {
                                                    caption: i?.implement_tools,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.implement_tools && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "implement_tools",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Stories, songs and popular
                                                sayings for Art and Craft
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.stories_songs &&
                                        !textVillage.includes(
                                          i.stories_songs
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.stories_songs)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "stories_songs",
                                                  {
                                                    caption: i?.stories_songs,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.stories_songs && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "stories_songs",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Present status</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.present_status_of_art_form &&
                                        !textVillage.includes(
                                          i.present_status_of_art_form
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.present_status_of_art_form
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "present_status_of_art_form",
                                                  {
                                                    caption:
                                                      i?.present_status_of_art_form,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.present_status_of_art_form && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "present_status_of_art_form",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>More Information</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.more_information &&
                                        !textVillage.includes(
                                          i.more_information
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.more_information
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "more_information",
                                                  {
                                                    caption:
                                                      i?.more_information,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.more_information && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "more_information",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Individual & Group Activity
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.individual_or_group_activity &&
                                        !textVillage.includes(
                                          i.individual_or_group_activity
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.individual_or_group_activity
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "individual_or_group_activity",
                                                  {
                                                    caption:
                                                      i?.individual_or_group_activity,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.individual_or_group_activity && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "individual_or_group_activity",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Specific Communities Associated
                                                With
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.specific_communities_associated_with &&
                                        !textVillage.includes(
                                          i.specific_communities_associated_with
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.specific_communities_associated_with
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "specific_communities_associated_with",
                                                  {
                                                    caption:
                                                      i?.specific_communities_associated_with,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.specific_communities_associated_with && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "specific_communities_associated_with",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-md-6">
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Type of Art and Craft</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.type_of_art_craft &&
                                        !textVillage.includes(
                                          i.type_of_art_craft
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.type_of_art_craft
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "type_of_art_craft",
                                                  {
                                                    caption:
                                                      i?.type_of_art_craft,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.type_of_art_craft && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "type_of_art_craft",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Story on origin of Art & Craft
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.story_on_origin_of_art &&
                                        !textVillage.includes(
                                          i.story_on_origin_of_art
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.story_on_origin_of_art
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "story_on_origin_of_art",
                                                  {
                                                    caption:
                                                      i?.story_on_origin_of_art,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.story_on_origin_of_art && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "story_on_origin_of_art",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Relationship of the Art and
                                                Craft (if any){" "}
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.relationship_art_craft &&
                                        !textVillage.includes(
                                          i.relationship_art_craft
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.relationship_art_craft
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "relationship_art_craft",
                                                  {
                                                    caption:
                                                      i?.relationship_art_craft,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.relationship_art_craft && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "relationship_art_craft",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Material Used</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.material_used &&
                                        !textVillage.includes(
                                          i.material_used
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(i.material_used)}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "material_used",
                                                  {
                                                    caption: i?.material_used,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.material_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "material_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Occasion for Display and Sale
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.occasion_display_and_sale &&
                                        !textVillage.includes(
                                          i.occasion_display_and_sale
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.occasion_display_and_sale
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "occasion_display_and_sale",
                                                  {
                                                    caption:
                                                      i?.occasion_display_and_sale,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.occasion_display_and_sale && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "occasion_display_and_sale",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Practicing Communities</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.practicing_communities &&
                                        !textVillage.includes(
                                          i.practicing_communities
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.practicing_communities
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "practicing_communities",
                                                  {
                                                    caption:
                                                      i?.practicing_communities,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.practicing_communities && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "practicing_communities",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Suggestion for Revival and
                                                Economic Viability of Art Form
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.suggestion_for_revival_and_economic &&
                                        !textVillage.includes(
                                          i.suggestion_for_revival_and_economic
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.suggestion_for_revival_and_economic
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "suggestion_for_revival_and_economic",
                                                  {
                                                    caption:
                                                      i?.suggestion_for_revival_and_economic,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.suggestion_for_revival_and_economic && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "suggestion_for_revival_and_economic",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}

                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>
                                                Classification of art form
                                              </div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.classification_of_art_form &&
                                        !textVillage.includes(
                                          i.classification_of_art_form
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.classification_of_art_form
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "classification_of_art_form",
                                                  {
                                                    caption:
                                                      i?.classification_of_art_form,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.classification_of_art_form && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "classification_of_art_form",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                        <div
                                          className="d-flex flex-row bd-highlight  mt-2"
                                          onMouseLeave={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseEnter={(e) => {
                                            setAboutEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                              <div>Surface & treatment</div>
                                            </h3>
                                          </div>
                                        </div>

                                        {i.technique_used &&
                                        !textVillage.includes(
                                          i.technique_used
                                        ) ? (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex ">
                                              <div className="inputText">
                                                {FilterNaItems(
                                                  i.technique_used
                                                )}
                                              </div>
                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "technique_used",
                                                  {
                                                    caption: i?.technique_used,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.technique_used && (
                                                <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
                                                  <IconButton>
                                                    <CiCircleInfo fill="#40B05E" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}

                                              <span>
                                                {handleAllEditcontent(
                                                  "traditional_art",
                                                  "technique_used",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* -------------------------------------------------------------------------------------------------------------------- */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------TRADITIONAL FOOD---------------------------------------------------- */}

                {/* {selectedTopic === "traditional_food" && ( */}
                {/* {selectedTopic === "traditional_food" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      marginTop: "5%",
                    }}
                    ref={foodRef}
                    data-section="traditional_food"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.traditional_food &&
                        Object.keys(topicsData?.traditional_food[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "117px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: foodFontColor,
                                fontWeight: foodFontWeight,
                                fontSize: "18px",
                              }}
                            >
                              Traditional Food
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.traditional_food && (
                          <>
                            {renderEditOption(
                              "traditional_food",
                              "description",
                              {
                                index: 0,
                              }
                            )}
                          </>
                        )}
                        {topicsData?.traditional_food &&
                        topicsData?.traditional_food?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <>
                            <Carousel
                              interval={null}
                              activeIndex={foodIndex}
                              onSelect={handleFoodIndex}
                              className="th-border-bottom pt-3 px-3 th-border-top"
                              style={{ borderRadius: "20px" }}
                              controls={
                                topicsData?.traditional_food[0]?.media?.length >
                                1
                              }
                              indicators={false}
                            >
                              {topicsData?.traditional_food
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, indexO) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4"
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {/* {topicsData.block_name ? (topicsData?.block_name?.toLowerCase()) + ", " : null} */}
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "traditional_food",
                                                    "caption_edit",
                                                    item,
                                                    null,
                                                    indexO
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {foodIndex + 1} /
                              {topicsData?.traditional_food[0]?.media?.length}
                            </div>
                          </>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-16 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "600",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {(!topicsData?.traditional_food ||
                          topicsData?.traditional_food?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {removeContentWords.includes(
                                  i?.title
                                ) ? null : (
                                  <div
                                    className={
                                      "pt-4 pb-3 border-radius-content-village-deets " +
                                      (index != 0 ? " mt-2" : "")
                                    }
                                  >
                                    <div className="bd-highlight">
                                      <div>
                                        <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                          <div>{i?.title}</div>
                                          <hr />
                                        </h3>
                                      </div>
                                    </div>

                                    {/* description */}
                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                          <div>Description</div>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.description &&
                                    !textVillage.includes(i.description) ? (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "traditional_food",
                                              "description",
                                              { caption: i?.description },
                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          {!i.description && <NoDataMessage />}
                                          <span>
                                            {handleAllEditcontent(
                                              "traditional_food",
                                              "description",
                                              { caption: "" },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            );
                          })}

                        {topicsData?.traditional_food?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i?.title) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                        <div>Description</div>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                  !textVillage.includes(i.description) ? (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {convertLinks(
                                            FilterNaItems(i.description)
                                          )}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_food",
                                            "description",
                                            { caption: i?.description },
                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        {!i.description && <NoDataMessage />}
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_food",
                                            "description",
                                            { caption: "" },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}
                {/* )} */}

                {/* ----------------------------------------------TRADITIONAL DRESS---------------------------------------------------- */}

                {/* {selectedTopic === "traditional_dress" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={dressRef}
                    data-section="traditional_dress"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.traditional_dress &&
                        Object.keys(topicsData?.traditional_dress[0])?.length >
                          1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "117px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: dressFontColor,
                                fontWeight: dressFontWeight,
                                fontSize: "18px",
                              }}
                            >
                              Traditional Dress
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.traditional_dress && (
                          <>
                            {renderEditOption(
                              "traditional_dress",
                              "description",
                              {
                                index: 0,
                              }
                            )}
                          </>
                        )}

                        {topicsData?.traditional_dress &&
                        topicsData?.traditional_dress?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              activeIndex={dressIndex}
                              onSelect={handleDressIndex}
                              className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              indicators={false}
                              controls={
                                topicsData?.traditional_dress[0]?.media
                                  ?.length > 1
                              }
                            >
                              {topicsData?.traditional_dress
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4"
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "traditional_dress",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {dressIndex + 1} /
                              {topicsData?.traditional_dress[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom my-3 pt-3 px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-12 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontSize: "18px",
                                          fontWeight: "500",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.traditional_dress?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i.title) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                        <div>Description</div>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                  !textVillage.includes(i.description) ? (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {convertLinks(
                                            FilterNaItems(i.description)
                                          )}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_dress",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        {!i.description && <NoDataMessage />}
                                        <span>
                                          {handleAllEditcontent(
                                            "traditional_dress",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          );
                        })}

                        {(!topicsData?.traditional_dress ||
                          topicsData?.traditional_dress?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {removeContentWords.includes(i.title) ? null : (
                                  <div
                                    className={
                                      "pt-4 pb-3 border-radius-content-village-deets " +
                                      (index != 0 ? " mt-2" : "")
                                    }
                                  >
                                    <div className="bd-highlight">
                                      <div>
                                        <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                          <div>{i?.title}</div>
                                          <hr />
                                        </h3>
                                      </div>
                                    </div>

                                    {/* description */}
                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                          <div>Description</div>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.description &&
                                    !textVillage.includes(i.description) ? (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "traditional_dress",
                                              "description",
                                              { caption: i?.description },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          {!i.description && <NoDataMessage />}
                                          <span>
                                            {handleAllEditcontent(
                                              "traditional_dress",
                                              "description",
                                              { caption: i?.description },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------ORNAMENTS---------------------------------------------------- */}

                {/* {selectedTopic === "ornaments" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={ornamentRef}
                    data-section="ornaments"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.ornaments &&
                        Object.keys(topicsData?.ornaments[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "117px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: ornamentsFontColor,
                                fontWeight: ornamentsFontWeight,
                                fontSize: "18px",
                              }}
                            >
                              Traditional Ornaments
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.ornaments && (
                          <>
                            {renderEditOption("ornaments", "description", {
                              index: 0,
                            })}
                          </>
                        )}
                        {topicsData.ornaments &&
                        topicsData?.ornaments?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom pt-3 px-3 th-border-top demo-animation"
                              style={{ borderRadius: "20px" }}
                              activeIndex={ornamentsIndex}
                              onSelect={handleOrnamentsIndex}
                              indicators={false}
                              controls={
                                topicsData?.ornaments[0]?.media?.length > 1
                              }
                            >
                              {topicsData?.ornaments
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4 "
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "ornaments",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {ornamentsIndex + 1} /
                              {topicsData?.ornaments[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-16 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "600",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.ornaments?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i?.title) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "ornaments",
                                                  "description",
                                                  { caption: i?.description },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "ornaments",
                                              "description",
                                              { caption: i?.description },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                            </>
                          );
                        })}
                        {(!topicsData?.ornaments ||
                          topicsData?.ornaments?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords.includes(i?.title) ? null : ( */}
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3 className="th-14 th-fw-500 mb-0 text-capitalize px-3">
                                        <div>Description</div>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                  !textVillage.includes(i.description) ? (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {convertLinks(
                                            FilterNaItems(i.description)
                                          )}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "ornaments",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        {!i.description && <NoDataMessage />}
                                        <span>
                                          {handleAllEditcontent(
                                            "ornaments",
                                            "description",
                                            { caption: i?.description },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------BELIEFS---------------------------------------------------- */}
                {/* {selectedTopic === "belief" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={beliefRef}
                    data-section="belief"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.belief &&
                        Object.keys(topicsData?.belief[0])?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "117px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: beliefFontColor,
                                fontWeight: beliefFontWeight,
                                fontSize: "18px",
                              }}
                            >
                              Beliefs & Customs
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.belief && (
                          <>
                            {renderEditOption("belief", "description", {
                              index: 0,
                            })}
                          </>
                        )}
                        {topicsData.belief &&
                        topicsData?.belief?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={beliefIndex}
                              onSelect={handleBeliefIndex}
                              indicators={false}
                              controls={
                                topicsData?.belief[0]?.media?.length > 1
                              }
                            >
                              {topicsData?.belief
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    if (parseInt(item?.status) !== -1) {
                                      return (
                                        item != null &&
                                        item?.media_type != "video" &&
                                        parseInt(item?.status) !== -1 && (
                                          <Carousel.Item
                                            style={{
                                              position: "relative",
                                            }}
                                          >
                                            <SliderImage
                                              getData={getData}
                                              image_id={item?.media_name}
                                            />
                                            <Carousel.Caption
                                              className="display-static pb-0"
                                              onMouseEnter={() =>
                                                setOnHoverEff(true)
                                              }
                                              onMouseLeave={() =>
                                                setOnHoverEff(true)
                                              }
                                            >
                                              <div className="d-block pt-2">
                                                <div className="d-flex">
                                                  <p
                                                    className="th-12 mb-4"
                                                    style={{ color: "#1e1d4c" }}
                                                  >
                                                    {item?.caption ? (
                                                      RemoveCapturedBy(
                                                        item?.caption
                                                      )
                                                    ) : (
                                                      <NoDataMessage />
                                                    )}
                                                  </p>
                                                  <div>
                                                    {handleAllEditcontent(
                                                      "belief",
                                                      "caption_edit",
                                                      item
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {onHoverEff ? (
                                                <div>
                                                  <img
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleModalShow(
                                                        item,
                                                        index
                                                      )
                                                    }
                                                    src={PlusGreen}
                                                    alt={item?.title}
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </Carousel.Caption>
                                            {item?.caption && (
                                              <p className="text-disclaimer-with-edit px-3">
                                                The images on the portal have
                                                been collected and uploaded only
                                                prior to the consent of the
                                                concerned person.
                                              </p>
                                            )}
                                          </Carousel.Item>
                                        )
                                      );
                                    }
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {beliefIndex + 1} /
                              {topicsData?.belief[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom my-3 pt-3 px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-12 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontSize: "18px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {topicsData?.belief?.map((i, index) => {
                          return (
                            <>
                              {removeContentWords.includes(i?.title) ? null : (
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "description",
                                                  { caption: i?.description },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "description",
                                              { caption: i?.description },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Belief Related To
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.belief_related_to && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "belief_related_to",
                                                  {
                                                    caption:
                                                      i?.belief_related_to,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.belief_related_to &&
                                    !textVillage.includes(
                                      i.belief_related_to
                                    ) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.belief_related_to)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "belief_related_to",
                                              { caption: i?.belief_related_to },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Type of Belief
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.type_of_belief && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "type_of_belief",
                                                  {
                                                    caption: i?.type_of_belief,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.type_of_belief &&
                                    !textVillage.includes(i.type_of_belief) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.type_of_belief)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "type_of_belief",
                                              { caption: i?.type_of_belief },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                            </>
                          );
                        })}
                        {(!topicsData?.belief ||
                          topicsData?.belief?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords.includes(i?.title) ? null : ( */}
                                <div
                                  className={
                                    "pt-4 pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>{i?.title}</div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* description */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Description
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.description && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "description",
                                                  { caption: i?.description },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.description &&
                                    !textVillage.includes(i.description) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {convertLinks(
                                              FilterNaItems(i.description)
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "description",
                                              { caption: i?.description },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Belief Related To
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.belief_related_to && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "belief_related_to",
                                                  {
                                                    caption:
                                                      i?.belief_related_to,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.belief_related_to &&
                                    !textVillage.includes(
                                      i.belief_related_to
                                    ) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.belief_related_to)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "belief_related_to",
                                              { caption: i?.belief_related_to },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Type of Belief
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.type_of_belief && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "belief",
                                                  "type_of_belief",
                                                  {
                                                    caption: i?.type_of_belief,
                                                  },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.type_of_belief &&
                                    !textVillage.includes(i.type_of_belief) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.type_of_belief)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "belief",
                                              "type_of_belief",
                                              { caption: i?.type_of_belief },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------FAMOUS PERSONALITY---------------------------------------------------- */}

                {/* {selectedTopic === "famous_personalities" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={famousRef}
                    data-section="famous_personalities"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.famous_personalities &&
                        Object.keys(topicsData?.famous_personalities[0])
                          ?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "128px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: famousFontColor,
                                fontWeight: famousFontWeight,
                                fontSize: "18px",
                              }}
                            >
                              Famous Personalities
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.famous_personalities && (
                          <>
                            {renderEditOption(
                              "famous_personalities",
                              "description",
                              { index: 0 }
                            )}
                          </>
                        )}

                        {topicsData?.famous_personalities &&
                        topicsData?.famous_personalities?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={famousIndex}
                              onSelect={handleFamousIndex}
                              indicators={false}
                              controls={
                                topicsData?.famous_personalities[0]?.media
                                  ?.length > 1
                              }
                            >
                              {topicsData?.famous_personalities
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4 "
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "famous_personalities",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>

                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {famousIndex + 1} /
                              {
                                topicsData?.famous_personalities[0]?.media
                                  ?.length
                              }
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom my-3 pt-3 px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-16 mb-4"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "600",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {(!topicsData?.famous_personalities ||
                          topicsData?.famous_personalities?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <div
                                className={
                                  "pb-3 border-radius-content-village-deets " +
                                  (index != 0 ? " mt-2" : "")
                                }
                                key={index}
                              >
                                <div className="bd-highlight">
                                  <div>
                                    <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                      <div>
                                        {/* {i?.name_of_local_hero || <NoDataMessage />} */}
                                      </div>
                                      <hr />
                                    </h3>
                                  </div>
                                </div>

                                {/* description */}
                                <div
                                  className="d-flex flex-row bd-highlight mt-2"
                                  onMouseLeave={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                  onMouseEnter={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                >
                                  <div>
                                    <h3
                                      className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Personal Details{" "}
                                      {!i.personal_details && (
                                        <span>
                                          <NoDataMessage />
                                        </span>
                                      )}
                                      {handleAllEditcontent(
                                        "famous_personalities",
                                        "personal_details",
                                        { caption: i?.personal_details },
                                        index
                                      )}
                                    </h3>
                                  </div>
                                </div>

                                <div className="th-14 pb-1 px-3">
                                  <div className="d-flex">
                                    <div className="inputText">
                                      {i.personal_details &&
                                        !textVillage.includes(
                                          i.personal_details
                                        ) &&
                                        FilterNaItems(i.personal_details)}
                                    </div>
                                    <span></span>
                                  </div>
                                </div>

                                <div
                                  className="d-flex flex-row bd-highlight mt-2"
                                  onMouseLeave={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                  onMouseEnter={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                >
                                  <div>
                                    <h3
                                      className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Person / Family related to (present)
                                      {!i.family_related_to && (
                                        <span>
                                          <NoDataMessage />
                                        </span>
                                      )}
                                      {handleAllEditcontent(
                                        "famous_personalities",
                                        "family_related_to",
                                        { caption: i?.family_related_to },
                                        index
                                      )}
                                    </h3>
                                  </div>
                                </div>

                                <div className="th-14 pb-1 px-3">
                                  <div className="d-flex">
                                    <div className="inputText">
                                      {i.family_related_to &&
                                        !textVillage.includes(
                                          i.family_related_to
                                        ) &&
                                        FilterNaItems(i.family_related_to)}
                                    </div>
                                    <span></span>
                                  </div>
                                </div>

                                <div
                                  className="d-flex flex-row bd-highlight mt-2"
                                  onMouseLeave={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                  onMouseEnter={(e) => {
                                    setAboutEdit({ display: "block" });
                                  }}
                                >
                                  <div>
                                    <h3
                                      className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Any Legendary Stories?
                                      {!i.legend_stories && (
                                        <span>
                                          <NoDataMessage />
                                        </span>
                                      )}
                                      {handleAllEditcontent(
                                        "famous_personalities",
                                        "family_related_to",
                                        { caption: i?.family_related_to },
                                        index
                                      )}
                                    </h3>
                                  </div>
                                </div>

                                <div className="th-14 pb-1 px-3">
                                  <div className="d-flex">
                                    <div className="inputText">
                                      {i.legend_stories &&
                                        !textVillage.includes(
                                          i.legend_stories
                                        ) &&
                                        FilterNaItems(i.legend_stories)}
                                    </div>
                                    <span></span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {topicsData?.famous_personalities?.map((i, index) => {
                          return (
                            <div
                              className={
                                "pb-3 border-radius-content-village-deets " +
                                (index != 0 ? " mt-2" : "")
                              }
                              key={index}
                            >
                              <div className="bd-highlight">
                                <div>
                                  <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                    <div>
                                      {/* {i?.name_of_local_hero || <NoDataMessage />} */}
                                    </div>
                                    <hr />
                                  </h3>
                                </div>
                              </div>

                              {/* description */}
                              <div
                                className="d-flex flex-row bd-highlight mt-2"
                                onMouseLeave={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                                onMouseEnter={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                              >
                                <div>
                                  <h3
                                    className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    Personal Details{" "}
                                    {!i.personal_details && (
                                      <span>
                                        <NoDataMessage />
                                      </span>
                                    )}
                                    {handleAllEditcontent(
                                      "famous_personalities",
                                      "personal_details",
                                      { caption: i?.personal_details },
                                      index
                                    )}
                                  </h3>
                                </div>
                              </div>

                              <div className="th-14 pb-1 px-3">
                                <div className="d-flex">
                                  <div className="inputText">
                                    {i.personal_details &&
                                      !textVillage.includes(
                                        i.personal_details
                                      ) &&
                                      FilterNaItems(i.personal_details)}
                                  </div>
                                  <span></span>
                                </div>
                              </div>

                              <div
                                className="d-flex flex-row bd-highlight mt-2"
                                onMouseLeave={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                                onMouseEnter={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                              >
                                <div>
                                  <h3
                                    className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    Person / Family related to (present)
                                    {!i.family_related_to && (
                                      <span>
                                        <NoDataMessage />
                                      </span>
                                    )}
                                    {handleAllEditcontent(
                                      "famous_personalities",
                                      "family_related_to",
                                      { caption: i?.family_related_to },
                                      index
                                    )}
                                  </h3>
                                </div>
                              </div>

                              <div className="th-14 pb-1 px-3">
                                <div className="d-flex">
                                  <div className="inputText">
                                    {i.family_related_to &&
                                      !textVillage.includes(
                                        i.family_related_to
                                      ) &&
                                      FilterNaItems(i.family_related_to)}
                                  </div>
                                  <span></span>
                                </div>
                              </div>

                              <div
                                className="d-flex flex-row bd-highlight mt-2"
                                onMouseLeave={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                                onMouseEnter={(e) => {
                                  setAboutEdit({ display: "block" });
                                }}
                              >
                                <div>
                                  <h3
                                    className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    Any Legendary Stories?
                                    {!i.legend_stories && (
                                      <span>
                                        <NoDataMessage />
                                      </span>
                                    )}
                                    {handleAllEditcontent(
                                      "famous_personalities",
                                      "family_related_to",
                                      { caption: i?.family_related_to },
                                      index
                                    )}
                                  </h3>
                                </div>
                              </div>

                              <div className="th-14 pb-1 px-3">
                                <div className="d-flex">
                                  <div className="inputText">
                                    {i.legend_stories &&
                                      !textVillage.includes(i.legend_stories) &&
                                      FilterNaItems(i.legend_stories)}
                                  </div>
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------------------prominent_artists---------------------------------------------------- */}

                {/* {selectedTopic === "prominent_artists" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      position: "relative",
                      marginTop: "5%",
                    }}
                    ref={prominentRef}
                    data-section="prominent_artists"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.prominent_artists &&
                        Object.keys(topicsData?.prominent_artists[0])?.length >
                          1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "117px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: artistFontColor,
                                fontWeight: artistFontWeight,
                                fontSize: "18px",
                              }}
                            >
                              Prominent Artists
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData.prominent_artists && (
                          <>
                            {renderEditOption(
                              "prominent_artists",
                              "description",
                              {
                                index: 0,
                              }
                            )}
                          </>
                        )}

                        {topicsData?.prominent_artists &&
                        topicsData?.prominent_artists?.length > 0 &&
                        topicsData?.prominent_artists?.filter(function (i) {
                          return mediaItem(i);
                        })?.length != 0 ? (
                          <div>
                            <Carousel
                              interval={null}
                              className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                              style={{ borderRadius: "20px" }}
                              activeIndex={artistIndex}
                              onSelect={handleArtistIndex}
                              indicators={false}
                              controls={
                                topicsData?.prominent_artists[0]?.media
                                  ?.length > 1
                              }
                            >
                              {topicsData?.prominent_artists
                                ?.filter(function (i) {
                                  return (
                                    i.media?.length != 0 && i.media != null
                                  );
                                })
                                ?.map((i, index) => {
                                  return i.media?.map((item, index) => {
                                    return (
                                      item != null &&
                                      item?.media_type != "video" &&
                                      parseInt(item?.status) !== -1 && (
                                        <Carousel.Item
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <SliderImage
                                            getData={getData}
                                            image_id={item?.media_name}
                                          />
                                          <Carousel.Caption
                                            className="display-static pb-0"
                                            onMouseEnter={() =>
                                              setOnHoverEff(true)
                                            }
                                            onMouseLeave={() =>
                                              setOnHoverEff(true)
                                            }
                                          >
                                            <div className="d-block pt-2">
                                              <div className="d-flex">
                                                <p
                                                  className="th-12 mb-4"
                                                  style={{ color: "#1e1d4c" }}
                                                >
                                                  {item?.caption ? (
                                                    RemoveCapturedBy(
                                                      item?.caption
                                                    )
                                                  ) : (
                                                    <NoDataMessage />
                                                  )}
                                                </p>
                                                <div>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "caption_edit",
                                                    item
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {onHoverEff ? (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow(item, index)
                                                  }
                                                  src={PlusGreen}
                                                  alt={item?.title}
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Carousel.Caption>
                                          {item?.caption && (
                                            <p className="text-disclaimer-with-edit px-3">
                                              The images on the portal have been
                                              collected and uploaded only prior
                                              to the consent of the concerned
                                              person.
                                            </p>
                                          )}
                                        </Carousel.Item>
                                      )
                                    );
                                  });
                                })}
                            </Carousel>
                            <div style={{ textAlign: "center" }}>
                              {artistIndex + 1} /
                              {topicsData?.prominent_artists[0]?.media?.length}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              className={`th-bg-white th-border-bottom px-3 th-border-top`}
                              style={{
                                borderRadius: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <SliderImage getData={"imahge"} /> */}
                                <div
                                  className="display-static pb-0"
                                  onMouseEnter={() => setOnHoverEff(true)}
                                  onMouseLeave={() => setOnHoverEff(true)}
                                >
                                  <div className="d-block">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        className="th-14"
                                        style={{
                                          color: "#1e1d4c",
                                          fontWeight: "500",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Add Image
                                        <span>
                                          {onHoverEff && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                width: "100%",
                                              }}
                                            >
                                              <NoDataMessage />
                                              {isEdit && (
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleModalShow()
                                                  }
                                                  src={PlusGreen}
                                                  alt="Dummy"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {topicsData?.prominent_artists?.length > 0 &&
                          topicsData?.prominent_artists?.map((i, index) => {
                            return (
                              <>
                                {removeContentWords?.includes(
                                  i?.name
                                ) ? null : (
                                  <div
                                    className={
                                      "pb-3 border-radius-content-village-deets " +
                                      (index != 0 ? " mt-2" : "")
                                    }
                                  >
                                    <div className="bd-highlight">
                                      <div>
                                        <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                          <div>
                                            {" "}
                                            {FilterNaItems(i?.name)}
                                            {FilterNaItems(i?.gender)}
                                          </div>
                                          <hr />
                                        </h3>
                                      </div>
                                    </div>

                                    {/* frist field */}
                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Expertise
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.expertise_of_skills && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "expertise_of_skills",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.expertise_of_skills &&
                                      !textVillage.includes(
                                        i.expertise_of_skills
                                      ) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(
                                                i.expertise_of_skills
                                              )}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "expertise_of_skills",
                                                {
                                                  caption:
                                                    i?.expertise_of_skills,
                                                },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Gender
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.gender && <NoDataMessage />}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "gender",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.gender &&
                                      !textVillage.includes(i.gender) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(i.gender)}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "gender",
                                                { caption: i?.gender },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Age
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.age && <NoDataMessage />}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "age",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.age && !textVillage.includes(i.age) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.age)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "age",
                                              { caption: i?.age },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Address for Communication
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.address && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "address",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.address &&
                                      !textVillage.includes(i.address) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(i.address)}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "address",
                                                { caption: i?.address },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Pincode
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.pincode && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "pincode",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.pincode &&
                                      !textVillage.includes(i.pincode) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(i.pincode)}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "pincode",
                                                { caption: i?.pincode },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Category of expertise
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.expertise_of_category && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "expertise_of_category",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.expertise_of_category &&
                                      !textVillage.includes(
                                        i.expertise_of_category
                                      ) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(
                                                i.expertise_of_category
                                              )}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "expertise_of_category",
                                                {
                                                  caption:
                                                    i?.expertise_of_category,
                                                },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          ID card Type
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.id_card_type && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "id_card_type",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.id_card_type &&
                                      !textVillage.includes(i.id_card_type) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(
                                                i?.id_card_type == 1
                                                  ? "Aadhaar Card"
                                                  : i?.id_card_type == 2
                                                  ? "Driving License"
                                                  : i?.id_card_type == 3
                                                  ? "PAN Card"
                                                  : i?.id_card_type == 4
                                                  ? " Passport"
                                                  : i?.id_card_type == 5
                                                  ? " Pension Passbook"
                                                  : i?.id_card_type == 6
                                                  ? "   NPR Smart Card"
                                                  : i?.id_card_type == 7
                                                  ? "Voter ID"
                                                  : i?.id_card_type == 8
                                                  ? " Unique Disability ID (Unique ID for Persons with Disabilities)"
                                                  : i?.id_card_type == 9
                                                  ? " Ration Card with Photo"
                                                  : ""
                                              )}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "expertise_of_skills",
                                                {
                                                  caption:
                                                    i?.id_card_type == 1
                                                      ? "Aadhaar Card"
                                                      : i?.id_card_type == 2
                                                      ? "Driving License"
                                                      : i?.id_card_type == 3
                                                      ? "PAN Card"
                                                      : i?.id_card_type == 4
                                                      ? " Passport"
                                                      : i?.id_card_type == 5
                                                      ? " Pension Passbook"
                                                      : i?.id_card_type == 6
                                                      ? "   NPR Smart Card"
                                                      : i?.id_card_type == 7
                                                      ? "Voter ID"
                                                      : i?.id_card_type == 8
                                                      ? " Unique Disability ID (Unique ID for Persons with Disabilities)"
                                                      : i?.id_card_type == 9
                                                      ? " Ration Card with Photo"
                                                      : "",
                                                },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    <div
                                      className="d-flex flex-row bd-highlight  mt-2"
                                      onMouseLeave={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                      onMouseEnter={(e) => {
                                        setAboutEdit({ display: "block" });
                                      }}
                                    >
                                      <div>
                                        <h3
                                          className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          ID Card Number
                                          <span>
                                            <div className="th-14 pb-1 px-3">
                                              <div className="d-flex">
                                                {!i.id_card_number && (
                                                  <NoDataMessage />
                                                )}
                                                <span>
                                                  {handleAllEditcontent(
                                                    "prominent_artists",
                                                    "id_card_number",
                                                    { caption: "" },

                                                    index
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    {i.id_card_number &&
                                      !textVillage.includes(
                                        i.id_card_number
                                      ) && (
                                        <div className="th-14 pb-1 px-3">
                                          <div className="d-flex">
                                            <div className="inputText">
                                              {FilterNaItems(i.id_card_number)}
                                            </div>
                                            <span>
                                              {handleAllEditcontent(
                                                "prominent_artists",
                                                "id_card_number",
                                                { caption: i?.id_card_number },

                                                index
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                )}
                              </>
                            );
                          })}

                        {(!topicsData?.prominent_artists ||
                          topicsData?.prominent_artists?.length === 0) &&
                          new Array(1).fill(1).map((i, index) => {
                            return (
                              <>
                                {/* {removeContentWords?.includes(i?.name) ? null : ( */}
                                <div
                                  className={
                                    "pb-3 border-radius-content-village-deets " +
                                    (index != 0 ? " mt-2" : "")
                                  }
                                >
                                  <div className="bd-highlight">
                                    <div>
                                      <h3 className="th-18 th-fw-600 mb-0 text-capitalize px-3">
                                        <div>
                                          {" "}
                                          {FilterNaItems(i?.name)}
                                          {FilterNaItems(i?.gender)}
                                        </div>
                                        <hr />
                                      </h3>
                                    </div>
                                  </div>

                                  {/* frist field */}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Expertise
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.expertise_of_skills && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "expertise_of_skills",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.expertise_of_skills &&
                                    !textVillage.includes(
                                      i.expertise_of_skills
                                    ) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(
                                              i.expertise_of_skills
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "expertise_of_skills",
                                              {
                                                caption: i?.expertise_of_skills,
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Gender
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.gender && <NoDataMessage />}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "gender",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.gender &&
                                    !textVillage.includes(i.gender) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.gender)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "gender",
                                              { caption: i?.gender },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Age
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.age && <NoDataMessage />}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "age",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.age && !textVillage.includes(i.age) && (
                                    <div className="th-14 pb-1 px-3">
                                      <div className="d-flex">
                                        <div className="inputText">
                                          {FilterNaItems(i.age)}
                                        </div>
                                        <span>
                                          {handleAllEditcontent(
                                            "prominent_artists",
                                            "age",
                                            { caption: i?.age },

                                            index
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Address for Communication
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.address && <NoDataMessage />}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "address",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.address &&
                                    !textVillage.includes(i.address) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.address)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "address",
                                              { caption: i?.address },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Pincode
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.pincode && <NoDataMessage />}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "pincode",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.pincode &&
                                    !textVillage.includes(i.pincode) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.pincode)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "pincode",
                                              { caption: i?.pincode },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Category of expertise
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.expertise_of_category && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "expertise_of_category",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.expertise_of_category &&
                                    !textVillage.includes(
                                      i.expertise_of_category
                                    ) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(
                                              i.expertise_of_category
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "expertise_of_category",
                                              {
                                                caption:
                                                  i?.expertise_of_category,
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        ID card Type
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.id_card_type && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "id_card_type",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.id_card_type &&
                                    !textVillage.includes(i.id_card_type) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(
                                              i?.id_card_type == 1
                                                ? "Aadhaar Card"
                                                : i?.id_card_type == 2
                                                ? "Driving License"
                                                : i?.id_card_type == 3
                                                ? "PAN Card"
                                                : i?.id_card_type == 4
                                                ? " Passport"
                                                : i?.id_card_type == 5
                                                ? " Pension Passbook"
                                                : i?.id_card_type == 6
                                                ? "   NPR Smart Card"
                                                : i?.id_card_type == 7
                                                ? "Voter ID"
                                                : i?.id_card_type == 8
                                                ? " Unique Disability ID (Unique ID for Persons with Disabilities)"
                                                : i?.id_card_type == 9
                                                ? " Ration Card with Photo"
                                                : ""
                                            )}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "expertise_of_skills",
                                              {
                                                caption:
                                                  i?.id_card_type == 1
                                                    ? "Aadhaar Card"
                                                    : i?.id_card_type == 2
                                                    ? "Driving License"
                                                    : i?.id_card_type == 3
                                                    ? "PAN Card"
                                                    : i?.id_card_type == 4
                                                    ? " Passport"
                                                    : i?.id_card_type == 5
                                                    ? " Pension Passbook"
                                                    : i?.id_card_type == 6
                                                    ? "   NPR Smart Card"
                                                    : i?.id_card_type == 7
                                                    ? "Voter ID"
                                                    : i?.id_card_type == 8
                                                    ? " Unique Disability ID (Unique ID for Persons with Disabilities)"
                                                    : i?.id_card_type == 9
                                                    ? " Ration Card with Photo"
                                                    : "",
                                              },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  <div
                                    className="d-flex flex-row bd-highlight  mt-2"
                                    onMouseLeave={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                    onMouseEnter={(e) => {
                                      setAboutEdit({ display: "block" });
                                    }}
                                  >
                                    <div>
                                      <h3
                                        className="th-14 th-fw-500 mb-0 text-capitalize px-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        ID Card Number
                                        <span>
                                          <div className="th-14 pb-1 px-3">
                                            <div className="d-flex">
                                              {!i.id_card_number && (
                                                <NoDataMessage />
                                              )}
                                              <span>
                                                {handleAllEditcontent(
                                                  "prominent_artists",
                                                  "id_card_number",
                                                  { caption: "" },

                                                  index
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </h3>
                                    </div>
                                  </div>

                                  {i.id_card_number &&
                                    !textVillage.includes(i.id_card_number) && (
                                      <div className="th-14 pb-1 px-3">
                                        <div className="d-flex">
                                          <div className="inputText">
                                            {FilterNaItems(i.id_card_number)}
                                          </div>
                                          <span>
                                            {handleAllEditcontent(
                                              "prominent_artists",
                                              "id_card_number",
                                              { caption: i?.id_card_number },

                                              index
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------GEOGRAPHICAL LOCATION------------------------------------------ */}

                {/* {selectedTopic === "geographical" && ( */}
                <div
                  className="pr-1"
                  ref={geoSectionRef}
                  data-section="geographical"
                >
                  <Accordion
                    defaultExpanded={
                      topicsData?.geographical &&
                      Object.keys(topicsData?.geographical)?.length > 1
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      style={{
                        position: "sticky",
                        top: "117px",
                        zIndex: "110",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          borderBottomLeftRadius: "15px",
                          borderBottomRightRadius: "15px",
                          padding: "5px 5px 5px 15px",
                          width: "100%",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              color: geoFontColor,
                              fontWeight: geoFontWeight,
                              fontSize: "18px",
                            }}
                          >
                            Geographic And Demographic Profile
                          </span>
                        </div>
                      </div>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div
                        className="th-bg-white th-border-bottom px-3 pb-2 th-border-top mr-2 img-fluid"
                        style={{
                          paddingRight: "15px",
                        }}
                      >
                        <iframe
                          className="mt-3 th-br-8"
                          title="location"
                          width="100%"
                          height="500"
                          style={{ border: 0 }}
                          allowFullscreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          src={`https://maps.google.com/maps?q=${topicsData?.geographical?.latitude_id},${topicsData?.geographical?.longitude_id}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                        />
                      </div>
                      <div className="row">
                        {generateNewCardComponent(
                          "Ancient Name",
                          "geographical",
                          "ancient_name",
                          LanguageIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Local Language",
                          "geographical",
                          "local_language",
                          LanguageIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Latitude Id",
                          "geographical",
                          "latitude_id",
                          LanguageIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Longitude Id",
                          "geographical",
                          "longitude_id",
                          LanguageIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Male",
                          "geographical",
                          "male_population",
                          CommunitiesIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Female",
                          "geographical",
                          "female_population",
                          CommunitiesIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Other Population",
                          "geographical",
                          "other_population",
                          CommunitiesIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}

                        {["", undefined, "NA"].includes(
                          topicsData?.geographical?.total_population
                        ) ? (
                          <div className="col-lg-6 col-md-12 col-md-12">
                            <div className="bedu my-2  primary-part">
                              <OverviewCard
                                village_key={keyy}
                                village_name={villageData?.data?.village_name}
                                blockName={villageData.data?.block_name}
                                districtName={villageData.data?.district_name}
                                jsonField="total_population"
                                subtitle="geographical"
                                title="Total Population"
                                total_people={"No Data Available"}
                                state_name={villageData?.data?.state_name}
                                answer={"No Data Available"}
                                icon={CommunitiesIcon}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="col-lg-6 col-md-12 col-md-12">
                            <div className="bedu my-2  primary-part">
                              <OverviewCard
                                village_key={keyy}
                                village_name={villageData?.data?.village_name}
                                blockName={villageData.data?.block_name}
                                districtName={villageData.data?.district_name}
                                jsonField="total_population"
                                subtitle="geographical"
                                title="Total Population"
                                total_people={
                                  topicsData?.geographical?.total_population
                                }
                                state_name={villageData?.data?.state_name}
                                answer={
                                  parseInt(
                                    topicsData?.geographical?.male_population
                                  ) +
                                  parseInt(
                                    topicsData?.geographical?.female_population
                                  ) +
                                  parseInt(
                                    topicsData?.geographical?.other_population
                                  )
                                }
                                icon={CommunitiesIcon}
                              />
                            </div>
                          </div>
                        )}
                        {generateNewCardComponent(
                          "Geographical Location Id",
                          "geographical",
                          "geographic_location_id",
                          RailwayIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Other Name Of Village",
                          "geographical",
                          "other_name_of_village",
                          RailwayIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Nearest Bus Terminal",
                          "geographical",
                          "nearest_bus_terminal",
                          BusIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Nearest Railway Station",
                          "geographical",
                          "nearest_railway_station",
                          RailwayIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                        {generateNewCardComponent(
                          "Status",
                          "geographical",
                          "status",
                          RailwayIcon,
                          topicsData,
                          keyy,
                          villageData
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {/* )} */}

                {/* ----------------------------------------------DEVELOPMENTAL---------------------------------------------------- */}

                {/* {selectedTopic === "developmental" && ( */}
                <>
                  <div
                    className="pr-1"
                    style={{
                      marginTop: "5%",
                    }}
                    ref={developmentalRef}
                    data-section="developmental"
                  >
                    <Accordion
                      defaultExpanded={
                        topicsData?.developmental &&
                        Object.keys(topicsData?.developmental)?.length > 1
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{
                          position: "sticky",
                          top: "117px",
                          zIndex: "110",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            padding: "5px 5px 5px 15px",
                            width: "100%",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: devFontColor,
                                fontWeight: devFontWeight,
                                fontSize: "18px",
                              }}
                            >
                              Developmental Profile
                            </span>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {!topicsData?.developmental?.media ? null : (
                          <Carousel
                            interval={null}
                            className="th-bg-white th-border-bottom pt-3 px-3 mb-5 th-border-top"
                            style={{ borderRadius: "20px" }}
                          >
                            {topicsData?.developmental?.media?.map(
                              (item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" && (
                                    <Carousel.Item
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <VillageImageDiv
                                        className="th-border-top th-border-bottom overflow-hidden"
                                        style={{
                                          backgroundColor: "#fff",
                                          backgroundSize: "cover",
                                          filter: "blur(10px)",
                                          border: "1px solid #3CAF5C",
                                          overflow: "hidden",
                                          backgroundImage: `url(${item?.cdn_path})`,
                                        }}
                                      ></VillageImageDiv>
                                      <VillageImageDiv
                                        className="th-border-top th-border-bottom"
                                        style={{
                                          position: "absolute",

                                          top: "0%",
                                          right: "0%",
                                          backgroundImage: `url(${item?.cdn_path})`,
                                        }}
                                      ></VillageImageDiv>
                                      <Carousel.Caption>
                                        <p
                                          className="th-12  mb-4 text-capitalize"
                                          style={{ color: "#1e1d4c" }}
                                        >
                                          {item?.caption ? (
                                            RemoveCapturedBy(item?.caption)
                                          ) : (
                                            <NoDataMessage />
                                          )}
                                        </p>
                                      </Carousel.Caption>
                                      {item?.caption && (
                                        <p className="text-disclaimer-with-edit px-3">
                                          The images on the portal have been
                                          collected and uploaded only prior to
                                          the consent of the concerned person.
                                        </p>
                                      )}
                                    </Carousel.Item>
                                  )
                                );
                              }
                            )}
                          </Carousel>
                        )}
                        <div
                          className="rounded "
                          style={{
                            overflowX: "hidden",
                          }}
                        >
                          <>
                            {/* {selectedTopic === "developmental" ? ( */}
                            <>
                              <div className="pt-3 pb-3 mb-1">
                                <div className="row">
                                  <div className="col-lg-6 col-md-12 col-md-12">
                                    {topicsData?.developmental
                                      ?.primary_source_of_livelihood != "" && (
                                      <>
                                        <h3 className="d-flex align-items-center th-14 th-fw-600 mb-1 text-capitalize px-3">
                                          <div>
                                            Primary Source Of Livelihood
                                          </div>
                                          <div>
                                            <>
                                              {handleAllEditcontent(
                                                "developmental",
                                                "primary_source_of_livelihood",
                                                topicsData?.developmental
                                                  ?.primary_source_of_livelihood
                                              )}
                                            </>
                                          </div>
                                        </h3>
                                        <div className="th-12 py-2 px-3">
                                          <>
                                            {topicsData?.developmental?.primary_source_of_livelihood?.map(
                                              (item, index) => {
                                                return (
                                                  <>
                                                    {item} <br />
                                                  </>
                                                );
                                              }
                                            )}
                                          </>
                                        </div>
                                      </>
                                    )}

                                    {topicsData?.developmental
                                      ?.primary_source_of_drinking_water !=
                                      "" && (
                                      <>
                                        <h3 className="d-flex align-items-center th-14 th-fw-600 mb-1 text-capitalize px-3">
                                          <div>
                                            Primary Source Of Drinking Water
                                          </div>
                                          <div>
                                            {handleAllEditcontent(
                                              "developmental",
                                              "primary_source_of_drinking_water",
                                              topicsData?.developmental
                                                ?.primary_source_of_drinking_water
                                            )}
                                          </div>
                                        </h3>
                                        <div className="th-12 py-2 px-3">
                                          <>
                                            {
                                              topicsData?.developmental
                                                ?.primary_source_of_drinking_water
                                            }
                                          </>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-lg-6 col-md-12 col-md-12">
                                    {FilterNaItems(
                                      topicsData?.developmental?.main_produces
                                    ) && (
                                      <>
                                        <div
                                          className="d-flex flex-row bd-highlight "
                                          onMouseEnter={(e) => {
                                            setAboutProducesEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseLeave={(e) => {
                                            setAboutProducesEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-600 mb-0 text-capitalize px-3">
                                              Main Produces
                                            </h3>
                                          </div>
                                          <div>
                                            {handleAllEditcontent(
                                              "developmental",
                                              "main_produces",
                                              {
                                                caption: FilterNaItems(
                                                  topicsData?.developmental
                                                    ?.main_produces
                                                ),
                                              }
                                            )}
                                          </div>
                                        </div>
                                        <div className="th-12 py-2 px-3">
                                          {FilterNaItems(
                                            topicsData?.developmental
                                              ?.main_produces
                                          )}
                                        </div>
                                      </>
                                    )}

                                    {FilterNaItems(
                                      topicsData?.developmental
                                        ?.village_paved_street
                                    ) && (
                                      <>
                                        <div
                                          className="d-flex flex-row bd-highlight "
                                          onMouseEnter={(e) => {
                                            setAboutProducesEdit({
                                              display: "block",
                                            });
                                          }}
                                          onMouseLeave={(e) => {
                                            setAboutProducesEdit({
                                              display: "block",
                                            });
                                          }}
                                        >
                                          <div>
                                            <h3 className="th-14 th-fw-600 mb-0 text-capitalize px-3">
                                              Village Paved Street
                                            </h3>
                                          </div>
                                          <div>
                                            {handleAllEditcontent(
                                              "developmental",
                                              "village_paved_street",
                                              {
                                                caption: FilterNaItems(
                                                  topicsData?.developmental
                                                    ?.village_paved_street
                                                ),
                                              }
                                            )}
                                          </div>
                                        </div>
                                        <div className="th-12 py-2 px-3">
                                          {FilterNaItems(
                                            topicsData.developmental
                                              .village_paved_street
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className=" row">
                                <div className="col-lg-6 col-md-12 col-md-12">
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Toilets",
                                    "toilet",
                                    "developmental",
                                    ToiletsIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Primary Health Centre",
                                    "primary_health_centre",
                                    "developmental",
                                    HealthIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Cooking Gas",
                                    "gas",
                                    "developmental",
                                    CookingIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Drinking Water",
                                    "tab_water",
                                    "developmental",
                                    WaterIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Post Office",
                                    "post_office",
                                    "developmental",
                                    PostoffcIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Nearest Railway Station",
                                    "nearest_railway_station",
                                    "developmental",
                                    RailwayIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Drainage System",
                                    "drainage_system",
                                    "developmental",
                                    WaterIcon
                                  )}
                                </div>
                                <div className="col-lg-6 col-md-12 col-md-12">
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "College",
                                    "college",
                                    "developmental",
                                    CollegeIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Oldest School",
                                    "school",
                                    "developmental",
                                    SchoolIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Bank",
                                    "bank",
                                    "developmental",
                                    BankIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Telephone",
                                    "telephone",
                                    "developmental",
                                    TelephoneIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Internet",
                                    "internet",
                                    "developmental",
                                    InternetIcon
                                  )}
                                  {renderOverviewCard(
                                    topicsData,
                                    villageData,
                                    keyy,
                                    "Electricity",
                                    "electricty",
                                    "developmental",
                                    ElectricityIcon
                                  )}
                                </div>
                              </div>
                            </>
                            {/* ) : null} */}
                          </>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
                {/* )} */}

                {/* ----------------------------------Image Gallery------------------------------------------ */}

                <div
                  ref={imageRef}
                  className="pr-1 shadow-sm bg-white rounded"
                  data-section="image_gallery"
                  style={{
                    position: "relative",
                    marginTop: "5%",
                  }}
                >
                  <div
                    style={{
                      position: "sticky",
                      top: "118px",
                      zIndex: "110",
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#fff",
                        width: "100%",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                        padding: "5px 5px 5px 15px",
                      }}
                    >
                      <span
                        style={{
                          color: imageFontColor,
                          fontWeight: imageFontWeight,
                          fontSize: "18px",
                        }}
                      >
                        Image Gallery
                      </span>
                    </div>
                  </div>
                  <div
                    // className="col-md-9 my-3"
                    style={{
                      borderRadius: "15px",
                      position: "relative",
                    }}
                  >
                    <>
                      <div
                        style={{
                          position: "sticky",
                          top: "155px",
                          backgroundColor: "#fff",
                          zIndex: "111",
                        }}
                      >
                        {Array?.from(new Set(gallery_photos_temp))?.map(
                          (topic, index) => {
                            return (
                              <>
                                <span>
                                  <button
                                    type="button"
                                    className={`date_btn m-2 text-capitalize  ${
                                      selectedGalleryTopic === topic
                                        ? "th-fw-600 col selectiveColor"
                                        : " not_selected_date "
                                    }`}
                                    key={index}
                                    onClick={() => {
                                      setSelectedGalleryTopic(topic);
                                      setIsOpen(false);
                                      setPhotoIndex(0);
                                    }}
                                  >
                                    {topic?.toLowerCase().split("_").join(" ")}
                                  </button>
                                </span>
                              </>
                            );
                          }
                        )}
                      </div>

                      <div className="container-fluid pt-3">
                        <div
                          className="row scollimagegallery"
                          style={{ overflowY: "auto" }}
                        >
                          {selectedGalleryTopic === "all_photos" ? (
                            <>
                              {resetImageGellary?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-lg-3 col-md-4 col-sm-6 col-6"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          setIsOpen(true);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.thumbnail
                                              ? item?.thumbnail
                                              : Default
                                          }
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageGellary[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImageGellary[
                                        (photoIndex + 1) %
                                          resetImageGellary.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageGellary[
                                        (photoIndex +
                                          resetImageGellary.length -
                                          1) %
                                          resetImageGellary.length
                                      ]?.image_path
                                    }
                                    imageCaption={RemoveCapturedBy(
                                      resetImageGellary[photoIndex]?.caption
                                    )}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageGellary.length -
                                          1) %
                                          resetImageGellary.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageGellary.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "heritage_places" ? (
                            <>
                              {resetImageHeritage?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageHeritage[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImageHeritage[
                                        (photoIndex + 1) %
                                          resetImageHeritage.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageHeritage[
                                        (photoIndex +
                                          resetImageHeritage.length -
                                          1) %
                                          resetImageHeritage.length
                                      ]?.image_path
                                    }
                                    imageCaption={
                                      resetImageHeritage[photoIndex]?.caption
                                    }
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageHeritage.length -
                                          1) %
                                          resetImageHeritage.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageHeritage.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "festivals" ? (
                            <>
                              {resetImageFestivals?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageFestivals[photoIndex]
                                        ?.image_path
                                    }
                                    nextSrc={
                                      resetImageFestivals[
                                        (photoIndex + 1) %
                                          resetImageFestivals.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageFestivals[
                                        (photoIndex +
                                          resetImageFestivals.length -
                                          1) %
                                          resetImageFestivals.length
                                      ]?.image_path
                                    }
                                    imageCaption={
                                      resetImageFestivals[photoIndex]?.caption
                                    }
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageFestivals.length -
                                          1) %
                                          resetImageFestivals.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageFestivals.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "traditional_dress" ? (
                            <>
                              {resetImageDress?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageDress[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImageDress[
                                        (photoIndex + 1) %
                                          resetImageDress.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageDress[
                                        (photoIndex +
                                          resetImageDress.length -
                                          1) %
                                          resetImageDress.length
                                      ]?.image_path
                                    }
                                    imageCaption={
                                      resetImageDress[photoIndex]?.caption
                                    }
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageDress.length -
                                          1) %
                                          resetImageDress.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageDress.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "ornaments" ? (
                            <>
                              {resetImageOrnamants?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageOrnamants[photoIndex]
                                        ?.image_path
                                    }
                                    nextSrc={
                                      resetImageOrnamants[
                                        (photoIndex + 1) %
                                          resetImageOrnamants.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageOrnamants[
                                        (photoIndex +
                                          resetImageOrnamants.length -
                                          1) %
                                          resetImageOrnamants.length
                                      ]?.image_path
                                    }
                                    imageCaption={RemoveCapturedBy(
                                      resetImageOrnamants[photoIndex]?.caption
                                    )}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageOrnamants.length -
                                          1) %
                                          resetImageOrnamants.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageOrnamants.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "traditional_food" ? (
                            <>
                              {resetImagesFood?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImagesFood[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImagesFood[
                                        (photoIndex + 1) %
                                          resetImagesFood.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImagesFood[
                                        (photoIndex +
                                          resetImagesFood.length -
                                          1) %
                                          resetImagesFood.length
                                      ]?.image_path
                                    }
                                    imageCaption={RemoveCapturedBy(
                                      resetImagesFood[photoIndex]?.caption
                                    )}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImagesFood.length -
                                          1) %
                                          resetImagesFood.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImagesFood.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "famous_personalities" ? (
                            <>
                              {resetImageFamous?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageFamous[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImageFamous[
                                        (photoIndex + 1) %
                                          resetImageFamous.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageFamous[
                                        (photoIndex +
                                          resetImageFamous.length -
                                          1) %
                                          resetImageFamous.length
                                      ]?.image_path
                                    }
                                    imageCaption={RemoveCapturedBy(
                                      resetImageFamous[photoIndex]?.caption
                                    )}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageFamous.length -
                                          1) %
                                          resetImageFamous.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) %
                                          resetImageFamous.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}

                          {selectedGalleryTopic === "traditional_art" ? (
                            <>
                              {resetImageArt?.map((item, index) => {
                                return (
                                  item != null &&
                                  item?.media_type != "video" &&
                                  parseInt(item?.status) !== -1 &&
                                  item?.media_name && (
                                    <>
                                      <div
                                        className="gallery_thumnail img-fluid col-3"
                                        style={{
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundColor: "#f3f3f4",
                                        }}
                                        onMouseOver={() => {
                                          item?.id
                                            ? setCapId(item?.id)
                                            : setCapId();
                                        }}
                                        onClick={() => {
                                          UpdatePhotoIndex(item?.id);
                                          setPhotoIndex(index);
                                        }}
                                      >
                                        <img
                                          src={item?.thumbnail}
                                          onError={(e) =>
                                            handleImageError(
                                              e,
                                              item?.media_name
                                            )
                                          }
                                        />
                                        {item?.caption &&
                                          item?.id &&
                                          capId === item?.id && (
                                            <div className="overlay">
                                              {item?.caption
                                                ? RemoveCapturedBy(
                                                    item?.caption
                                                  )
                                                : "-"}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )
                                );
                              })}
                              {isOpen && (
                                <>
                                  <Lightbox
                                    mainSrc={
                                      resetImageArt[photoIndex]?.image_path
                                    }
                                    nextSrc={
                                      resetImageArt[
                                        (photoIndex + 1) % resetImageArt.length
                                      ]?.image_path
                                    }
                                    prevSrc={
                                      resetImageArt[
                                        (photoIndex +
                                          resetImageArt.length -
                                          1) %
                                          resetImageArt.length
                                      ]?.image_path
                                    }
                                    imageCaption={RemoveCapturedBy(
                                      resetImageArt[photoIndex]?.caption
                                    )}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex +
                                          resetImageArt.length -
                                          1) %
                                          resetImageArt.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setPhotoIndex(
                                        (photoIndex + 1) % resetImageArt.length
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </>
                  </div>
                </div>

                {/* ----------------------------------Nearbuy Village------------------------------------------ */}

                <div
                  className="pr-1 shadow-sm bg-white my-5"
                  ref={nearVillageRef}
                  style={{
                    position: "relative",
                  }}
                  data-section="near_village"
                >
                  <div
                    style={{
                      position: "sticky",
                      top: "117px",
                      zIndex: "110",
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: nearFontColor,
                        width: "100%",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                        padding: "5px 5px 5px 15px",
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          fontWeight: nearFontWeight,
                          fontSize: "18px",
                        }}
                      >
                        Explore More Villages Nearby
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      gap: "1%",
                      padding: "2%",
                    }}
                  >
                    {nearBuyVillage.map((hit, index) => (
                      <div
                        key={index}
                        style={{ width: "100%", marginBottom: "2%" }}
                      >
                        <Link
                          to={{
                            pathname: `/village-details/${hit.village_document_id}`,
                            state: {
                              villageId: hit.village_document_id,
                            },
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <VillageCard
                            village={hit}
                            village_name={hit?.village_name}
                            state={hit?.state_name}
                            cover={hit?.thumbnail}
                            thumbnaiId={hit?.thumbnaiId}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </ReactPlaceholder>
            </div>
          </>
        </MyLayout>
      </MyMobile>

      {/* modal for indibadges */}
      <Modal
        show={openModel}
        size="auto"
        backdrop="static"
        keyboard={false}
        className="village-badge-model"
        onHide={handleBadgesM}
        style={{ justifyContent: "center" }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {isError && (
              <div className="text-badge">
                <p>You are not LoggedIn. Please Login to Collect this Badge</p>
                <span className="pb-2 clickme">
                  <Link to="login">Click Here</Link>
                </span>
                to Log In.
              </div>
            )}
            {selectedTopic == "festivals" ||
            selectedTopic == "traditional_food" ||
            selectedTopic == "heritage_places" ||
            selectedTopic == "ornaments" ||
            selectedTopic == "traditional_dress" ||
            selectedTopic == "traditional_art" ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <img
                    style={{ height: "150px", width: "150px" }}
                    src={randomImg}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="primary"
            className="th-bg-primary "
            onClick={() => collectBadges(selectedTopic)}
            style={{ border: "none" }}
            disabled={claimLoader}
          >
            {!claimLoader ? "Claim" : "Claiming"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog
        onClose={() => setOpenContributeModal(false)}
        open={openContributeModal}
      >
        <DialogTitle>Disclaimer</DialogTitle>
        <DialogContent>
          <p>
            The information on this portal has been collected through surveys
            and is subject to ongoing updates. If any information is found to be
            missing or incorrect, it can be updated using the "Contribute"
            button
          </p>
          <p>
            Information shared must be authentic and appropriate, devoid of any
            sensitive data.
          </p>
        </DialogContent>
        <DialogActions>
          <button
            className="btn"
            style={{ borderColor: "#3CAF5C", color: "#3CAF5C" }}
            onClick={() => setOpenContributeModal(false)}
          >
            Close
          </button>
          <button
            className="btn"
            onClick={() => {
              setIsEdit(true);
              setOpenContributeModal(false);
            }}
            style={{
              backgroundColor: "#3CAF5C",
              color: "#fff",
            }}
          >
            OK
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withRouter(VillageDetails);

export const VillageImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-color: "#555";
  background-position: center;
  border-radius: 20px;
`;

export const GalleryImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-color: "#555";
  background-position: center;
  border-radius: 20px;
`;

const NoDataMessage = () => {
  return (
    <>
      <Tooltip title="No data is available but you can contribute by clicking on the contribute button.">
        <IconButton>
          <CiCircleInfo fill="#40B05E" />
        </IconButton>
      </Tooltip>
    </>
  );
};
